.report-by-day {
    .tag-header {
        padding: 15px;
        .title-tag {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 10px;
            text-transform: uppercase;
            .value {
                font-size: 18px;
            }
        }
        .items-tag {
            .price-new {
                margin-bottom: 5px;
            }
            .label {
                font-size: 12px;
            }
            .value {
                font-size: 18px;
            }
        }
    }
    .list-transaction {
        margin-top: 20px;
        .head-list {
            display: inline-block;
            width: 100%;
            .title-list {
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 700;
                margin-bottom: 10px;
                float: left;
            }
            .filter-list {
                float: right;
                display: flex;
                .search-filter {
                    margin-right: 15px;
                    border: 1px solid #ced4da;
                    border-radius: 3px;
                    height: 32px;
                    padding: 0.375rem 0.75rem;
                    color: #495057;
                }
            }
        }

        .table-list {
            .price {
                color: red;
            }
            .action_row {
                text-decoration-line: underline;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }
    .popup-report {
        .body-popup {
            font-size: 14px;
            color: #5e6d8c;
        }
        .row {
            align-items: baseline;
            .info-transaction {
                margin-bottom: 20px;
                .label-info {
                    margin-bottom: 10px;
                    b {
                        color: #007bff;
                    }
                }
            }
        }
        .bottom-popup {
            .select-store {
                float: left;
                padding: 10px;
                width: 25%;
            }
        }
    }
    .total-price-input {
        margin-top: 20px;
        .label {
            margin-bottom: 10px;
            font-size: 18px;
        }
        .button-cent {
            width: 120px;
            margin-top: 38px;
        }
    }
    .input-cash {
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        color: #495057;
        display: block;
        font-size: 1rem;
        height: 32px;
        padding: 0.375rem 0.75rem;
    }

    .style-select {
        width: 120px;
        > div {
            border-radius: 5px !important;
        }  
    }
}