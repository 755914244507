.menu-list-voucher {
    margin: 30px 0;
    .item-menu {
        a {
            font-size: 18px;
            color: #000;
            font-weight: 600;
        }
        &.active, &:hover {
            a {
                color: #FF6513;
                text-decoration: underline;
            }
        }
    }
}