.admin-page {
    .admin-body {
        .edit-slot-operation {
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            input[type=number] {
                -moz-appearance: textfield;
            }
            .block-spa {
                margin-bottom: 10px;
            }
            .block-title {
                font-weight: 600;
                font-size: 18px;
                color: #5e6d8c;
                margin-bottom: 5px;
            }
            .action-title {
                color: #2196f3;
                text-align: right;
                margin-right: 5px;
                span {
                    cursor: pointer;
                }
            }
            .textarea {
                margin-top: 10px;
                textarea {
                    width: 100%;
                    border-color: #d9d9d9;
                    border-radius: 2px;
                    padding: 5px 10px;
                    min-height: 100px;
                }
            }
        }
        .store-banking {
            .items-data {
                &:not(:last-child) {
                    margin-bottom: 25px;
                }
                .title {
                    line-height: 1;
                    font-size: 14px;
                }
                .value {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 1.2;
                    margin-top: 10px;
                    color: #4a90e2;
                }
            }
            .action-title {
                color: #2196f3;
                text-align: right;
                margin-right: 5px;
                span {
                    cursor: pointer;
                }
            }
        }
        .edit-bank {
            .items-data  {
                > div {
                    margin-bottom: 25px;
                }    
            }
        }
        .dashboard-content {
            .wrapper {
                .item-store {
                    width: calc(100% / 2 - 10px);
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    padding: 20px;
                    margin: 0 5px 10px 5px;
                    .title-store {
                        color: #2196F3;
                        margin-bottom: 10px;
                        font-size: 21px;
                        display: inline-block;
                        width: 100%;
                        .button-cent {
                            float: right;
                        }
                    }
                    .info-store {
                        font-size: 12px;
                        color: #5e6d8c;
                        margin-bottom: 10px;
                        .label {
                            width: 120px;
                            display: inline-block;
                            i {
                                margin-right: 7px;
                            }
                        }
                    }
                    .button-container {
                        float: right;
                        .button-cent {
                            margin-left: 5px;
                            display: inline-block;
                        }
                    }
                }
            }
        }
        .store-edit, .edit-bank {
            .content-popup .body-popup .items-data {
                line-height: 1.5;
            }
        }
    }
    @media (max-width: 1199px) {
        .admin-body {
            .dashboard-content {
                .wrapper {
                    .item-store {
                        .info-store .label {
                            width: auto;
                            margin-right: 5px;
                        }
                        .button-container {
                            .button-cent {
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 991px) {
        .store-banking .block-spa,
        .edit-slot-operation .block-data {
            .button-cent {
                padding: 0 5px;
                text-align: center;
                span {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 767px) {
        .admin-body .dashboard-content .wrapper .item-store {
            width: calc(100% - 10px);
            .button-container .button-cent {
                width: calc(50% - 5px);
                margin-left: 0;
                margin-right: 5px;
            }
        }
        .store-banking {
            .content-popup .row {
                align-items: normal;
                .info-item {
                    margin-bottom: 15px;
                }
            }
        }
        .edit-slot-operation {
            .choose-time {
                > * {
                    width: 100%;
                }
            }
        }
        .popup-cent .content-popup .body-popup .items-data {
            margin-bottom: 40px;
        }
    }
}