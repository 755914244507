.admin-page.pos {
    .page-title {
        border-bottom: solid 1px #d3dae7;
        background-color: #fff;
    }

    .bank-screen2 {
        display: flex;
        align-items: center;
        margin-top: 15px;
        .pay-select-stores2 {
            margin-right: 20px;
            font-weight: 600;
            font-size: 14px;
        }
    }
    .bank-screen-header {
        float: right;
        margin-right: 10px;
        z-index: 9;
        position: relative;
    }
    .input-xs {
        border-radius: 2px;
        padding: 5px 10px;
        font-size: 12px;
        height: 32px;
        line-height: 1.6666667;
    }

    .fixed-column,
    .fixed-one-col,
    [class^=fixed-two-col-offset-],
    [class^=fixed-two-col-width-] {
        position: absolute;
        top: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        box-shadow: 0 0 15px rgb(0 0 0 / 8%);
    }

    .form-control {
        width: 100%;
        height: 36px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 3px;
        -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        padding: 7px;
        border-color: #d3dae7;
        color: inherit;
    }

    .btn-labeled {
        position: relative;

        a {
            color: #fff;
        }

        b {
            corlor: #fff;
            position: absolute;
            top: 0px;
            left: 0px;
            background-color: rgba(0, 0, 0, 0.15);
            display: block;
            line-height: 1;
            padding: 10px;
            border-bottom-left-radius: 3px;
            border-top-left-radius: 3px;
        }

        &.btn-xs b {
            padding: 9px;
        }
    }

    .fixed-content-wrapper {
        position: absolute;
        top: 108px;
        left: 0;
        right: 0;
        bottom: 10px;
        margin: 0;
        padding: 0;
    }

    .order-checkout-block {
        width: 100%;
        padding: 0 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        background: #fff;
        >div>p {
            float: right;
            font-size: 14px;
            font-weight: 700;
            margin: 0;
            padding: 0;
            text-align: right;
            width: 30%;
            line-height: 20px;
        }

        .price-order {
            width: 100%;
            min-height: 21px;
            &.order-total-subtotal {
                border-top: 1px solid #bbb;
                padding-top: 5px;
            }
            >div {
                float: left;
                font-weight: 700;
                width: 70%;
                line-height: 21px;
            }

            .voucher-item {
                margin-left: 10px;
                display: inline-block;
            }
            .voucher-group {
                .active-voucher {
                    color: #4a90e2;
                }
            }
        }
    }

    .voucher-list {
        position: fixed;
        top: 20%;
        left: 25%;
        max-height: 500px;
        border: 1px solid #ddd;
        padding: 10px;
        border-radius: 5px;
        width: 992px;
        background: #FFF;
        box-shadow: 0 0 45px rgba(0, 0, 0, 0.25);
        z-index: 9999;
        overflow-y: auto;
        padding-top: 0px;
        .head-popup {
            padding-top: 10px;
            padding-bottom: 20px;
        }
        .body-popup {
            height: auto;
            .grid-two-column {
                height: 53px;
                .product-image {
                    height: 50px;
                    width: 50px;
                }
                .pull-left {
                    .product-name {
                        font-size: 12px;
                        height: 20px;
                    }
                    .pull-left {
                        .product-sku {
                            max-width: 70%;
                        }
                    }
                }
            }
            // .grid-two-column.active{
            //     background: #f79800 !important
            // }
            .input-action-voucher {
                display: flex;
                input {
                    margin-right: 10px;
                }
                button {
                    padding: 8px;
                    width: 36px;
                    height: 36px;
                }
            }

            .product-grid {
                margin-top: 10px;
                .title-list {
                    margin-bottom: 10px;
                    color: dodgerblue;
                    font-weight: bold;
                }
                .disable-package2 {
                    display: none;
                }
                .close {
                    right: 5px;
                    top: -2px;
                    color: white;
                }
                .active {
                    .close {
                        right: 5px;
                        top: -2px;
                        color:#C3C3C3 ;
                    }
                }
            }
        }
        .content-popup.fadeInDown {
            width: 100%;
            margin: 10px auto;
        }
        .overlay {
            background: none;
        }
    }
    .openTypeAvatar {
        position: fixed;
        top: 20%;
        left: 25%;
        max-height: 250px;
        border: 1px solid #ddd;
        padding: 10px;
        border-radius: 5px;
        width: 700px;
        background: #FFF;
        box-shadow: 0 0 45px rgba(0, 0, 0, 0.25);
        z-index: 9999;
        overflow-y: auto;
        padding-top: 0px;
        .button-container {
            display: block;
        }
        .content-popup.fadeInDown {
            width: 100% !important;
            margin: 10px auto;
        }
        .overlay {
            background: none;
        }
    }
    .voucher-list.avatar-list {
        left: 432px;
    }

    .avatar-view {
        z-index: 9999;
        position: absolute;
        top: 5%;
        left: 160px;
        width: 262px;
        height: 306px;
        border: 1px solid #ddd;
        padding: 30px;
        border-radius: 5px;
        box-shadow: 0 0 45px rgba(0, 0, 0, 0.25);
        background: #FFF;
        
        .title-avatar {
            color: dodgerblue;
            padding-bottom: 10px;
            font-weight: 500;
        }
        .avatar {
            img {
                width: 200px;
                height: 200px;
            }
        }
    }
    .bottom-popup-save-avatar {
        position: sticky;
        bottom: 0;
    }

    .fixed-two-col-offset-1 {
        left: 10px;
        right: calc(50% + 5px);
    }

    .panel {
        background-color: #fff;
        border-radius: 3px;
        -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
        box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
        color: #5e6d8c;
        border: 1px solid #d3dae7 !important;
    }

    .max-height {
        height: 100%;
        margin: 0;
    }

    .panel-body,
    .panel-footer,
    .panel-heading,
    .panel-title {
        position: relative;
    }

    .panel-heading {
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        padding: 5px 10px;
        border-bottom: 1px solid #d3dae7;
        padding-bottom: 10px;
        padding-top: 10px;
        background: #fff;
        display: inline-block;
        width: 100%;
    }

    .max-height .panel-heading {
        max-height: 42px;
    }

    .customer-block-title {
        font-weight: 700;
        font-size: 14px;
        position: relative;
    }

    [role=button] {
        cursor: pointer;
    }

    .collapsing,
    .dropdown,
    .dropup {
        position: relative;
    }

    .select-inside-heading .mega-menu-wide {
        float: right;
        font-size: 13px;
        font-weight: 400;
        margin-bottom: -5px;
        margin-top: -5px;
        width: auto !important;
        position: unset;

        .add-more-item {
            margin-left: 10px;
        }
        .dropdown-content {
            position: absolute;
            top: calc(100% + 10px);
            right: 0;
            padding: 10px;
            width: 460px;
            color: #5e6d8c;
            font-size: 13px;
            background-color: #fff;
            border: 1px solid #ddd;
            border-radius: 3px;
            -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
            box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
            background-clip: padding-box;
            z-index: 5;

            .dropdown-content-body {
                position: relative;
                z-index: 2;

                .menu-heading {
                    padding-bottom: 10px;
                    border-bottom: 1px solid #eee;
                    display: block;
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: 500;
                    margin-bottom: 10px;
                    padding-top: 4px;
                }

                .form-control {
                    border-radius: 30px;
                    border-color: #d3dae7;
                    height: 32px;
                    line-height: 1.6666667;
                }

                .menu-list {
                    li {
                        border-bottom: solid 1px #d3dae7;
                        padding: 5px 5px 5px 25px !important;
                        position: relative;
                        display: inline-block;
                        width: 100%;
                        font-size: 12px;
                        position: relative;
                        cursor: pointer;

                        &:hover {
                            background-color: #f5f5f5;
                        }

                        .code {
                            color: #2196F3;
                            overflow: hidden;
                            float: left;
                            width: 33.333%;
                        }

                        .name {
                            float: left;
                            width: 33.333%;
                            font-style: italic;
                            color: #777;
                            text-align: center;
                            display: inline-block;
                        }

                        .date {
                            float: left;
                            width: 33.333%;
                            color: #777;
                            text-align: right;
                        }

                        .check {
                            position: absolute;
                            top: 5px;
                            left: 5px;
                            color: #2196F3;
                        }
                    }
                }
            }

            .overlay-order-list {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
            }
        }
    }

    .panel-body {
        padding: 0 10px 10px;

        .list-card-member {
            .owned-membership-cards-group-title {
                color: rgba(244, 81, 30, 1);
                background: #fee6df;
                border: 1px solid rgba(244, 81, 30, 1);
                padding: 0 5px 0 10px;
                width: 100%;
                margin-bottom: 10px;
                clear: both;
                border-radius: 5px;
                display: block;
                line-height: 32px;

                span {
                    font-size: 11px;
                }
            }

            .selling-membership-cards-group-title {
                color: #4a90e2;
                background: #f0fcff;
                border: 1px solid rgba(33, 150, 243, 1);
                padding: 0 5px 0 10px;
                width: 100%;
                margin-bottom: 10px;
                clear: both;
                border-radius: 5px;
                display: block;
                line-height: 32px;
            }

            .grid-two-column {
                border: 1px solid rgba(244, 81, 30, 1);
                background: #fee6df;

                .product-name {
                    color: rgba(244, 81, 30, 1);
                }

                .product-sku {}
            }
        }
    }

    .max-height .panel-body {
        height: calc(100% - 42px);
        overflow: auto;
        width: 100%;
    }

    .pos-customer-info {
        width: 50%;
        padding-right: 5px;
        float: left;
    }

    .customer-block-details {
        clear: both;
        overflow: hidden;
        padding: 10px 0;

        .customer-avatar-square-small {
            width: 112px;
            height: 65px;
            border: 1px solid #ddd;
            float: left;
            cursor: pointer;
        }

        .right-block {
            margin-left: 120px;
            position: relative;

            .name-customer {
                color: #2196F3;
                font-size: 14px;
                font-weight: 700;
            }

            .phone-customer {
                font-size: 12px;
                margin-top: 5px;
                color: #999;
            }

            .ranking {
                font-size: 12px;
                margin-top: 5px;
                color: #999;
                float: left;
            }

            .data-money {
                float: right;
                margin-top: 5px;
                margin-right: 5px;
                text-align: right;
                position: relative;
                top: -20px;

                .owe {
                    span {
                        color: red;
                    }
                }

                .deposit,
                .owe {
                    font-size: 12px;
                    color: #999;
                }
            }


            .button-edit {
                background-color: #4a90e2;
                height: 20px;
                width: 23px;
                text-align: center;
                line-height: 10px;
                padding: 4px 0;
                color: #fff;
                position: absolute;
                top: 0;
                right: 5px;
                border-radius: 3px;
                font-size: 12px;
                cursor: pointer;
            }
        }
    }

    .order-cashier {
        float: left;
        padding: 10px 0 5px 10px;
        width: 50%;
        border-left: solid 1px #d6e0ef;
        font-size: 12px;
    }

    .order-details-item-list-wrapper {
        height: calc(100% - 135px);
        position: relative;
        top: 85px;

        .table-striped {
            overflow: auto;
            position: absolute;
            display: block;
            height: calc(100% - 150px);

            .show-total-discount {
                text-align: end;
                text-decoration: line-through;
                padding-right: 12px;
            }
        }
    }

    .order-total-price {
        padding: 7px 0 5px;
        border-top: 1px solid #bbb;
        margin-top: 5px;
        display: inline-block;
    }

    .text-pink a {
        color: #4a90e2 !important;
    }

    .btn-group-sm>.btn:not(.btn-rounded),
    .btn-group-xs>.btn:not(.btn-rounded),
    .btn-sm:not(.btn-rounded),
    .btn-xs:not(.btn-rounded) {
        border-radius: 3px;
    }

    .btn-labeled.btn-xs {
        padding-left: 42px;
    }

    .btn.disabled,
    .btn[disabled],
    fieldset[disabled] .btn {
        cursor: not-allowed;
        opacity: .65;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    button[disabled],
    html input[type=button][disabled],
    input[type=reset][disabled],
    input[type=submit][disabled],
    md-autocomplete[disabled] input {
        cursor: default;
    }

    button[disabled],
    html input[disabled] {
        cursor: default;
    }

    .btn-primary,
    .btn-primary.focus,
    .btn-primary:focus,
    .btn-primary:hover,
    .btn.bg-teal-400 {
        background-color: #4a90e2;
        border-color: #4a90e2;
        color: #fff !important;
    }

    .btn-group-xs>.btn+.dropdown-toggle,
    .btn-group>.btn-xs+.dropdown-toggle {
        padding-left: 7px;
        padding-right: 7px;
    }

    .fixed-two-col-offset-2 {
        right: 10px;
        left: calc(50% + 5px);

        .panel-flat {
            overflow: auto;
        }
    }

    .input-group-addon,
    .input-group-btn {
        width: 1%;
        white-space: nowrap;
        vertical-align: middle;
    }

    .input-group-addon {
        font-size: 13px;
        font-weight: 400;
        color: #333;
        background-color: #fcfcfc;
        border: 1px solid #ddd;
        border-radius: 3px;
    }

    .input-group-addon {
        padding: 5px 7px;
        line-height: 20px;
    }

    .input-group-addon:first-child {
        border-right: 0;
    }

    .btn-group,
    .btn-group-vertical {
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }

    .btn-group-justified {
        display: table;
        width: 100%;
        table-layout: fixed;
        border-collapse: separate;
    }

    .btn-group-justified>.btn,
    .btn-group-justified>.btn-group {
        float: none;
        display: table-cell;
        width: 1%;
    }

    .btn-group-vertical>.btn,
    .btn-group>.btn {
        position: relative;
        float: left;
    }

    .btn .caret,
    .btn-group>.btn:first-child {
        margin-left: 0;
    }

    .btn-group-justified>.btn-group .btn {
        width: 100%;
    }

    .position-left {
        margin-right: 7px;
    }

    .btn-default {
        border: 1px solid #d3dae7;
        color: #5e6d8c;
        box-shadow: none;
        outline: none;

        &.btn {
            display: inline-block;
            margin-bottom: 0;
            font-weight: 400;
            text-align: center;
            vertical-align: middle;
            touch-action: manipulation;
            cursor: pointer;
            padding: 0;
            font-size: 13px;
            border-radius: 3px;
            user-select: none;
            width: 30px;
            height: 32px;
            line-height: 30px;
            background: #fcfcfc;
        }
    }

    .navbar-nav>li,
    li,
    ul {
        padding-left: 0;
        list-style: none;
    }

    .product-grid {
        margin: 0;
        overflow: hidden;
        margin-top: 20px;
        .text-upsale {
            color: #f4511e;
            margin-bottom: 15px;
            font-weight: 600;
        }
    }

    .product-grid .grid-two-column,
    .product-grid .disable-package2 {
        float: left;
        margin: 0 10px 10px 1px;
        position: relative;
        width: calc(50% - 11px);
        cursor: pointer;
        height: 82px;
        border: 1px solid #d6e0ef;
        padding: 0;
        border-radius: 6px;
    }

    .avatar-customer-grid>li {
        float: left;
        margin: 0 10px 10px 1px;
        position: relative;
        width: calc(13% - 11px);
        cursor: pointer;
        height: 90px;
        width: 90px;
        padding: 0;
        border-radius: 6px;
        .avatar {
            width: 90px;
            height: 90px;
            img {
                width: 90px;
                height: 90px;
                object-fit: cover;
                border: rgba(52,152,255,.25) 4px solid;
            }
        }
        .close {
            right: 5px;
            top: -3px;
        }
    }

    .avatar-customer-grid {
        .title-list {
            margin-bottom: 10px;
            color: dodgerblue;
            font-weight: bold;
        }
        .grid-two-column-avatar.active {
            .avatar {
                img {
                    //border-radius: 50% !important;
                }
            }
        }
    }

    .product-grid .disable-package2 .product-image, 
    .product-grid .grid-two-column .product-image {
        height: 80px;
        margin: 0;
        clear: both;
        float: left;
        background-size: cover;
        background-position: center center;
        width: 80px;
        padding: 0;
        border-radius: 5px 0 0 5px;
        background-repeat: no-repeat;
        background-color: #fff;
    }

    .product-grid .disable-package2 .product-name,
    .product-grid .grid-two-column .product-name {
        font-size: 12px;
        line-height: 20px;
        height: 40px;
        color: #4a90e2;
        margin-top: 5px;
        word-break: break-word;
        margin-bottom: 5px;
        text-transform: uppercase;
        font-weight: 700;
    }
    .package-history .delete-transaction {
        height: 50px !important;
    }

    .product-grid .disable-package2 .product-name,
    .product-grid .disable-package2 .product-sku,
    .product-grid .grid-two-column .product-name,
    .product-grid .grid-two-column .product-sku {
        overflow: hidden;
        margin-left: 10px;
        text-overflow: ellipsis;
        float: left;
    }

    .product-grid .disable-package2 .product-sku,
    .product-grid .grid-two-column .product-sku {
        color: #999;
        max-width: 150px;
        display: inline-block;
        white-space: nowrap;
        font-size: 12px;
    }

    .product-grid .disable-package2 .customer-booking-total,
    .product-grid .grid-two-column .customer-booking-total {
        font-weight: 700;
        float: right;
        font-size: 12px;
    }

    .product-grid .grid-two-column.active {
        border: 1px solid rgba(33, 150, 243, 1) !important;
        background: #f0fcff !important;
    }

    .product-grid .disable-package2 .product-cart-counter,
    .product-grid .grid-two-column .product-cart-counter {
        position: absolute;
        left: -1px;
        top: -1px;
        z-index: 3;
        font-size: 28px;
        font-weight: 700;
        color: #fff;
        width: 81px;
        background: rgba(0, 0, 0, 0.45);
        line-height: 40px;
        text-align: right;
        overflow: visible;
        border-radius: 5px 0 0 5px;
        padding: 20px;
        border: 1px solid rgba(33, 150, 243, 1) !important;
        bottom: -1px;
    }

    .product-grid .disable-package2 .product-cart-counter:after,
    .product-grid .grid-two-column .product-cart-counter:after {
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        content: "\f217";
        font-size: 18px;
        margin-right: 5px;
        vertical-align: text-top;
        position: absolute;
        left: 10px;
    }

    .htContextMenu td,
    .ranges ul li,
    .table td,
    .table th,
    .table tr {
        -webkit-transition: background-color ease-in-out .15s;
        -o-transition: background-color ease-in-out .15s;
        transition: background-color ease-in-out .15s;
    }

    .table {
        width: 100%;
        max-width: 100%;
        font-size: 10px;
        margin-bottom: 0;
    }

    table.table {
        clear: both;
        max-width: none !important;
        border-top: 1px solid #bbb;
    }

    .table>caption+thead>tr:first-child>td,
    .table>caption+thead>tr:first-child>th,
    .table>colgroup+thead>tr:first-child>td,
    .table>colgroup+thead>tr:first-child>th,
    .table>thead:first-child>tr:first-child>td,
    .table>thead:first-child>tr:first-child>th {
        border-top: 0;
    }

    table.order-items {
        width: 100%;
        max-height: 100%;
        table-layout: fixed;

        >thead {
            background: #fff;
            border: 1px solid #d6e0ef;
            border-top: none !important;
            width: 100%;
            display: inline-block;

            >tr {
                display: table;
                width: 100%;

                >th {
                    padding: 5px;
                    font-weight: 700;
                    border: none !important;
                    vertical-align: middle;
                    width: 50%;
                    font-size: 12px;
                }
            }
        }

        >tbody {
            >tr {
                &:nth-of-type(odd) {
                    background-color: #f0fcff;
                }

                >td {
                    padding: 5px 0;
                    border: none;
                    vertical-align: middle;
                    width: 50%;

                    &.selected-operator {
                        position: fixed;
                        top: 30%;
                        left: 50%;
                        max-height: 500px;
                        border: 1px solid #ddd;
                        padding: 10px;
                        border-radius: 5px;
                        width: 550px;
                        background: #FFF;
                        box-shadow: 0 0 45px rgb(0 0 0 / 25%);
                        z-index: 9999;
                        overflow-y: auto;

                        .content-data {
                            position: relative;
                            z-index: 2;

                            .content-operator {
                                text-align: left;
                                margin-top: 20px;

                                .head-block {
                                    .select-all {
                                        color: #4a90e2;
                                        font-size: 14px;
                                        margin-bottom: 20px;
                                        cursor: pointer;
                                        display: inline-block;
                                    }

                                    .title-block {
                                        float: left;
                                        font-size: 14px;
                                        line-height: 32px;
                                        width: 100%;
                                    }

                                    .input-search {
                                        input {
                                            height: 30px;
                                            border: 1px solid #E4E4E4;
                                            border-radius: 3px;
                                            width: 200px;
                                            font-size: 12px;
                                        }
                                    }
                                }

                                .body-operators {
                                    margin-top: 15px;
                                    border: solid 1px #d3dae7;
                                    border-radius: 3px;

                                    .top-title {
                                        display: inline-block;
                                        width: 100%;
                                        padding: 10px;
                                        border-bottom: solid 1px #d3dae7;

                                        .top-name {
                                            float: left;
                                            font-size: 12px;
                                            width: 90px;

                                            &:first-child {
                                                width: 250px;
                                            }
                                        }
                                    }

                                    .list-operator {
                                        .item-operator {
                                            display: inline-block;
                                            width: 100%;
                                            padding: 10px;

                                            >div {
                                                float: left;
                                            }

                                            .operator-name {
                                                width: 250px;

                                                .avatar {
                                                    object-fit: cover;
                                                    float: left;
                                                    width: 35px;
                                                    height: 35px;
                                                    border: 1px solid #d6e0ef;
                                                    border-radius: 50%;
                                                }

                                                .info {
                                                    margin-left: 45px;
                                                    padding-top: 3px;

                                                    .name {
                                                        color: #2196F3;
                                                        font-size: 12px;
                                                        text-transform: uppercase;
                                                    }

                                                    .position {
                                                        font-size: 11px;
                                                    }
                                                }
                                            }

                                            .operator-service {
                                                width: 90px;
                                                line-height: 35px;

                                                >div {
                                                    width: 16px;
                                                    height: 16px;
                                                    border: 1px solid #5e6d8c;
                                                    position: relative;

                                                    &.active {
                                                        border-color: #2196F3;

                                                        &:before {
                                                            content: "";
                                                            position: absolute;
                                                            top: 2px;
                                                            left: 2px;
                                                            width: 10px;
                                                            height: 10px;
                                                            background: #2196F3;
                                                        }
                                                    }

                                                    input {
                                                        width: 16px;
                                                        height: 16px;
                                                    }
                                                }
                                            }

                                            .operator-consultant {
                                                width: 90px;
                                                line-height: 35px;

                                                >div {
                                                    width: 16px;
                                                    height: 16px;
                                                    border: 1px solid #5e6d8c;
                                                    position: relative;

                                                    &.active {
                                                        border-color: #2196F3;

                                                        &:before {
                                                            content: "";
                                                            position: absolute;
                                                            top: 2px;
                                                            left: 2px;
                                                            width: 10px;
                                                            height: 10px;
                                                            background: #2196F3;
                                                        }
                                                    }

                                                    input {
                                                        width: 16px;
                                                        height: 16px;
                                                    }
                                                }
                                            }

                                            &:not(:last-child) {
                                                border-bottom: solid 1px #d3dae7;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .overlay-user-list {
                            position: fixed;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            z-index: 1;
                        }
                    }

                    &:first-child {
                        padding-left: 0;
                    }

                    .cash-vnd {
                        text-align: right;
                        padding: 0;
                        font-weight: 700;
                        font-size: 14px;
                        margin-right: 10px;
                    }
                }
            }
        }

        tr>th:nth-child(2),
        tr>th:nth-child(3),
        tr>th:nth-child(4),
        tr>td:nth-child(2),
        tr>td:nth-child(3),
        >tr>td:nth-child(4) {
            width: 15%;
        }

        tr>th:nth-child(5),
        tr>td:nth-child(5) {
            width: 5%;
        }

        .input-group {
            width: 100px;
            float: right;
        }
    }

    .item-info {
        min-width: 150px;
        width: calc(100% - 30px);
        display: inline-block;
        >div {
            font-size: 11px;
            height: 20px;
            overflow: hidden;
            overflow-wrap: break-word;
            text-overflow: ellipsis;
            white-space: nowrap;

            .ng-isolate-scope .cash-vnd {
                font-size: 11px;
            }
        }
    }

    .item-info>div,
    td.name-dt,
    th.name-dt {
        word-break: break-all;
    }

    .input-group {
        position: relative;
        display: table;
        border-collapse: separate;
    }

    .input-group .form-control,
    .input-group-addon,
    .input-group-btn {
        display: table-cell;
    }

    .input-group-addon,
    .input-group-btn {
        width: 1%;
        white-space: nowrap;
        vertical-align: middle;
    }

    .input-group-btn {
        position: relative;
        font-size: 0;

        .btn.left {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .btn.right {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .input-group .form-control {
        position: relative;
        z-index: 2;
        float: left;
        width: 100%;
        border-left: 0;
        border-right: 0;
        height: 32px;
        line-height: 30px;
        text-align: center;
        padding: 0;
        margin-bottom: 0;
        min-width: 40px;
        outline: none;
        box-shadow: none;
    }

    .input-group .form-control:not(:first-child):not(:last-child),
    .input-group-addon:not(:first-child):not(:last-child),
    .input-group-btn:not(:first-child):not(:last-child) {
        border-radius: 0;
    }

    .require-operator {
        width: 36px;
        height: 36px;
        background-size: 100% 100%;
        background-position: center center;
        border-radius: 18px;
        border: 1px solid #d6e0ef;
        display: inline-block;
        position: relative;
        line-height: 34px;

        i.fa-question {
            font-size: 20px;
            padding-top: 8px;
        }

        .count {
            position: absolute;
            background: #4a90e2;
            color: #fff;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            border-radius: 50%;
            left: 10px;

            &.service {
                top: -8px;
            }

            &.consultant {
                bottom: -10px;
            }
        }

        .tooltip-user {
            padding: 10px;

            p {
                margin-top: 0;
                margin-bottom: 0;
            }

            .item-user {
                text-align: left;

                &:not(:last-child) {
                    margin-bottom: 15px;
                }

                label {
                    font-weight: 600;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .cash-cny,
    .cash-czk,
    .cash-dkk,
    .cash-eur,
    .cash-gbp,
    .cash-inr,
    .cash-krw,
    .cash-lak,
    .cash-myr,
    .cash-thb,
    .cash-usd,
    .cash-vnd {
        color: #259B9A;
        position: relative;
        white-space: nowrap;
    }

    .order-details-dropdown-container {
        overflow: visible;
        position: relative;
        height: 36px;
        width: 36px;
        display: inline-block;
        position: relative;

        .dropdown-menu {
            display: none;
            min-width: 100px;
            padding: 5px 0;
            color: #333;
            position: absolute;
            top: 100%;
            left: -72px;
            background: #fff;
            z-index: 2;
            border: 1px solid #ddd;
            border-radius: 3px;
            box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
            margin: 2px 0 0;
            list-style: none;
            font-size: 13px;

            .action {
                padding: 0 10px;
                text-align: left;

                i {
                    margin-right: 12px;
                    margin-top: 2px;
                    min-width: 20px;
                }
            }
        }

        &.active {
            .dropdown-menu {
                display: block;
            }
        }
    }

    .item-info span.ng-binding {
        font-size: 14px;
        color: #4a90e2;
    }

    .dropdown-toggle::after {
        margin-left: 0;
    }

    .order-total-checkout-buttons {
        display: inline-block;
        width: 100%;
    }

    .button-container {
        display: flex;
        &.left {
            float: left;
        }

        &.right {
            float: right;
        }
    }

    .information-customer-order {
        padding: 10px 15px;

        .top-form {
            padding-bottom: 45px;

            .item-group-info {
                margin-bottom: 15px;

                .custom-date-picker {
                    width: 100%;
                }

                .control-label {
                    text-transform: uppercase;
                }

                .title {
                    font-size: 13px;
                    line-height: 30px;
                }

                .value {

                    textarea,
                    input {
                        width: 100%;
                        border-radius: 3px;
                        border: 1px solid rgb(229, 229, 234);
                        padding: 0 10px;
                    }

                    input {
                        height: 34px;
                        line-height: 32px;
                    }

                    textarea {
                        height: 100px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }

                &.custom-col {
                    .value {
                        .row {
                            margin: 0 -5px;

                            >div {
                                padding: 0 5px;
                            }
                        }
                    }
                }
            }
        }

        .button-container {
            margin-top: 5px;
            position: absolute;
            bottom: 1px;
            left: 15px;
            background: #fff;
            padding: 10px 0;
            border-top: 1px solid #ddd;
            width: calc(100% - 45px);
        }
    }

    .result-search {
        position: absolute;
        z-index: 6;
        background: #fff;
        width: calc(100% - 30px);
        border: 1px solid #d3dae7;
        border-radius: 3px;
        top: calc(100% + 5px);

        .empty-customer {
            line-height: 1;
            padding: 10px;
            font-size: 16px;
        }

        .items-customer {
            height: 350px;
            overflow: hidden;
            overflow-y: scroll;

            .item {
                display: flex;
                cursor: pointer;
                padding: 10px;

                .avatar {
                    float: left;

                    img {
                        object-fit: cover;
                        width: 46px;
                        height: 46px;
                        border-radius: 50%;
                    }
                }

                .info {
                    float: left;
                    padding-left: 10px;
                    width: calc(100% - 36px);

                    >div {
                        line-height: 1;
                    }

                    .name {
                        text-transform: uppercase;
                        border-radius: 3px;
                        background: red;
                        color: #fff;
                        display: inline-block;
                        padding: 3px;
                    }

                    .phone,
                    .deposit {
                        color: #999;
                        font-size: 10px;
                        margin: 7px 0 5px;
                    }

                    .address {
                        color: #999;
                        font-size: 10px;
                    }
                }

                &:not(:last-child) {
                    border-bottom: 1px solid #d3dae7;
                }

                &:hover {
                    background: #f6fdff;
                }
            }
        }
    }

    .old-customer {
        .avatar {
            img {
                object-fit: cover;
                max-height: 60px;
                max-width: 60px;
                border-radius: 50%;
            }
        }

        .info-customer {
            line-height: 1;
        }

        .gender {
            color: rgb(33, 150, 243);
            font-size: 12px;
            margin-right: 2px;
        }

        .name {
            font-size: 14px;
            font-weight: 700;
            margin: 0;
            color: #2196F3;
            text-transform: uppercase;
        }

        .button-edit {
            border-radius: 2px;
            background: #2196F3;
            color: #FFF;
            margin-left: 5px;
            padding: 3px;
            cursor: pointer;
        }

        .button-create-new {
            margin-left: 10px;
            color: #2196F3;
            text-decoration: underline;
            cursor: pointer;

            i {
                margin-right: 5px;
            }
        }

        .phone {
            margin-top: 15px;
        }

        .phone,
        .address {
            line-height: 1;
            color: #5e6d8c;
            font-size: 12px;
            margin-bottom: 8px;

            i {
                margin-right: 10px;
                font-size: 13px;
            }
        }

        i {
            font-size: 10px;
        }
    }

    .change-service {
        .btn-group {
            .btn {
                border-radius: 0;
            }

            &:not(:first-child) .btn {
                border-left: 0;
            }

            &:first-child .btn {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }

            &:last-child .btn {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            .btn-disable {
                color: #fff;
                background: #5e6d8c;
                border: 1px solid rgb(211, 218, 231);
                cursor: not-allowed;
            }
        }
    }

    .popup-cent {
        &.payment {
            color: #5e6d8c;

            .content-popup {
                width: 900px;
            }

            .account-payment {
                padding-bottom: 10px;
                font-size: 12px;
            }

            .payment-block {
                display: inline-block;
                width: 100%;

                .left-block {
                    float: left;
                }

                .right-block {
                    float: right;
                }
            }

            .total-price,
            .money-back-block {
                .title-block {
                    font-size: 20px;
                    line-height: 40px;
                }

                .price-block {
                    line-height: 40px;
                    font-size: 32px;
                    color: #2196F3;
                }
            }

            .payment-method {
                margin-top: 10px;

                .title-block {
                    font-size: 14px;
                    margin-bottom: 10px;
                }

                .body-block {
                    .row {
                        margin: 0 -5px;

                        .btn {
                            margin-bottom: 10px;

                            &.btn-primary {}

                            &.btn-default {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .cash-block {
                margin-bottom: 10px;

                .title-block {
                    font-size: 14px;
                    line-height: 40px;
                }

                .cash-customer {
                    width: 50%;
                    text-align: right;
                    position: relative;

                    .currency {
                        position: absolute;
                        top: 7px;
                        right: 7px;
                        font-size: 16px;
                    }

                    input {
                        width: 100%;
                        height: 32px;
                        padding-left: 10px;
                        padding-right: 25px;
                        border: 1px solid rgb(211, 218, 231);
                        border-radius: 3px;
                        text-align: right;
                    }
                }
            }

            .note-transaction {
                border-top: solid 1px #d3dae7;
                padding-top: 10px;

                .note-block {
                    textarea {
                        width: 100%;
                        border: 1px solid rgb(211, 218, 231);
                        border-radius: 3px;
                        margin-top: 10px;
                        height: 80px;
                        padding: 5px 10px;
                    }
                }
            }

            .owed-block {
                color: rgb(244, 67, 54);

                .money-owed {
                    font-size: 16px;
                }
            }
        }

        &.update-cart-item {
            .content-popup {
                width: 700px;
            }

            .order-item-details {
                display: inline-block;
                width: 100%;
                border-bottom: solid 1px #d6e0ef;
                padding-bottom: 15px;
                margin-bottom: 10px;

                .order-item-image {
                    width: 80px;
                    height: 80px;
                    float: left;
                    border: 1px solid #ddd;
                }

                .info {
                    float: left;
                    margin-left: 20px;

                    .order-item-name {
                        font-weight: 700;
                        font-size: 16px;
                        color: #5e6d8c;
                    }

                    .order-code {
                        color: #2196F3;
                        font-size: 12px;
                    }
                }

                .cash-vnd {
                    font-weight: 700;
                    font-size: 26px;
                    float: right;
                    color: #259B9A;
                    position: relative;
                    white-space: nowrap;

                    .show-discount {
                        font-size: 12px !important;
                        text-decoration: line-through;
                        color: rgb(119, 119, 119) !important;
                    }
                }
            }

            .list-change {
                .item-input {
                    width: 100%;
                    display: inline-block;
                    padding: 5px;

                    .label {
                        color: #5e6d8c;
                        font-size: 12px;
                        width: 120px;
                        float: left;
                        line-height: 32px;
                    }

                    .value {
                        width: 160px;
                        position: relative;
                        float: left;
                        font-size: 12px;

                        input {
                            width: 100%;
                            height: 32px;
                            padding-left: 10px;
                            padding-right: 25px;
                            border: 1px solid rgb(211, 218, 231);
                            border-radius: 3px;
                            text-align: right;
                            font-size: 12px;
                        }

                        .currency {
                            position: absolute;
                            top: 7px;
                            right: 7px;
                            font-size: 16px;
                        }
                    }

                    .select-quantit {
                        width: 115px !important;
                    }

                    .set-max-used-package {
                        text-align: end;

                        .label {
                            width: 210px;
                        }

                        .value {
                            width: 100px;
                            margin-left: 10px;

                            input {
                                padding-right: 0px;
                            }
                        }
                    }

                    .value.node-cart-coc {
                        width: 360px;

                        .form-control {
                            height: 70px;
                        }
                    }
                }

                .discount-show {
                    .value-show-discount {
                        margin-left: 120px;

                        .input-discount-show {
                            width: 100%;
                            height: 32px;
                            padding-left: 10px;

                            // padding-right: 25px;
                            border: 1px solid rgb(211, 218, 231);
                            border-radius: 3px;
                            text-align: right;
                            font-size: 12px;
                        }

                        .checkbox-all-item {
                            padding-left: 10px;
                            padding-top: 8px;

                            input {
                                margin-right: 10px;
                                width: 15px;
                                height: 15px;
                            }

                            label {
                                font-size: 12px;
                            }
                        }
                    }
                }

                .wraper-source-search {
                    display: flex;
                    font-size: 12px;

                    .source-customer-search {
                        position: relative;

                        .select-option-type-user {
                            width: 160px;
                        }

                        .search-show-user {
                            width: 230px;

                            input {
                                width: 100%;
                                width: 100%;
                                height: 38px;
                                padding-left: 10px;
                                padding-right: 25px;
                                border: 1px solid rgb(211, 218, 231);
                                border-radius: 3px;
                                font-size: 12px;
                            }

                            .result-search {
                                width: 100%;
                            }
                        }

                        .refresh-button {
                            padding-left: 10px;

                            button {
                                height: 35px;
                            }
                        }
                    }

                    .label-source {
                        width: 120px;
                        line-height: 35px;
                    }
                }

                .wraper-show-data {
                    .value-show-discount {
                        font-size: 12px;
                        padding-left: 120px;

                        .input-group {
                            width: 160px;
                            padding-right: 10px;

                            input {
                                width: 100%;
                                height: 32px;
                                padding-left: 10px;
                                padding-right: 25px;
                                border: 1px solid rgb(211, 218, 231);
                                border-radius: 3px;
                            }

                            .height-38 {
                                height: 38px !important;
                            }

                            .input-discount-money {
                                border-radius: 3px 0px 0px 3px;
                                padding-right: 5px !important;
                                text-align: end;

                                // width: 110px;

                            }
                        }

                        .input-consultant {
                            padding-right: 0px !important;

                            .input-discount-mone {
                                text-align: end;
                            }
                        }

                        .select-type-consultant {
                            width: 65px;
                        }
                    }
                }
            }

            .wraper-introductory-person {
                border-top: solid 1px #d6e0ef;
                padding-top: 20px;

                .validation-error-label {
                    padding-left: 125px;
                    color: red;
                    font-size: 11px;

                    :before {
                        content: '\ed63';
                        position: absolute;
                        top: 2px;
                        left: 0;
                        display: inline-block;
                        line-height: 1;
                        font-size: 16px;
                    }
                }
            }

            .bottom-popup {
                border-top: solid 1px #d6e0ef;
            }
        }
    }

    .set-border-right {
        border-radius: 3px 0px 0px 3px !important;
    }

    .set-border-left {
        border-radius: 0px 3px 3px 0px !important;
    }

    .cl-red {
        color: red;
    }

    .body-popup {
        .payment-in-many-forms {
            font-size: 12px !important;
            width: 100%;
            margin-bottom: 10px;

            .class-payment-many-item {
                margin-bottom: 10px;
                .button-cent-remover {
                    background: #4a90e2;
                    border-radius: 3px;
                    text-align: center;
                    color: #fff;
                    font-size: 12px;
                    position: relative;
                    height: 32px;
                    line-height: 32px;
                    cursor: pointer;
                    background: #f2480c;
                }
            }

            .select-type-payment {
                width: 30%;
                float: left;
                div {
                    div {
                        span {
                            display: none;
                        }
                    }
                }
            }

            .cash-customer {
                float: left;
                width: 70%;
                padding-left: 10px;
                position: relative;
            }

            .input-money-paid {
                width: 100%;
                height: 38px;
                padding-left: 10px;
                padding-right: 25px;
                border: 1px solid rgb(211, 218, 231);
                border-radius: 3px;
                text-align: right;
            }

            .currency {
                position: absolute;
                top: 9px;
                right: 8px;
                font-size: 15px;
            }

            .add-more-payment {
                color: #2196F3;
                float: right;
                margin-top: 10px;
                cursor: pointer;
            }
        }
    }

    .image-ktv {
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .note-package {
        font-size: 25px;
        color: #ddd;
        position: absolute;
        left: -30px;
        top: 3px;
    }

    .edit-credit {
        color: #5e6d8c;
        .body-popup {
            .panel-body {
                .item-popup {
                    &:not(:last-child) {
                        margin-bottom: 15px;
                    }
                    .input-credit, textarea {
                        border: 1px solid #d3dae7;
                        border-radius: 3px;
                        padding: 7px;
                        width: 100%;
                    }
                    .input-credit {
                        height: 38px;
                        line-height: 38px;

                    }
                    textarea {
                        height: 120px;
                    }
                    .result-search {
                        position: absolute;
                        z-index: 6;
                        background: #fff;
                        width: calc(100% + 100px);
                        border: 1px solid #d3dae7;
                        border-radius: 3px;
                        .empty-customer {
                            line-height: 1;
                            padding: 10px;
                            font-size: 16px;
                        }
                        .items-customer {
                            height: 350px;
                            overflow: hidden;
                            overflow-y: scroll;
                            .item {
                                display: flex;
                                cursor: pointer;
                                padding: 10px;
                                .avatar {
                                    float: left;
                                    img {
                                        border-radius: 50%;
                                    }
                                }
                                .info {
                                    float: left;
                                    padding-left: 10px;
                                    width: calc(100% - 36px);
                                    > div {
                                        line-height: 1;
                                    }
                                    .name {
                                        text-transform: uppercase;
                                        border-radius: 3px;
                                        background: red;
                                        color: #fff;
                                        display: inline-block;
                                        padding: 3px;
                                        position: relative;
                                        top: -5px;
                                    }
                                    .phone {
                                        position: relative;
                                        color: #999;
                                        font-size: 10px;       
                                        margin-bottom: 5px;       
                                    }
                                    .ranking {
                                        position: relative;
                                        top: -5px;
                                        margin-left: 5px;
                                        font-size: 10px;
                                    }
                                    .address {
                                        color: #999;
                                        font-size: 10px;    
                                    }
                                }
                                &:not(:last-child) {
                                    border-bottom: 1px solid #d3dae7;
            
                                }
                                &:hover {
                                    background: #f6fdff;
                                }
                            }
                        }
                    }
                    .receiver_customer {
                        .avatar {
                            img {
                                max-width: 60px;
                                border-radius: 50%;
                            }
                        }
                        .info-customer {
                            line-height: 1;
                            .top-info {
                                margin-bottom: 10px;
                            }
                        }
                        .gender {
                            color: rgb(33, 150, 243);
                            font-size: 12px;
                            margin-right: 2px;
                        }
                        .name {
                            font-size: 14px;
                            font-weight: 700;
                            margin: 0;
                            color: #2196F3;
                            text-transform: uppercase;
                        }
                        .button-edit {
                            border-radius: 2px;
                            background: #2196F3;
                            color: #FFF;
                            margin-left: 5px;
                            padding: 3px;
                            cursor: pointer;
                        }
                        .button-create-new {
                            margin-left: 10px;
                            color: #2196F3;
                            text-decoration: underline;
                            cursor: pointer;
                            i {
                                margin-right: 5px;
                            }
                        }
                        .ranking {
                            margin-top: 15px;
                        }
                        .phone, .address, .ranking {
                            line-height: 1;
                            color: #5e6d8c;
                            font-size: 12px;
                            margin: 8px 0;
                            i {
                                margin-right: 10px;
                                font-size: 13px;
                            }
                        }
                        i {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
}

.button-cent.save-and-book {
    margin-right: 10px;
    background: #ffa702
}

.button-cent.check-qr {
    margin-right: 10px;
    background:#fb8a25
}

.disable-package {
    opacity: 0.5;
    pointer-events: none;
}

.disable-package2 {
    opacity: 0.5;
}

.overlay-loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 9999;

    .ant-spin {
        position: absolute;
        left: calc(50% - 12px);
        top: calc(50% - 10px);
    }
}

.print-order {
    color: #000;
    font-size: 12px;
    width: 100%;
    max-width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    width: 220px;
    padding-bottom: 50px;

    .header {
        margin-top: 0;
        margin-bottom: 5px;
        position: relative;
        text-align: center;

        img {
            height: 50px;
        }

        .title {
            margin-top: 10px;
            font-size: 17px;
        }
    }

    .info-cus {
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: left;

        label {
            width: 60px;
            margin: 0;
        }

        p {
            margin: 5px 0;
        }
    }

    table {
        border-collapse: collapse;
        border-top: 1px solid #000;
        max-width: 100%;
    }

    .head {
        border-bottom: 1px solid #000;
    }

    tbody .item {
        td:not(:last-child) {
            border-bottom: 1px solid #000;
        }

        td:last-child {
            border-bottom: 1px dashed #000;
        }
    }

    .bottom {
        border-bottom: 1px dashed #000;
    }

    td,
    th {
        text-align: left;

        &:not(:first-child) {
            text-align: right;
        }
    }

    .total-price {
        padding-top: 5px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;

        .item-price {
            display: inline-block;
            width: 100%;
        }
    }

    .package-history {
        padding-top: 12px;
        font-style: italic;

        .title {
            margin-bottom: 5px;
        }

        .items-package .item {
            display: inline-block;
            width: 100%;
        }
    }

    .left {
        float: left;
    }

    .right {
        float: right;
    }
}

.link-qr-code {
    color: #000 !important;
    &:hover {
        color: red !important;
        text-decoration: underline;
    }
}

.isCopy {
    background: #59c136 !important;
    color: white !important;
}
.price-subtotal {
    float: right;
}
@media (max-width: 1199px) {
    .overlay-service-pos {
        opacity: 0.5;
        z-index: 2;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background: #000;
    }
    .admin-page.pos {
        table.order-items > tbody > tr > td.selected-operator {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .voucher-list {
            left: 50%;
            top: 40%;
            transform: translate(-50%, -50%);
        }
        .fixed-two-col-offset-1 {
            right: 10px;
        }
        .fixed-two-col-offset-2 {
            position: fixed;
            z-index: 999;
            top: 50%;
            left: 50%;
            width: 80%;
            height: 80%;
            transform: translate(-50%, -50%);
            .note-icon {
                display: none;
            }
        }
    }
}

@media (max-width: 767px) {
    .admin-page.pos {
        .note-package {
            left: auto;
            right: -30px;
        }
        .voucher-list {
            width: 95%;
        }
        .btn-primary {
            font-size: 11px;
            padding-left: 5px;
            padding-right: 5px;
        }
        .page-title {
            .title-page {
                font-size: 12px !important; 
            }
            .button-cent {
                padding: 0 5px;
                font-size: 11px;
                span {
                    display: none;
                }
            }
        }
        .product-grid {
            .grid-two-column,
            .disable-package2 {
                width: calc(100% - 1px);
                margin-right: 0;
            }
        }
        .select-inside-heading .mega-menu-wide .dropdown-content {
            width: 100%;
        }
        .pos-customer-info, .order-cashier {
            width: 100%;
        }

        .fixed-content-wrapper, 
        .fixed-two-col-offset-1 {
            position: unset;
        }
        .fixed-two-col-offset-1 {
            .panel {
                box-shadow: none;
                .panel-body {
                    height: auto;
                    .customer-block-details {
                        .right-block {
                            margin-left: 105px;
                        }
                        .customer-avatar-square-small {
                            width: 100px;
                            height: 58px;
                        }
                    }
                    .order-cashier {
                        padding: 10px 0;
                        border-top: solid 1px #d6e0ef;
                        border-bottom: solid 1px #d6e0ef;
                        border-left: none;
                    }
                    .order-details-item-list-wrapper {
                        height: auto;
                        position: unset;
                        margin-bottom: 250px;
                        .table-striped {
                            position: unset;
                            overflow: unset;
                            height: auto;
                            > thead {
                                display: none;
                            }
                            > tbody {
                                width: 100%;
                                display: inline-block;
                                tr {
                                    width: 100%;
                                    display: inline-block;
                                    padding: 5px;
                                    position: relative;
                                    td {
                                        display: inline-block;
                                        &.col-half {
                                            width: 50%;
                                        }
                                        &.col-full {
                                            width: 100%;
                                        }
                                        .item.info {
                                            width: calc(100% - 40px);
                                        }
                                        .avt-item {
                                            display: none;
                                        }
                                        .input-group {
                                            float: left;
                                        }
                                        .require-operator {
                                            display: block;
                                            float: right;
                                        }
                                        .title {
                                            width: 80px;
                                            font-size: 14px;
                                            float: left;
                                        }
                                        .price-subtotal {
                                            float: left;
                                        }
                                        .order-details-dropdown-container {
                                            position: absolute;
                                            top: 5px;
                                            right: 5px;
                                        }
                                        &.selected-operator {
                                            width: 95%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .order-checkout-block {
                        position: fixed;
                    }
                }
            }
        }
        .fixed-two-col-offset-2 {
            width: 95%;
        }
        .popup-cent .content-popup .bottom-popup .button-container {
            display: block;
            .button-cent {
                width: 100%;
                margin-bottom: 10px;
                margin-left: 0;
            }
        }
        .body-popup .payment-in-many-forms {
            .select-type-payment {
                width: 40%
            }
            .cash-customer {
                width: 60%
            }
            .class-payment-many-item {
                .input-type {
                    margin-bottom: 10px;
                }
            }
        } 
        .old-customer  {
            > div {
                padding: 0 5px;
            }
        }
        .information-customer-order .top-form .item-group-info {
            &.custom-col .value .row > div {
                margin-bottom: 10px;
            }
            .title {
                padding: 0 5px;
                line-height: 1.5;
            }
            .value {
                padding: 0 5px;
            }
        } 
    }
}