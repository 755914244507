.member-card {
  .popup-cent.loading {
    position: absolute;
    .overlay {
        position: absolute;
        opacity: 0.9;
        background: #fff;
        .icon-loading {
            color: slategray;
            font-size: 23px;
            top: 40%
        }
       
    }
}
  .control-label {
    text-transform: none;
  }
  .page-title {
    border-bottom: 1px solid #d3dae7;
  }

  .button-cent {
    margin-right: 10px;
  }

  .blue-green-bg {
    background-color: #02bad4 !important;
  }

  .report-summary-block {
    width: 100%;
    padding: 5px 10px;
    overflow: hidden;
    border-radius: 5px;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
  }

  .search-section {
    background: #e6eef3;
  }

  .report-summary-block>label {
    font-size: 11px;
    margin: 0;
    padding: 0;
    display: block;
    text-transform: uppercase;
    color: white;
  }

  .bg-blue {
    background-color: #03a9f4;
    border-color: #03a9f4;
    color: #fff;
  }

  .account-container .page-container {
    padding-top: 0;
  }

  .fixed-content-wrapper {
    position: absolute;
    top: 108px;
    left: 0;
    right: 0;
    bottom: 42px;
    margin: 0;
    padding: 0;
  }

  .page-container {
    padding: 10px 10px 0;
  }

  .max-height {
    height: 100%;
    margin: 0;
  }

  @media (min-width: 769px) {
    .page-container {
      width: 100%;
      display: table;
      table-layout: fixed;
    }

    .page-container {
      position: relative;
    }
  }

  @media (min-width: 769px) {
    .content-wrapper {
      display: table-cell;
      vertical-align: top;
    }
  }

  .content-wrapper {
    width: 100vw;
    background: white;
  }

  .table-container {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;
    padding: 10px;
  }

  .able-container-wraper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .member-item {
    width: 100%;
    border-bottom: 1px solid #ccc;

    &:hover {
      background-color: #f5f5f5;
    }

    display: flex;
    align-items: center;
    padding: 10px;
  }

  .customer {
    width: 10%;


    p {
      color: #3fa3f3;
    }

    div {
      color: #b4b4b4;
    }
  }

  .card-info-member {
    width: 16%;


    p {
      font-size: 12px;
      color: #202020;
      line-height: 22px;
      font-weight: 700;
      display: flex;

      button {
        background: none;

        i {
          font-size: 13px;
          color: #FF9800;
        }
      }

      div {
        background-color: #8bc34a;
        color: white;
        padding: 0px 3px;
        border-radius: 5px;
        height: 20px
      }
    }

    span {
      font-weight: 400;
      font-size: 13px;
      line-height: 22px;
      color: #2196f3;
    }
  }

  .provided-day {
    width: 8%;
    font-size: 13px;
    font-weight: 400;
    color: rgb(0, 0, 0, 0.87)
  }

  .out-date-time {
    width: 8%;

  }

  .nearest-item-used {
    width: 8%;

  }

  .used-time {
    width: 10%;

    span:first-child {
      font-size: 16px !important;
      padding-left: 10px;
    }

    span:last-child {
      font-size: 10px !important;
    }

  }

  .money-remain {
    width: 10%;

    color: #54aeae;
    font-size: 13px;
    font-weight: 700;
    span {
      padding-left: 10px;
    }
  }

  .status {
    width: 10%;

  }

  .select-member-checkbox {
    width: 8%;

  }

  .history-used-th {
    width: 20%;

    .history-used-title {
      border-radius: 3px;
      border-color: #d3dae7;
      color: #5e6d8c;
      padding: 5px 10px;
      background-color: #d3dae7;
    }

    .rs-dropdown-toggle,
    .rs-dropdown-toggle.rs-btn {
      margin-left: 2px;
      height: 32px;
    }

  }

  .history-used-item-th {
    .history-used-title {
      border-radius: 3px;
      border-color: #d3dae7;
      color: #5e6d8c;
      padding: 5px 10px;
      background-color: #d3dae7;
      cursor: pointer;
    }

    .rs-dropdown-toggle,
    .rs-dropdown-toggle.rs-btn {
      margin-left: 2px;
      height: 32px;
    }
  }

  .pl-2 {
    padding-left: 5px;
  }
  .table-container-wraper {
    padding-top: 20px
  }

  .table-item-container {
    height: 60vh;
    scroll-behavior: auto;
    overflow: scroll;
  }

  .lock {
    background-color: #00bcd4;
    color: white;
    border-radius: 3px;
    width: 55px;
    text-align: center;
    height: 20px;
  }

  .complete {
    background-color: #777777;
    color: white;
    width: 80px;
    text-align: center;
    border-radius: 3px;
  }

  .out-of-date {
    @extend .complete;
    width: 60px;
  }

  .used {
    background-color: #8bc34a;
    color: white;
    width: 63px;
    text-align: center;
    border-radius: 3px;
  }
  .status-package {
    margin-left: 10px;
    width: 63px;
  }

  .history-used {
    display: flex;
    align-items: center;
  }

  .history-used-item-th {
    width: 20%;
    display: flex;
    align-items: center;
  }

  .cl-green {
    color: #8bc34a;
  }

  .cl-out-of-date {
    color: #777 !important;
    text-decoration: line-through;
  }

  .btn,
  .btn-danger.active,
  .btn-danger:active,
  .btn-default.active,
  .btn-default:active,
  .btn-info.active,
  .btn-info:active,
  .btn-primary.active,
  .btn-primary:active,
  .btn-warning.active,
  .btn-warning:active,
  .btn.active,
  .btn:active,
  .dropdown-menu>.disabled>a:focus,
  .dropdown-menu>.disabled>a:hover,
  .form-control,
  .navbar-toggle,
  .open>.dropdown-toggle.btn-danger,
  .open>.dropdown-toggle.btn-default,
  .open>.dropdown-toggle.btn-info,
  .open>.dropdown-toggle.btn-primary,
  .open>.dropdown-toggle.btn-warning {
    background-image: none;
  }

  .no-shadow,
  input {
    box-shadow: none !important;
  }

  li {
    list-style: none !important;
  }

  .filter-item {
    list-style: none;

    input::placeholder {
      font-size: 12px;
    }

    .customer-block-filter {
      .form-control {
        height: calc(2.25rem + 0px);
      }
    }
    .rs-picker.rs-picker-daterange.rs-picker-default {
      height: 38px !important;
      margin-right: 5px;
      width: 100%;
      .rs-picker-toggle.rs-btn.rs-btn-default.rs-btn-sm {
        height: 100%;
        line-height: 30px;
      }
    }
  }

  .col-xs-2 {
    width: 16.67%;
  }

  .col-xs-3 {
    width: 25%;
  }

  .col-xs-1 {
    width: 8.3%;
  }

  .select2-hidden-accessible {
    border-color: #dddddd;
    height: 35px !important;
    margin: 0px 5px 0px 0px;
    border-radius: 2px;
    font-size: 12px;
    color: #333;
    width: 98%;
  }

  .ng-binding {
    font-size: 12px;
    font-weight: 400px;
    line-height: 18px;
    color: #5e6d8c;
    margin-bottom: 0px;
  }
  // p {
  //   margin-bottom: 0px;
  // }

  .ng-scope {
    font-size: 12px;
    line-height: 20px;
    color: #333;
    font-weight: 400;
  }

  .text-bold {
    font-weight: 700;
  }

  .text-blue-royal-icing {
    color: #4a90e2 !important;
  }

  .font-16 {
    font-size: 16px;
  }

  .alert-warning {
    color: #aa3510;
    word-spacing: 1px;
    background-color: #fff3e0;
    border-color: #ff9800;
    padding: 3px;
  }

  .m-10 {
    margin: 10px;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .align-center {
    align-items: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-around {
    justify-content: space-around;
  }

  .panel-body {
    background-color: white;
    padding: 5px 15px;

    .row {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .paging_first_last_numbers {
    float: right;
    padding-right: 30px;
    padding-top: 15px;
  }

}

.button-close-modal {
  padding: 0px 10px 0px 0px;
  border-radius: 5px;
  background-color: #f0f0f0;

  i {
    padding: 10px;
    height: 100%;
    margin-right: 10px;
    background-color: #cccccc;
  }

  .pb-10 {
    padding-bottom: 10px;
  }

  .pl-10 {
    padding-left: 10px;
  }

  .pr-10 {
    padding-right: 10px;
  }

  .border-none {
    border: none;
  }

  .w-100 {
    width: 100%;
  }

  .w-80 {
    width: 80%;
  }

  .w-50 {
    width: 50%;
  }

  .text-red {
    color: #f76f65;
  }

  .panel {
    color: #5e6d8c;
    border: 1px solid #d3dae7 !important;
  }

  .max-height {
    height: 100%;
    margin: 0;
  }

  .panel {
    margin-bottom: 20px;
  }

  .panel {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  }

  .form-control {
    padding: 7px;
    border-color: #d3dae7;
    color: inherit;
  }

  .input-xs {
    height: 32px;
    line-height: 1.6666667;
  }

  .form-group-xs .form-control,
  .input-xs {
    border-radius: 2px;
    padding: 5px 10px;
    font-size: 12px;
  }

  .form-control {
    box-shadow: none;
  }

  .form-control {
    width: 100%;
    height: 36px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    -webkit-transition: border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  .form-control,
  output {
    font-size: 13px;
    line-height: 1.5384616;
    display: block;
  }
}

// modal style 
.modal-header {
  padding-top: 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d6e0ef;
}

.modal-footer {
  padding-top: 10px;
  padding-bottom: 0px;
  border-top: 1px solid #d6e0ef;
}

.modal-body {
  margin-top: 0px;

  .modal-title {
    color: #333;
    font-size: 12px;
    font-weight: 700;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}

.button-renew-card-modal {
  color: white;
  background: #f2480c;
  padding: 0 10px 0px 0px;

  i {
    padding: 10px;
    height: 100%;
    margin-right: 10px;
    background-color: #ce3d0a;
  }
}

.package-history {
  .body-popup {
    height: 500px;
  }
  .panel.panel-flat {
    color: #5e6d8c;
    border: 1px solid #d3dae7!important;
    margin-bottom: 20px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    .panel-body{
      padding: 0 10px 10px;
      position: relative;
      .col-3 {
        .customer-name {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
      .col-5 {
        .package-order {
          text-transform: uppercase;
          font-weight: bold;
          color: #9C27B0;
        }
        .tag {
          border-radius: 3px;
          color: #fff;
          display: inline-block;
          margin: 5px 5px 5px 0;
          padding: 0 5px;
        }
      }
    }
  }

  .fixed-content-wrapper.customer-list-container {
    top : 235px;
    background-color: #fff;
    .report-summary-block{
      width: 200px
    }
  }
  .font-20 {
    font-size: 20px !important;
  }
  .bg-green {
    background-color: #8BC34A;
    border-color: #8BC34A;
  }
}

.popup-package-edit {
  .modal-body {
    padding: 10px;
    position: relative;
    .form-control {
      height: calc(2.25rem + -4px);
    }
    .div-search {
      width: 100%;
      height: 32px;
      border: 1px solid #d3dae7;
      border-radius: 3px;
      cursor: pointer;
      padding-left: 10px;
      line-height: 32px;
    }
    .result-search{
      position: absolute;
      z-index: 6;
      background: #fff;
      width: calc(100% - 10px);
      border: 1px solid #d3dae7;
      border-radius: 3px;
      .items-customer{
        height: 350px;
        overflow: hidden;
        overflow-y: scroll;
        .item {
          border-bottom: 1px solid #d3dae7;
          display: flex;
          cursor: pointer;
          padding: 10px;
          .avatar {
            float: left;
            img {
              border-radius: 50%;
            }
          }
          .info {
            float: left;
            padding-left: 10px;
            width: calc(100% - 36px);
            line-height: 20px;
            .name {
              text-transform: uppercase;
              border-radius: 3px;
              background-color: red;
              color: #fff;
              display: inline-block;
              padding: 3px;
              position: relative;
              top: -5px;
              width: auto;
              
            }
            .phone {
              position: relative;
              top: -7px;
              color: #999;
              font-size: 10px;
              width: 100%;
            }
            .address {
              color: #999;
              font-size: 10px;
              width: 100%;
              margin-top: -11px;
            }
          }
        }

      }
    }
  }
}
