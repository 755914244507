.popupHeader {
    padding: 10px;
    border-bottom: 1px solid #d3dae7;
}
.modal{
    width: 800px;
}

.textHeader {
    font-family: inherit;
    font-weight: 400;
    line-height: 1.5384616;
    color: inherit;
    font-size: 17px;
}

.createdText {
    color: rgb(153, 153, 153);
    font-style: italic;
    font-size: 10px;
    margin-left: 3rem !important;
    margin-top: 0.7rem
}

.avatar {
    cursor: pointer;
}
.iconCamera{
    color: #EF5350!important;
    margin-left: 0.4rem;
    position: relative;;
}
.infor1{
    float: left;
    margin-left: 0rem;
    padding-left: 1rem;
}
.strong{
    font-weight: 700;
    color: #5e6d8c;
    font-size: 12px;
}
.textName{
    font-size: 16px;
    margin: 0;
    text-transform: uppercase;
    color: #2196F3
}
.lastCome{
    clear: both;
    color: #999;
    float: left;
    font-size: 12px;
}
.rankText{
    color: #2196F3;
    font-size: 12px;
}
.circle{
    background: #ff5722;
    border-radius: 50%;
    clear: both;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    height: 60px;
    line-height: 60px;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 60px;
    display: inline-block;
}
.ousideCircles{
    overflow: hidden;
    text-align: center;
}
.bottomCircle{
    color: #ff5722;
    display: block;
    font-size: 10px
}
.location{
    font-size: 12px;
    color: red;
}
.location2{
    color: #2196F3!important;
    font-weight: 700;
    font-size: 12px;
    margin-left: 0.2rem;
    margin-top: 0px;
}
.bookingRate{
    height: 7px;
    margin-top: 4px;
    background-color: #558B2F;
    border-color: #558B2F;
    color: #fff;
}
.ul{
    border-bottom: 1px solid #ddd;
    width: 100%;
    float: left;
}
.li{
    display: table-cell;
    width: 1%;
    padding-left: 0;
    text-align: center;
    padding-left: 0;
    cursor: pointer;
}
.li1{
    display: table-cell;
    width: 1%;
    border-bottom: 1px solid #2196F3;
    font-size: 15px;
    color: black;
    float: none;
    text-align: center;
    padding-left: 0;
    cursor: pointer;
}
.li:hover {
    color: black;
    font-weight: 700;
  }
.textInfor{
    font-weight: 700;
    color: #5e6d8c;
    font-size: 12px;
}
.textDateChilOrder{
    width: 20%;
    text-align: left!important;
    font-weight: 700;
    color: #5e6d8c;
    font-size: 12px;
    margin-bottom: 0px;
    padding-left: 0;
    list-style: none;
}
.textFormatDateChildOrder{
    color: #2196F3!important;
    font-weight: 700;
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 1px;
    padding-left: 0;
    list-style: none;
}
.orderId{
    float: left;
    font-weight: 400;
    color: #4a90e2;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 1px;
    padding-left: 0;
    list-style: none;
    cursor: pointer;
}
.serviceTextChildOrder{
    font-weight: 700;
    line-height: 20px;
    margin-top:0rem
}
.statusTextChildOrder{
    font-weight: 700;
    line-height: 20px;
    margin-top:0rem;
}
.tagChildOrder{
    white-space: normal;
    margin: 0 5px 5px 0;
    background: #efefef;
    color: #5e6d8c;
    border: 1px solid #ddd;
}
.textCash{
    color: #259B9A;
    position: relative;
    white-space: nowrap;
}
.tagOwed{
    background-color: #f2480c;
    border-color: #f2480c;
    font-size: smaller;
    line-height: 10px;
    padding: 2px 5px 1px;
    font-weight: 500;
    color: #fff;
}
.tagDone{
    background-color: #8BC34A;
    border-color: #8BC34A;
    font-size: smaller;
    line-height: 10px;
    text-transform: none;
    padding: 2px 5px 1px;
    font-weight: 500;
    color: #fff;
}
.tagPending{
    background-color: #F4511E;
    border-color: #F4511E;
    font-size: smaller;
    line-height: 10px;
    text-transform: none;
    padding: 2px 5px 1px;
    font-weight: 500;
    color: #fff;
}
.tagCancle{
    background-color: #C62828;
    border-color: #C62828;
    font-size: smaller;
    line-height: 10px;
    text-transform: none;
    padding: 2px 5px 1px;
    font-weight: 500;
    color: #fff;
}
.tagKTV{
    border-radius: 3px;
    display: inline-block;
    margin: 5px 5px 5px 0;
    padding: 0 5px;
    border-color: #2196F3;
    background-color: #2196F3;
    color: #fff;
}
.divStatus{
    float: right;
    margin-right: 1.5rem;
}
.textCountTime{
    font-weight: 700;
    color: #2196F3!important;

}
.textIncomingOrder{
    float: right;
    color: #66bb6a;
    font-size: 12px;
}
.textPlaceBookingIncomingOrder{
    clear: both;
    color: #999;
    float: right;
    font-size: 12px;
}
.scrollTransaction{
  height: 200px;
  width: auto;
  overflow: scroll;
}
.buttn1{
    background-color: #4a90e2;
    border-color: #4a90e2;
}
.buttn2{
    background-color: #6e7378;
    border-color: #6e7378;
}

@media (max-width: 1023px) {
    .scrollTransaction{
        height: 300px;
    }
}
