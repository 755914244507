.addButton {
    background: #EB661B;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    padding: 15px 30px;
}

.saveButton {
    background: #EB661B;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    padding: 5px 30px;
}

.addCategoryButton {
    background:#007bff;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    padding: 15px 30px;
}
.widthLabel{
    flex: 0 0 15%;
    max-width: 15%;
}

.widthValue {
    flex: 0 0 85%;
    max-width: 85%;
}

.fixDiv{
    position: sticky;
    bottom: 10px;
    padding-right: 5%;
    background: white;
    z-index: 1;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.disable{
    opacity: 0.5;
    pointer-events: none;
}
.actionBlock {
    margin-top: 20px;
}
.wrapperVoucher {
    padding: 0 20px;
    margin-top: 20px;
}

.w50 {
    width: 50%;
}
@media (max-width: 1199px) {
    .actionBlock {
        margin-top: 10px;
    }
    .itemSearch {
        margin-bottom: 10px;
    }
    .widthLabel{
        flex: 0 0 17%;
        max-width: 17%;
    }
    .widthValue {
        flex: 0 0 83%;
        max-width: 83%;
    }
}

@media (max-width: 991px) {
    .widthLabel{
        flex: 0 0 20%;
        max-width: 20%;
    }
    .widthValue {
        flex: 0 0 80%;
        max-width: 80%;
    }
}

@media (max-width: 767px) {
    .wrapperVoucher {
        margin-top: 60px;
        padding-bottom: 30px;
    }
    .widthLabel, .widthValue{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .w50 {
        width: 100%;
    }
}
