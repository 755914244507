.reward-page {
    padding: 50px 0;
    .title-reward {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 30px;
    }
    .item-reward {
        padding: 0 30px;
        margin-bottom: 50px;
        .title-detail {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            .count {
                font-size: 14px;
                span {
                    font-weight: 700;
                    display: inline-block;
                    font-size: 18px;
                    color: #fb8921;
                }
            }
        }
    }
    .reward-category {
        margin-bottom: 50px;
    }
}

.progress-striped {
    background: rgb(251 137 33 / 25%);
    border-radius: 6px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
    position: relative;
    .progress-bar {
        height: 10px;
        border-radius: 4px; 
        transition: 0.4s linear;  
        transition-property: width, background-color;    
        background-color: #fb8921; 
        background-image: linear-gradient(45deg, rgb(252,163,17) 25%,transparent 25%, transparent 50%, rgb(252,163,17) 50%, rgb(252,163,17) 75%, transparent 75%, transparent); 
        animation: progressAnimationStrike 3s;
        position: relative;
        .current-process {
            .icon {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                position: absolute;
                top: -3.5px;
                right: 0;
                background: #fb8921;
            }
            
        }
    }
    .end-process {
        .icon {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            position: absolute;
            top: -5px;
            right: 0;
            background: #fb8921;
        }
    }
}
.text-process {
    margin-top: 15px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    span {
        font-size: 18px;
    }
    .buy span {
        color: #0014ff;

    }
    .total span {
        color: #fb8921;
    }
    .count span {
        color: #28a745;
        font-weight: 700;
    }
}
.text-success {
    margin-top: 15px;
    font-size: 18px;
}
@keyframes progressAnimationStrike {
    from { width: 0 }
    to   { width: 100% }
}