.admin-page.customer {
    .order-complete {
        background-color: #8BC34A;
        padding: 2px 5px;
        border-radius: 2px;;
    }

    .order-canceled {
        background-color: #C62828;
        padding: 2px 5px;
        border-radius: 2px;
    }

    .order-swich {
        background-color: #700909;
        padding: 2px 5px;
        border-radius: 2px;
    }

    .order-incomplete {
        background-color: #FF9800;
        padding: 2px 5px;
        border-radius: 2px;
    }

    .order-upsale {
        background-color: #26A69A;
        padding: 2px 5px;
        border-radius: 2px;
    }
    .top-body {
        padding: 0 15px 10px;
        display: flex;
        font-size: 12px;
        font-weight: 600;
        color: rgb(94, 109, 140);

        .item {
            width: 25%;

            .filter {
                display: inline-block;
                cursor: pointer;
            }

            b {
                font-weight: 600;
                font-size: 18px;
                margin-left: 5px;
            }
        }
    }

    .admin-body {
        padding: 0;
        color: #5e6d8c;

        .dashboard-content {
            border: none;
            padding: 0;
            .wrapperCrm{
                top:9%;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 15px;
                margin: 0;
                padding: 0;
                .fixed-column {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    // overflow-x: hidden;
                    // overflow-y: auto;
                    box-shadow: 0 0 15px rgb(0 0 0 / 8%);

                    &.list-customers {
                        left: 10px;
                        right: calc(66% + 5px);
                    }

                    &.detail-customer {
                        left: calc(34% + 5px);
                        right: calc(26% + 5px);

                        .body-col {
                            padding: 0 10px;
                            height: calc(100% - 90px);
                        }
                    }

                    &.more-info-customer {
                        left: calc(74% + 5px);
                        right: 10px;

                        .header-col {
                            padding: 0;
                        }
                    }
                    .header-col {
                        border-bottom: 1px solid #d3dae7;
                        padding: 5px 10px;
                        background: #fff;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .title {
                            font-weight: 700;
                            font-size: 14px;
                        }

                        .action {
                            >div {
                                display: inline-block;
                                margin-left: 10px;
                            }

                            .search-form {
                                position: relative;

                                .search {
                                    padding: 7px 30px;
                                    border-radius: 30px;
                                    font-weight: 400;
                                    height: 32px;
                                    border: 1px solid #d3dae7;
                                    font-size: 12px;
                                }

                                i {
                                    position: absolute;
                                    top: 10px;
                                    left: 10px;
                                    font-size: 14px;
                                    color: #ddd;
                                }
                            }

                            .download-data {
                                border-radius: 100%;
                                width: 30px;
                                height: 30px;
                                background-color: #4a90e2;
                                color: #fff;
                                text-align: center;
                                font-size: 14px;
                                line-height: 30px;
                                cursor: pointer;
                            }
                        }

                        .title-tab {
                            width: 50%;
                            text-align: center;
                            padding: 11px 15px;
                            cursor: pointer;
                            font-size: 13px;
                            position: relative;

                            &.active {
                                color: #000;

                                &:before {
                                    content: '';
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    background-color: #2196F3;
                                    height: 2px;
                                }
                            }
                        }

                    }

                    .popup-cent.loading {
                        position: absolute;
                        .overlay {
                            position: absolute;
                            opacity: 0.9;
                            background: #fff;
                            .icon-loading {
                                color: slategray;
                                font-size: 23px;
                                top: 40%
                            }
                           
                        }
                    }

                    .body-col {
                        height: calc(100% - 42px);
                        overflow: auto;
                        width: 100%;
                        position: relative;
                        .customer-block-list {
                            .empty-customer {
                                text-align: center;
                                padding: 20px 15px;
                            }
                            .item-customer {
                                border-bottom: 1px solid #d3dae7;
                                cursor: pointer;
                                overflow: hidden;
                                padding: 5px 10px;

                                .avatar {
                                    float: left;

                                    img {
                                        height: 36px;
                                        width: 36px;
                                        border-radius: 50%;
                                        border: 1px solid #d6e0ef;
                                        object-fit: cover;
                                    }
                                }

                                .info {
                                    float: left;
                                    margin-left: 10px;
                                    width: calc(100% - 150px);

                                    .name {
                                        margin-bottom: 5px;
                                        text-transform: uppercase;
                                        font-weight: 700;
                                        font-size: 11px;
                                        color: #2196F3;

                                        i {
                                            color: #EF5350;
                                        }
                                    }

                                    .phone,
                                    .address {
                                        color: #999;
                                        font-size: 10px;
                                        margin: 2px 0;
                                    }
                                }

                                &.active,
                                &:hover {
                                    background-color: #f4fdff;
                                }
                            }
                        }

                        .filter-block {
                            border-bottom: 1px solid #d3dae7;
                            padding: 10px;
                            font-size: 12px;

                            .filter-head {
                                label {
                                    font-weight: 700;
                                    min-width: 90px;
                                }

                                .tag {
                                    background-color: #8bc34a;
                                    border-radius: 3px;
                                    color: #fff;
                                    display: inline-block;
                                    padding: 0 5px;
                                    margin-left: 5px;
                                    cursor: pointer;

                                    &:hover {
                                        i {
                                            color: #000;
                                        }
                                    }

                                    i {
                                        position: relative;
                                        top: 0.5px;
                                    }
                                }
                            }

                            .action-filter {
                                .action-delete {
                                    color: #4a90e2;
                                    cursor: pointer;
                                }
                            }
                        }

                        .customer-block-details {
                            margin: 10px 0;
                            display: inline-block;
                            width: 100%;

                            .customer-avatar {
                                float: left;
                                cursor: pointer;
                                position: relative;
                                margin-right: 10px;

                                img {
                                    width: 100px;
                                    height: 105px;
                                    object-fit: cover;
                                    border-radius: 50%;
                                }
                            }

                            .customer-basic-info {
                                float: left;
                                font-size: 12px;
                                width: calc(100% - 190px);

                                input:not([type=radio]) {
                                    width: 50%;
                                }

                                .customer-name {
                                    font-size: 16px;
                                    margin: 0;
                                    text-transform: uppercase;
                                    color: #2196F3;
                                }

                                .date-create,
                                .last-visited {
                                    clear: both;
                                    color: #999;
                                    font-size: 11px;
                                }

                                .ranking {
                                    border-radius: 3px;
                                    color: #fff;
                                    display: inline-block;
                                    margin: 5px 5px 5px 0;
                                    padding: 0 5px;
                                    font-size: 11px;
                                    background-color: #999;

                                    &.rank-1 {
                                        background-color: #999;
                                    }

                                    &.rank-2 {
                                        background-color: #666;
                                    }

                                    &.rank-3 {
                                        background-color: #FFB74D;
                                    }

                                    &.rank-4 {
                                        background-color: #EF6C00;
                                    }

                                    &.rank-5 {
                                        background-color: #C62828;
                                    }
                                }
                            }

                            .customer-loyaty {
                                float: right;
                                overflow: hidden;
                                text-align: center;

                                .point {
                                    background: #fb8a25;
                                    border-radius: 50%;
                                    clear: both;
                                    color: #fff;
                                    font-size: 20px;
                                    font-weight: 400;
                                    height: 60px;
                                    line-height: 60px;
                                    margin: 0;
                                    padding: 0;
                                    text-align: center;
                                    width: 60px;
                                    display: inline-block;
                                    cursor: pointer;
                                    .fa-tools {
                                        font-size: 28px;
                                        color: aliceblue;
                                        margin-top: 17px;
                                    }
                                }

                                .title {
                                    color: #fb8a25;
                                    font-size: 12px;
                                    font-weight: 600;
                                }
                            }
                        }
                        .button-edit {
                            position: absolute;
                            left: 100px;
                            top: 92px;
                            cursor: pointer
                        }

                        .booking-rate {
                            margin-bottom: 20px;
                            font-size: 11px;

                            .item-booking {
                                display: flex;

                                .title-col {
                                    padding: 0;
                                }

                                .data-col {
                                    padding: 0;

                                    .wrap-bar {
                                        width: calc(100% - 50px);
                                        display: inline-block;

                                        .bar {
                                            height: 7px;
                                            margin-top: 4px;
                                            float: right;
                                        }
                                    }

                                    .count {
                                        font-weight: 700;
                                        float: right;
                                    }

                                    .value {
                                        color: #2196F3;
                                        font-size: 12px;
                                    }
                                }

                                &.list-stores {
                                    margin-top: 10px;

                                    .title-col {
                                        .label {
                                            font-size: 12px;
                                        }
                                    }

                                    .data-col .value span:not(:last-child):after {
                                        content: ",";
                                        margin-right: 3px;
                                        display: inline-block;
                                    }
                                }
                            }
                            .item-owed {
                                color: red;
                                cursor: pointer;
                            }
                            .item-credit {
                                margin-top: 10px;

                                .title-col {
                                    .label {
                                        font-size: 12px;
                                    }
                                }
                                .edit-credit {
                                    padding-left: 10px;
                                    text-decoration: underline;
                                    cursor: pointer;
                                    font-size: 11px;
                                    .content-popup {
                                        width: 1024px;
                                    }
                                }
                                .show-history {
                                    float: right;
                                    color: #5e6d8c;
                                    font-size: 11px;
                                    text-decoration: underline;
                                    cursor: pointer;
                                }
                            }   
                        }

                        .customer-more-info {
                            .tabs-title {
                                border-bottom: 1px solid #ddd;
                                margin-bottom: 20px;

                                .item-tab {
                                    display: inline-block;
                                    font-size: 12px;
                                    color: #333;
                                    padding: 9px 15px;
                                    position: relative;
                                    cursor: pointer;

                                    &.active {
                                        &::after {
                                            content: '';
                                            position: absolute;
                                            bottom: 0;
                                            left: 0;
                                            right: 0;
                                            background-color: #2196F3;
                                            height: 2px;
                                        }
                                    }
                                }
                            }

                            .tabs-data {
                                font-size: 12px;

                                .items-tab {
                                    display: none;

                                    &.active {
                                        display: block;
                                    }

                                    .item {
                                        display: inline-block;
                                        width: 100%;
                                        margin-bottom: 7px;

                                        .label {
                                            float: left;
                                            width: 25%;
                                        }

                                        .value {
                                            float: left;
                                            width: 75%;
                                            overflow-wrap: break-word;

                                            .select-birth {
                                                display: flex;
                                                margin: 0 -5px;

                                                .data-birthday {
                                                    width: 33.333%;
                                                    padding: 0 5px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            input:not([type=radio]) {
                                width: 100%;
                            }
                        }

                        .list-information {
                            padding: 0 10px;

                            .item {
                                border-bottom: 1px solid #dcdcdc;

                                .title {
                                    margin: 10px 0;
                                    font-weight: 700;
                                    font-size: 14px;
                                    cursor: pointer;
                                }

                                ul {
                                    padding-left: 0px;
                                    font-size: 12px;

                                    li {
                                        a {
                                            width: 100%;

                                            .item-booking {
                                                display: flex;
                                            }
                                        }

                                        margin-bottom: 10px;
                                        display: flex;
                                        padding: 0;
                                        cursor: pointer;

                                        .right {
                                            font-size: 12px;
                                            color: #5e6d8c;

                                            .customer-booking-time {
                                                font-weight: 700;
                                                color: #2196F3 !important;
                                            }

                                            .customer-booking-location-view {
                                                font-weight: 700;
                                                color: #5e6d8c;

                                                .text-grey-300 {
                                                    color: #999 !important;

                                                }
                                            }
                                        }

                                        .left {
                                            text-align: end;

                                            .customer-booking-total {
                                                color: #66bb6a;
                                            }

                                            .customer-booking-items {
                                                clear: both;
                                                color: #999;
                                                float: right;
                                                font-size: 12px;

                                                span {
                                                    background-color: #8BC34A !important;
                                                    border-color: #8BC34A;
                                                    font-size: smaller;
                                                    line-height: 10px;
                                                    text-transform: none;
                                                    padding: 2px 5px 1px;
                                                    font-weight: 500;
                                                    color: #fff;
                                                }
                                                .bg-red {
                                                    background-color: red !important;
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                            .total-price-detail {
                                text-align: end;
                                font-size: 12px;
                                padding-right: 10px;
                            }
                        }

                        input:not([type=radio]) {
                            border: 1px solid #d3dae7;
                            border-radius: 3px;
                            padding: 7px;
                            height: 34px;
                            color: #5e6d8c;

                            &:focus {
                                background: #f6fdff !important;
                                border: 1px solid #9fbccc;
                            }

                            &.required {
                                font-size: 13px;
                                font-weight: 700;
                                border-color: #6aa1ce;
                                background: rgba(249, 255, 0, 0.05);
                            }
                        }

                        .select {
                            width: 100%;
                            &.location {
                                input:focus {
                                    background: transparent !important;
                                }
                            }
                            &.half {
                                width: 50%;
                            }
                        }
                    }

                    .bottom-col {
                        position: absolute;
                        padding: 7px 20px;
                        background-color: #fcfcfc;
                        border-top: 1px solid #ddd;
                        top: calc(100% - 47px);
                        width: 100%;

                        .action {
                            float: right;

                            >div {
                                display: inline-block;
                                margin-left: 10px;
                            }
                        }
                    }

                    .order-popup {
                        .head-popup {
                            .order-status {
                                clear: both;
                                color: #999;
                                font-size: 12px;
                                position: absolute;
                                right: 10px;
                                top: 15px;
                                padding-left: 20px;

                                span {
                                    // background-color: #8BC34A !important;
                                    // border-color: #8BC34A;
                                    font-size: smaller;
                                    line-height: 10px;
                                    text-transform: none;
                                    padding: 2px 5px 1px;
                                    font-weight: 500;
                                    color: #fff;
                                }
                            }
                        }

                        .body-popup {
                            .customer {
                                .customer-avatar {
                                    position: relative;

                                    img {
                                        height: 60px;
                                        margin-right: 10px;
                                        width: 60px;
                                        border: solid 1px #ddd;
                                        border-radius: 50%;
                                        object-fit: cover;
                                    }

                                    .info-customer {
                                        position: absolute;
                                        top: 10px;
                                        left: 70px;

                                        p {
                                            font-size: 12px;
                                            color: #777;
                                            margin-top: 0px;
                                            font-weight: 700;

                                        }

                                        p:first-child {
                                            color: #4a90e2;
                                            font-size: 16px;
                                            text-transform: uppercase;
                                        }
                                    }
                                }
                            }

                            .order-info {
                                margin-top: 15px;
                                padding-bottom: 10px;

                                span {
                                    color: #2196F3 !important;
                                }

                                p {
                                    color: #5e6d8c;
                                    font-size: 12px;
                                }

                                .user-order-customer {
                                    margin-top: 10px;
                                    padding-bottom: 10px;
                                    border-bottom: #5e6d8c 1px;

                                    .user-titel {
                                        p {
                                            color: #5e6d8c;
                                            font-size: 12px;
                                        }
                                    }

                                    .user-order-date {
                                        p {
                                            background-color: #999;
                                            border-color: #999;
                                            color: #fff;
                                            font-size: smaller;
                                            line-height: 12px;
                                            text-transform: none;
                                            padding: 1px 5px;
                                        }
                                    }
                                }

                            }

                            .table-list-order-item {
                                font-size: 12px;
                                max-height: 200px;
                                overflow: auto;
                                padding-top: 10px;
                                border-top: solid 1px #d3dae7;

                                table {
                                    width: 100%;

                                    .order-item-tr {
                                        border-bottom: solid 1px #d3dae7;
                                    }

                                    tr {
                                        margin-bottom: 15px !important;

                                        th {
                                            color: #2196F3 !important;
                                            font-weight: 700;
                                        }

                                        th:last-child {
                                            text-align: end;
                                        }

                                        td:first-child {
                                            padding-top: 10px;
                                        }

                                        td:last-child {
                                            text-align: end;
                                        }
                                    }
                                }

                                .order-item-detail {
                                    padding-bottom: 15px;

                                    .item-avatar {
                                        display: flex;

                                        .info-service {
                                            margin-left: 10px;
                                            p:first-child {
                                                color: #5e6d8c;
                                                font-weight: 700;
                                            }
                                        }

                                        img {
                                            width: 50px;
                                            height: 50px;
                                        }
                                    }

                                    p {
                                        font-size: 12px;
                                        margin-top: 0px;
                                    }
                                }
                            }

                            .order-remind-item {
                                border-top: unset !important;

                                .none-remind {
                                    position: absolute;
                                    top: 56px;
                                    height: 150px;
                                    text-align: center;
                                    width: 100%;
                                }
                            }

                            .item-pagination {
                                text-align: end;
                                border-top: 1px solid #e0e0e0;
                                padding: 0 24px;
                                border-bottom: 1px solid #e0e0e0;

                                .ag-paging-panel {
                                    padding: 10px 0;
                                }
                            }

                            .order-into-money {
                                font-weight: 700;
                                font-size: 12px;
                                margin-top: 15px;
                                padding-bottom: 10px;
                                border-bottom: #d3dae7 solid 1px;

                                p {
                                    padding-bottom: 0;
                                }

                                .list-order-price {
                                    text-align: end;
                                    font-weight: 700;
                                }
                            }

                            .order-total-money {
                                margin-top: 15px;
                                padding-bottom: 10px;
                                border-bottom: #d3dae7 solid 1px;
                                font-size: 12px;

                                .order-price-type {
                                    font-weight: 700;
                                    color: #5E6D94;

                                    p:first-child {
                                        width: 70%;
                                        font-size: 20px;
                                    }
                                }

                                .list-order-price {
                                    text-align: end;

                                    p:first-child {
                                        font-size: 20px;
                                    }

                                    font-weight: 700;
                                }

                                .node-order {
                                    .node-title {
                                        color: #2196F3 !important;
                                        font-weight: 700;
                                    }

                                    .node-text {
                                        background-color: #eeeeee;
                                        padding: 5px 5px 5px 5px;
                                        color: #777 !important;
                                        display: inline-block;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }

                }
            }
            .wrapper {
                position: absolute;
                top: 155px;
                left: 0;
                right: 0;
                bottom: 15px;
                margin: 0;
                padding: 0;

                .fixed-column {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    // overflow-x: hidden;
                    // overflow-y: auto;
                    box-shadow: 0 0 15px rgb(0 0 0 / 8%);

                    &.list-customers {
                        left: 10px;
                        right: calc(66% + 5px);
                    }

                    &.detail-customer {
                        left: calc(34% + 5px);
                        right: calc(26% + 5px);

                        .body-col {
                            padding: 0 10px;
                            height: calc(100% - 90px);
                        }
                    }

                    &.more-info-customer {
                        left: calc(74% + 5px);
                        right: 10px;

                        .header-col {
                            padding: 0;
                        }
                    }

                    .header-col {
                        border-bottom: 1px solid #d3dae7;
                        padding: 5px 10px;
                        background: #fff;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .title {
                            font-weight: 700;
                            font-size: 14px;
                        }

                        .action {
                            >div {
                                display: inline-block;
                                margin-left: 10px;
                            }

                            .search-form {
                                position: relative;

                                .search {
                                    padding: 7px 30px;
                                    border-radius: 30px;
                                    font-weight: 400;
                                    height: 32px;
                                    border: 1px solid #d3dae7;
                                    font-size: 12px;
                                }

                                i {
                                    position: absolute;
                                    top: 10px;
                                    left: 10px;
                                    font-size: 14px;
                                    color: #ddd;
                                }
                            }

                            .download-data {
                                border-radius: 100%;
                                width: 30px;
                                height: 30px;
                                background-color: #4a90e2;
                                color: #fff;
                                text-align: center;
                                font-size: 14px;
                                line-height: 30px;
                                cursor: pointer;
                            }
                        }

                        .title-tab {
                            width: 50%;
                            text-align: center;
                            padding: 11px 15px;
                            cursor: pointer;
                            font-size: 13px;
                            position: relative;

                            &.active {
                                color: #000;

                                &:before {
                                    content: '';
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    background-color: #2196F3;
                                    height: 2px;
                                }
                            }
                        }

                    }

                    .popup-cent.loading {
                        position: absolute;
                        .overlay {
                            position: absolute;
                            opacity: 0.9;
                            background: #fff;
                            .icon-loading {
                                color: slategray;
                                font-size: 23px;
                                top: 40%
                            }
                           
                        }
                    }

                    .body-col {
                        height: calc(100% - 42px);
                        overflow: auto;
                        width: 100%;
                        position: relative;
                        .customer-block-list {
                            .empty-customer {
                                text-align: center;
                                padding: 20px 15px;
                            }
                            .item-customer {
                                border-bottom: 1px solid #d3dae7;
                                cursor: pointer;
                                overflow: hidden;
                                padding: 5px 10px;

                                .avatar {
                                    float: left;

                                    img {
                                        height: 36px;
                                        width: 36px;
                                        border-radius: 50%;
                                        border: 1px solid #d6e0ef;
                                        object-fit: cover;
                                    }
                                }

                                .info {
                                    float: left;
                                    margin-left: 10px;
                                    width: calc(100% - 150px);

                                    .name {
                                        margin-bottom: 5px;
                                        text-transform: uppercase;
                                        font-weight: 700;
                                        font-size: 11px;
                                        color: #2196F3;

                                        i {
                                            color: #EF5350;
                                        }
                                    }

                                    .phone,
                                    .address {
                                        color: #999;
                                        font-size: 10px;
                                        margin: 2px 0;
                                    }
                                }

                                &.active,
                                &:hover {
                                    background-color: #f4fdff;
                                }
                            }
                        }

                        .filter-block {
                            border-bottom: 1px solid #d3dae7;
                            padding: 10px;
                            font-size: 12px;

                            .filter-head {
                                label {
                                    font-weight: 700;
                                    min-width: 90px;
                                }

                                .tag {
                                    background-color: #8bc34a;
                                    border-radius: 3px;
                                    color: #fff;
                                    display: inline-block;
                                    padding: 0 5px;
                                    margin-left: 5px;
                                    cursor: pointer;

                                    &:hover {
                                        i {
                                            color: #000;
                                        }
                                    }

                                    i {
                                        position: relative;
                                        top: 0.5px;
                                    }
                                }
                            }

                            .action-filter {
                                .action-delete {
                                    color: #4a90e2;
                                    cursor: pointer;
                                }
                            }
                        }

                        .customer-block-details {
                            margin: 10px 0;
                            display: inline-block;
                            width: 100%;

                            .customer-avatar {
                                float: left;
                                cursor: pointer;
                                position: relative;
                                margin-right: 10px;

                                img {
                                    width: 100px;
                                    height: 105px;
                                    object-fit: cover;
                                    border-radius: 50%;
                                }
                            }

                            .customer-basic-info {
                                float: left;
                                font-size: 12px;
                                width: calc(100% - 190px);

                                input:not([type=radio]) {
                                    width: 50%;
                                }

                                .customer-name {
                                    font-size: 16px;
                                    margin: 0;
                                    text-transform: uppercase;
                                    color: #2196F3;
                                }

                                .date-create,
                                .last-visited {
                                    clear: both;
                                    color: #999;
                                    font-size: 11px;
                                }

                                .ranking {
                                    border-radius: 3px;
                                    color: #fff;
                                    display: inline-block;
                                    margin: 5px 5px 5px 0;
                                    padding: 0 5px;
                                    font-size: 11px;
                                    background-color: #999;

                                    &.rank-1 {
                                        background-color: #999;
                                    }

                                    &.rank-2 {
                                        background-color: #666;
                                    }

                                    &.rank-3 {
                                        background-color: #FFB74D;
                                    }

                                    &.rank-4 {
                                        background-color: #EF6C00;
                                    }

                                    &.rank-5 {
                                        background-color: #C62828;
                                    }
                                }
                            }

                            .customer-loyaty {
                                float: right;
                                overflow: hidden;
                                text-align: center;

                                .point {
                                    background: #fb8a25;
                                    border-radius: 50%;
                                    clear: both;
                                    color: #fff;
                                    font-size: 20px;
                                    font-weight: 400;
                                    height: 60px;
                                    line-height: 60px;
                                    margin: 0;
                                    padding: 0;
                                    text-align: center;
                                    width: 60px;
                                    display: inline-block;
                                    cursor: pointer;
                                    .fa-tools  {
                                        font-size: 28px;
                                        color: aliceblue;
                                        margin-top: 17px;
                                    }
                                }

                                .title {
                                    color: #fb8a25;
                                    font-size: 12px;
                                }
                            }
                        }
                        .button-edit {
                            position: absolute;
                            left: 100px;
                            top: 92px;
                            cursor: pointer
                        }
                        .booking-rate {
                            margin-bottom: 20px;
                            font-size: 11px;

                            .item-booking {
                                display: flex;

                                .title-col {
                                    padding: 0;
                                }

                                .data-col {
                                    padding: 0;

                                    .wrap-bar {
                                        width: calc(100% - 50px);
                                        display: inline-block;

                                        .bar {
                                            height: 7px;
                                            margin-top: 4px;
                                            float: right;
                                        }
                                    }

                                    .count {
                                        font-weight: 700;
                                        float: right;
                                    }

                                    .value {
                                        color: #2196F3;
                                        font-size: 12px;
                                    }
                                }

                                &.list-stores {
                                    margin-top: 10px;

                                    .title-col {
                                        .label {
                                            font-size: 12px;
                                        }
                                    }

                                    .data-col .value span:not(:last-child):after {
                                        content: ",";
                                        margin-right: 3px;
                                        display: inline-block;
                                    }
                                }
                            }
                            .item-owed {
                                color: red;
                                cursor: pointer;
                            }
                            .item-credit {
                                margin-top: 10px;

                                .title-col {
                                    .label {
                                        font-size: 12px;
                                    }
                                }
                                .edit-credit {
                                    padding-left: 10px;
                                    text-decoration: underline;
                                    cursor: pointer;
                                    font-size: 11px;
                                }
                                .show-history {
                                    float: right;
                                    color: #5e6d8c;
                                    font-size: 11px;
                                    text-decoration: underline;
                                    cursor: pointer;
                                }
                            }   
                        }

                        .customer-more-info {
                            .tabs-title {
                                border-bottom: 1px solid #ddd;
                                margin-bottom: 20px;

                                .item-tab {
                                    display: inline-block;
                                    font-size: 12px;
                                    color: #333;
                                    padding: 9px 15px;
                                    position: relative;
                                    cursor: pointer;

                                    &.active {
                                        &::after {
                                            content: '';
                                            position: absolute;
                                            bottom: 0;
                                            left: 0;
                                            right: 0;
                                            background-color: #2196F3;
                                            height: 2px;
                                        }
                                    }
                                }
                            }

                            .tabs-data {
                                font-size: 12px;

                                .items-tab {
                                    display: none;

                                    &.active {
                                        display: block;
                                    }

                                    .item {
                                        display: inline-block;
                                        width: 100%;
                                        margin-bottom: 7px;

                                        .label {
                                            float: left;
                                            width: 25%;
                                        }

                                        .value {
                                            float: left;
                                            width: 75%;
                                            overflow-wrap: break-word;

                                            .select-birth {
                                                display: flex;
                                                margin: 0 -5px;

                                                .data-birthday {
                                                    width: 33.333%;
                                                    padding: 0 5px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            input:not([type=radio]) {
                                width: 100%;
                            }
                        }

                        .list-information {
                            padding: 0 10px;

                            .item {
                                border-bottom: 1px solid #dcdcdc;

                                .title {
                                    margin: 10px 0;
                                    font-weight: 700;
                                    font-size: 14px;
                                    cursor: pointer;
                                }

                                ul {
                                    padding-left: 0px;
                                    font-size: 12px;

                                    li {
                                        a {
                                            width: 100%;

                                            .item-booking {
                                                display: flex;
                                            }
                                        }

                                        margin-bottom: 10px;
                                        display: flex;
                                        padding: 0;
                                        cursor: pointer;

                                        .right {
                                            font-size: 12px;
                                            color: #5e6d8c;

                                            .customer-booking-time {
                                                font-weight: 700;
                                                color: #2196F3 !important;
                                            }

                                            .customer-booking-location-view {
                                                font-weight: 700;
                                                color: #5e6d8c;

                                                .text-grey-300 {
                                                    color: #999 !important;

                                                }
                                            }
                                        }

                                        .left {
                                            text-align: end;

                                            .customer-booking-total {
                                                color: #66bb6a;
                                            }

                                            .customer-booking-items {
                                                clear: both;
                                                color: #999;
                                                float: right;
                                                font-size: 12px;

                                                span {
                                                   
                                                    font-size: smaller;
                                                    line-height: 10px;
                                                    text-transform: none;
                                                    padding: 2px 5px 1px;
                                                    font-weight: 500;
                                                    color: #fff;
                                                }
                                                .bg-red {
                                                    background-color: red !important;
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                            .total-price-detail {
                                text-align: end;
                                font-size: 12px;
                                padding-right: 10px;
                            }
                        }

                        input:not([type=radio]) {
                            border: 1px solid #d3dae7;
                            border-radius: 3px;
                            padding: 7px;
                            height: 34px;
                            color: #5e6d8c;

                            &:focus {
                                background: #f6fdff !important;
                                border: 1px solid #9fbccc;
                            }

                            &.required {
                                font-size: 13px;
                                font-weight: 700;
                                border-color: #6aa1ce;
                                background: rgba(249, 255, 0, 0.05);
                            }
                        }

                        .select {
                            width: 100%;
                            &.location {
                                input:focus {
                                    background: transparent !important;
                                }
                            }
                            &.half {
                                width: 50%;
                            }
                        }
                    }

                    .bottom-col {
                        position: absolute;
                        padding: 7px 20px;
                        background-color: #fcfcfc;
                        border-top: 1px solid #ddd;
                        top: calc(100% - 47px);
                        width: 100%;

                        .action {
                            float: right;

                            >div {
                                display: inline-block;
                                margin-left: 10px;
                            }
                        }
                    }

                    .order-popup {
                        .head-popup {
                            .order-status {
                                clear: both;
                                color: #999;
                                font-size: 12px;
                                position: absolute;
                                right: 10px;
                                top: 15px;
                                padding-left: 20px;

                                span {
                                    // background-color: #8BC34A !important;
                                    // border-color: #8BC34A;
                                    font-size: smaller;
                                    line-height: 10px;
                                    text-transform: none;
                                    padding: 2px 5px 1px;
                                    font-weight: 500;
                                    color: #fff;
                                }
                            }
                        }

                        .body-popup {
                            .customer {
                                .customer-avatar {
                                    position: relative;

                                    img {
                                        height: 60px;
                                        margin-right: 10px;
                                        width: 60px;
                                        border: solid 1px #ddd;
                                        border-radius: 50%;
                                        object-fit: cover;
                                    }

                                    .info-customer {
                                        position: absolute;
                                        top: 10px;
                                        left: 70px;

                                        p {
                                            font-size: 12px;
                                            color: #777;
                                            margin-top: 0px;
                                            font-weight: 700;

                                        }

                                        p:first-child {
                                            color: #4a90e2;
                                            font-size: 16px;
                                            text-transform: uppercase;
                                        }
                                    }
                                }
                            }

                            .order-info {
                                margin-top: 15px;
                                padding-bottom: 10px;

                                span {
                                    color: #2196F3 !important;
                                }

                                p {
                                    color: #5e6d8c;
                                    font-size: 12px;
                                }

                                .user-order-customer {
                                    margin-top: 10px;
                                    padding-bottom: 10px;
                                    border-bottom: #5e6d8c 1px;

                                    .user-title {
                                        .name-user {
                                            color: #5e6d8c;
                                            font-size: 12px;
                                        }
                                    }

                                    .user-order-date {
                                        background-color: #999;
                                        border-color: #999;
                                        color: #fff;
                                        font-size: 11px;
                                        line-height: 12px;
                                        text-transform: none;
                                        padding: 1px 5px;
                                    }
                                }

                            }

                            .table-list-order-item {
                                font-size: 12px;
                                max-height: 200px;
                                overflow: auto;
                                padding-top: 10px;
                                border-top: solid 1px #d3dae7;

                                table {
                                    width: 100%;

                                    .order-item-tr {
                                        border-bottom: solid 1px #d3dae7;
                                    }

                                    tr {
                                        margin-bottom: 15px !important;

                                        th {
                                            color: #2196F3 !important;
                                            font-weight: 700;
                                        }

                                        th:last-child {
                                            text-align: end;
                                        }

                                        td:first-child {
                                            padding-top: 10px;
                                        }

                                        td:last-child {
                                            text-align: end;
                                        }
                                    }
                                }

                                .order-item-detail {
                                    padding-bottom: 15px;

                                    .item-avatar {
                                        display: flex;

                                        .info-service {
                                            margin-left: 10px;
                                            p:first-child {
                                                color: #5e6d8c;
                                                font-weight: 700;
                                            }
                                        }

                                        img {
                                            width: 50px;
                                            height: 50px;
                                        }
                                    }

                                    p {
                                        font-size: 12px;
                                        margin-top: 0px;
                                    }
                                }
                            }

                            .order-remind-item {
                                border-top: unset !important;

                                .none-remind {
                                    position: absolute;
                                    top: 56px;
                                    height: 150px;
                                    text-align: center;
                                    width: 100%;
                                }
                            }

                            .item-pagination {
                                text-align: end;
                                border-top: 1px solid #e0e0e0;
                                padding: 0 24px;
                                border-bottom: 1px solid #e0e0e0;

                                .ag-paging-panel {
                                    padding: 10px 0;
                                }
                            }

                            .order-into-money {
                                font-weight: 700;
                                font-size: 12px;
                                margin-top: 15px;
                                padding-bottom: 10px;
                                border-bottom: #d3dae7 solid 1px;

                                p {
                                    padding-bottom: 0;
                                }

                                .list-order-price {
                                    text-align: end;
                                    font-weight: 700;
                                }
                            }

                            .order-total-money {
                                margin-top: 15px;
                                padding-bottom: 10px;
                                border-bottom: #d3dae7 solid 1px;
                                font-size: 12px;

                                .order-price-type {
                                    font-weight: 700;
                                    color: #5E6D94;

                                    p:first-child {
                                        width: 70%;
                                        font-size: 20px;
                                    }
                                }

                                .list-order-price {
                                    text-align: end;

                                    p:first-child {
                                        font-size: 20px;
                                    }

                                    font-weight: 700;
                                }

                                .node-order {
                                    .node-title {
                                        color: #2196F3 !important;
                                        font-weight: 700;
                                    }

                                    .node-text {
                                        background-color: #eeeeee;
                                        padding: 5px 5px 5px 5px;
                                        color: #777 !important;
                                        display: inline-block;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
    }

    .radio-button {
        margin: 0 50px 0 0;

        input[type=radio] {
            margin-right: 10px;
            position: relative;
            top: 2px;
        }
    }

    .popup-cent.payment.qr-code .body-popup {
        height: auto !important;
    }
    .popup-cent .content-popup {
        width: 700px;
        @media (max-width: 1199px) {
            width: 95% !important;
        }
        .row {
            margin-left: -5px;
            margin-right: -5px;
            align-items: center;

            >div {
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        .filter-date-order {
            padding: 20px;
            label {
                width: 40%;
            }
            .form-input {
                padding-top: 10px;
            }
            .select-birth {
                display: inline-block;
                width: 60%;
                margin: 0 -5px;
                .data-birthday {
                    width: 33.333%;
                    display: inline-block;
                    padding: 0 5px;
                    input {
                        width: 100%;
                        border: 1px solid hsl(0, 0%, 80%);
                        height: 32px;
                        line-height: 32px;
                        border-radius: 4px;
                        padding: 0 5px;
                    }
                }
            }
            .select-ranking {
                display: inline-block;
                width: 60%;
            }
        }
    }
    .w-700 {
        font-weight: 700;
    }

    .bg-green {
        background-color: #0288D1;
    }

    .bg-red {
        background-color: #D32F2F;
    }

    .bg-blue {
        background-color: #0288D1;
    }

    .cl-red {
        color: #D32F2F !important;
    }

    .cl-blue {
        color: #0288D1;
    }

    .cl-green {
        color: #259B9A;
    }

    .cl-green2 {
        color: #8BC34A;
    }

    .cl-bold {
        font-weight: 700;
    }

    .cl-none-bold {
        font-weight: 300;
    }

    .cl-violet {
        color: #673AB7;
    }

    .cl-grey {
        color: #777 !important;
        text-decoration: line-through;

    }

    .posion-wraper {
        position: relative;

        .left-view {
            position: absolute;
            right: 10px;
            top: 0px;
        }
    }

    .order-status {
        clear: both;
        color: #999;
        font-size: 12px;
        position: absolute;
        right: 10px;
        top: 15px;
        padding-left: 20px;

        span {
            // background-color: #8BC34A !important;
            border-color: #8BC34A;
            font-size: smaller;
            line-height: 10px;
            text-transform: none;
            padding: 2px 5px 1px;
            font-weight: 500;
            color: #fff;
        }
    }

    .order-remind {
        color: #4a90e2;
        text-decoration: underline;
        padding-left: 10px;
    }

    .popup-cent .content-popup.package-popup {
        width: 1024px;
        align-items: unset;
        @media (max-width: 1199px) {
            width: 95%;
        }
    }
    .package-history {
        .body-popup {
            height: 500px;
        }
        .delete-transaction {
            height: 50px !important;
        }
        .panel.panel-flat {
            color: #5e6d8c;
            border: 1px solid #d3dae7 !important;
            margin-bottom: 20px;
            border-radius: 3px;
            -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
            box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    
            .panel-body {
                padding: 0 10px 10px;
                position: relative;
                .show-head {
                    align-items: unset;
                }
    
                .customer-info {
                    .customer-name {
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                }
    
                .package-info-name {
                    .package-order {
                        text-transform: uppercase;
                        font-weight: bold;
                        color: #9C27B0;
                    }
    
                    .tag {
                        border-radius: 3px;
                        color: #fff;
                        display: inline-block;
                        margin: 5px 5px 5px 0;
                        padding: 0 5px;
                    }
                }
            }
        }
    
        .fixed-content-wrapper.customer-list-container {
            top: 235px;
            background-color: #fff;
    
            .report-summary-block {
                width: 200px
            }
        }
    
        .font-20 {
            font-size: 20px !important;
        }
    
        .bg-green {
            background-color: #8BC34A;
            border-color: #8BC34A;
        }
    }
    .delete-transaction {
        .content-popup {
            .body-popup {
                height: 100px;
            }
        }
    }

    .edit-credit {
        color: #5e6d8c;
        .body-popup {
            .panel-body {
                .item-popup {
                    &:not(:last-child) {
                        margin-bottom: 15px;
                    }
                    .input-credit, textarea {
                        border: 1px solid #d3dae7;
                        border-radius: 3px;
                        padding: 7px;
                        width: 100%;
                    }
                    .input-credit {
                        height: 38px;
                        line-height: 38px;

                    }
                    textarea {
                        height: 120px;
                    }
                    .result-search {
                        position: absolute;
                        z-index: 6;
                        background: #fff;
                        width: calc(100% + 100px);
                        border: 1px solid #d3dae7;
                        border-radius: 3px;
                        .empty-customer {
                            line-height: 1;
                            padding: 10px;
                            font-size: 16px;
                        }
                        .items-customer {
                            height: 350px;
                            overflow: hidden;
                            overflow-y: scroll;
                            .item {
                                display: flex;
                                cursor: pointer;
                                padding: 10px;
                                .avatar {
                                    float: left;
                                    img {
                                        border-radius: 50%;
                                        height: 36px;
                                        width: 36px;
                                        object-fit: cover;
                                    }
                                }
                                .info {
                                    float: left;
                                    padding-left: 10px;
                                    width: calc(100% - 36px);
                                    > div {
                                        line-height: 1;
                                    }
                                    .name {
                                        text-transform: uppercase;
                                        border-radius: 3px;
                                        background: red;
                                        color: #fff;
                                        display: inline-block;
                                        padding: 3px;
                                        position: relative;
                                        top: -5px;
                                    }
                                    .phone {
                                        position: relative;
                                        color: #999;
                                        font-size: 10px;       
                                        margin-bottom: 5px;       
                                    }
                                    .ranking {
                                        position: relative;
                                        top: -5px;
                                        margin-left: 5px;
                                        font-size: 10px;
                                    }
                                    .address {
                                        color: #999;
                                        font-size: 10px;    
                                    }
                                }
                                &:not(:last-child) {
                                    border-bottom: 1px solid #d3dae7;
            
                                }
                                &:hover {
                                    background: #f6fdff;
                                }
                            }
                        }
                    }
                    .receiver_customer {
                        .avatar {
                            img {
                                height: 70px;
                                width: 70px;
                                border-radius: 50%;
                                border: 1px solid #d6e0ef;
                                object-fit: cover;
                            }
                        }
                        .info-customer {
                            line-height: 1;
                            .top-info {
                                margin-bottom: 10px;
                            }
                        }
                        .gender {
                            color: rgb(33, 150, 243);
                            font-size: 12px;
                            margin-right: 2px;
                        }
                        .name {
                            font-size: 14px;
                            font-weight: 700;
                            margin: 0;
                            color: #2196F3;
                            text-transform: uppercase;
                        }
                        .button-edit {
                            border-radius: 2px;
                            background: #2196F3;
                            color: #FFF;
                            margin-left: 5px;
                            padding: 3px;
                            cursor: pointer;
                        }
                        .button-create-new {
                            margin-left: 10px;
                            color: #2196F3;
                            text-decoration: underline;
                            cursor: pointer;
                            i {
                                margin-right: 5px;
                            }
                        }
                        .ranking {
                            margin-top: 15px;
                        }
                        .phone, .address, .ranking {
                            line-height: 1;
                            color: #5e6d8c;
                            font-size: 12px;
                            margin: 8px 0;
                            i {
                                margin-right: 10px;
                                font-size: 13px;
                            }
                        }
                        i {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
    .history-credit {
        color: #5e6d8c;
        .content-popup {
            width: 1200px;
        }
        .table {
            tbody tr {
                &:last-child {
                    border-bottom: 1px solid #dee2e6;
                }
                td {
                    font-size: 12px;
                    padding-left: 7px;
                    padding-right: 7px;
    
                }
            } 
            thead th {
                padding-left: 7px;
                padding-right: 7px;
                font-size: 12px;
                border-bottom: 0;
            }
        } 
        .price_credit {
            &.plus {
                color: #008000;
            }
            &.minus {
                color: red;
            }
        }
    }
}
.overlay {
    .icon-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 34px;
        color: #fff;
        z-index: 100;
    }
}
.disable-button{
    pointer-events: none;
    opacity: 0.7
}
.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}
.package-expired{
    background-color: red;
}
.package-using{
    background-color: #0288D1;
}
.package-block{
    background-color: #9C27B0;
}
.package-complete{
    background-color: #008000;
}
.upload-local {
    position: relative;
}
.camera {
    position: relative;
    #canvas {
        background-color: transparent;
        position: absolute;
        z-index: 2;
        margin: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin-left: auto;
        margin-right: auto;
    }
    #webcam {
        height: 100%;
        max-width: 100%;
    }
}
.cameraControls {
    position: absolute;
    bottom: 10%;
    left: 0;
    width: 100%;
    z-index: 3;
    > div {
        color: white;
        width: 80px;
        height: 80px;
        line-height: 90px;
        background-color: black;
        border-radius: 50%;
        text-align: center;
        margin: 0 10px;
        display: inline-block;
        cursor: pointer;
        &.disable {
            opacity: 0.5
        }
        i {
            font-size: 30px;
        }
    }
}

@media (max-width: 1199px) {
    .admin-page.customer {
        .package-history {
            .panel.panel-flat {
                overflow-y: auto;
            }
        }
        
        .history-credit, .package-history, .package-popup {
            th, td {
                min-width: 150px;
                max-width: 250px;
            }
            .content-popup {
                max-height: 95%;
                overflow-y: auto;
            }
            .body-popup .panel-body {
                overflow-y: auto;
            }
            .bottom-popup {
                position: sticky;
                bottom: 0;
                left: 0;
                background: #fff;
            }
        }
        .top-body {
            display: none;
        }
        .admin-body .dashboard-content .wrapper {
            top: 110px;
            .fixed-column {
                &.list-customers {
                    right: 10px;
                    z-index: 2;
                    bottom: auto;
                    .header-col {
                        z-index: 3;
                        position: relative;
                    }
                    .body-col {
                        background: #fff;
                        height: auto;
                        overflow: unset;
                    }
                    .customer-block-list {
                        position: absolute;
                        width: 100%;
                        z-index: 3;
                        background: #fff;
                        box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
                        max-height: 65vh;
                        overflow-y: auto;
                    }
                    .overlay-search-customer {
                        opacity: 0.5;
                        z-index: 2;
                        position: fixed;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        width: 100%;
                        height: 100vh;
                        background: #000;
                    }
                }
                &.detail-customer, &.more-info-customer {
                    top: 50px;
                }
    
                &.detail-customer{
                    left: 10px;
                    right: calc(40% + 5px)
                }
    
                 &.more-info-customer {
                    left: calc(60% + 5px)
                }
            }
        }
    }

}

@media (max-width: 767px) {
    .admin-page.customer {
        .popup-cent .content-popup .filter-date-order {
            label, .select-birth {
                width: 100%;
            }
        }
        .admin-body .dashboard-content .wrapper {
            position: inherit;
            .fixed-column {
                position: inherit;
                width: 100%;
                margin-bottom: 5px;
                .body-col {
                    height: auto;
                    .button-edit {
                        left: 70px;
                        top: 70px;
                    }
                }
                .bottom-col {
                    position: inherit;
                    display: inline-block;
                }
            
                &.detail-customer{
                    .body-col {
                        height: auto;
                        .customer-avatar {
                            img {
                                width: 70px;
                                height: 70px;
                            }
                        } 
                    }
                }
            }
        }
    } 

}