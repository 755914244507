.textHeader {
    font-family: inherit;
    font-weight: 400;
    line-height: 1.5384616;
    color: inherit;
    font-size: 17px;
}
.popupHeader {
    padding: 10px;
    border-bottom: 1px solid #d3dae7;
}
.childOrder{
    border-bottom: 1px solid #d3dae7
}
.textDate{
    color: #5e6d8c;
    font-size: 14px;
}
.formLeft{
    border-right: 1px solid #d3dae7;
    padding-right: 3rem;
    width: 13rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.textNone{
    color: red;
}
.textDelete{
    color: red;
    cursor: pointer;
}
.scrollTransaction{
    height: 300px;
    overflow: scroll;
  }

.textCodeOrder{
    color: blue;
    cursor: pointer;
}
.textCreateNote{
    color: #4a90e2;
    text-decoration: underline;
    cursor: pointer;
}