.header {
    .header-top {
        color: #fff;
        padding: 0 20px;
        background-color: #37474F;
        display: flex;
        justify-content: space-between;
        height: 48px;
        align-items: center;
        .menu-list {
            display: flex;
        }

        .icon-menu {
            padding: 0 7px;
            font-size: 20px;
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
        }

        .overlay-menu {
            background: rgba(0, 0, 0, .7);
            cursor: pointer;
            height: 100%;
            left: 0;
            opacity: 0;
            pointer-events: none;
            position: fixed;
            top: 0;
            transition: opacity .3s ease-in-out, visibility .3s;
            visibility: hidden;
            width: 100vw;
            z-index: 468;
            &.active {
                opacity: 1;
                pointer-events: auto;
                transition: opacity .25s, visibility;
                visibility: visible;
            }
        }

        .close-button-menu {
            background-color: #333;
            color: #fff;
            height: 50px;
            left: calc(100vw - 63px);
            opacity: 0;
            padding: 0;
            pointer-events: none;
            position: fixed;
            top: 0;
            transform: translate3d(-104%, 0, 0);
            transition: opacity .3s cubic-bezier(.645,.045,.355,1), transform .5s cubic-bezier(.645,.045,.355,1);
            width: 50px;
            z-index: 10000;
            svg {
                height: 20px;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 20px;
            }
            &.active {
                opacity: 1;
                pointer-events: auto;
                transform: none;
            }
        }

        .menu-item {
            padding: 0 7px;
            font-size: 12px;
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            .title-menu {
                margin-bottom: 0;
                cursor: pointer;
            }
            .pre-booking {
                margin-right: 20px;
                font-size: 16px;
            }
            .cskh {
                font-weight: 600;
                margin-right: 10px;
                color: #fb8a25;
            }
            .customer-bell {
                position: relative;
                .count-customer {
                    position: absolute;
                    bottom: 5px;
                    font-size: 15px;
                    left: 14px;
                    font-weight: bold;
                    color: #fb8a25;
                    z-index: 10;
                }
            }
            .sale {
                color: #558B2F;
                font-weight: 600;
            }
            @media (min-width: 992px) {
                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }    
            }
           
            a {
                color: #fff;
            }

            i {
                margin-right: 5px;
            }
            &.choose-location {
                .dropdown-menu {
                    min-width: 350px;
                    right: 0;
                    left: auto;
                    top: calc(100% + 10px);
                    .dropdown-content-heading {
                        line-height: 1;
                        padding: 5px 10px 10px;
                        font-size: 12px;
                        text-transform: uppercase;
                        color: #333;
                        border-bottom: solid 1px #d6e0ef;
                        margin-bottom: 10px;
                    }
                    .input-search {
                        border-bottom: solid 1px #d6e0ef;
                        margin-bottom: 10px;
                        padding: 0 10px 10px;
                        input {
                            border-radius: 50px;
                            height: 32px;
                            line-height: 30px;
                            font-size: 12px;
                        }
                    }
                    .media-list {
                        overflow-y: auto;
                        padding: 0 10px 10px;
                        .media {
                            max-width: 400px;
                            word-break: break-word;
                            overflow: hidden;
                            margin-top: 0;
                            position: relative;
                            &:not(:last-child) {
                                margin-bottom: 15px;
                            }
                            a {
                                width: 350px;
                            }
                            .media-left {
                                padding-right: 20px;
                                display: table-cell;
                                vertical-align: top;
                                .color-indicator {
                                    height: 100%;
                                    position: absolute;
                                    width: 3px;
                                    background-color: #03a9f4;
                                }
                            }
                            .media-body {
                                display: table-cell;
                                vertical-align: top;
                                zoom: 1;
                                overflow: visible;
                                position: relative;
                                width: 10000px;
                                font-size: 13px;
                                .col-8, .col-4 {
                                    padding-left: 5px;
                                    padding-right: 5px;
                                }
                                .customer-avatar {
                                    img {
                                        width: 105px;
                                        height: 105px;
                                        object-fit: cover;
                                        border-radius: 50%;
                                    }
                                }

                                .image-hanet {
                                    img {
                                        width: 50px;
                                        height: 50px;
                                        object-fit: cover;
                                        border-radius: 50%;
                                    }
                                }

                                .media-heading {
                                    color: #4a90e2;
                                    width: 100%;
                                    display: inline-block;
                                    margin-bottom: 5px;
                                }
                                .text-muted {
                                    color: #999;
                                }
                            }
                        }
                    }
                }
            }

            @media (min-width: 992px) {
                .child-menu {
                    display: none;
                    max-width: 1024px;
                    width: max-content;
                    left: -164px;
                    max-height: 500px;
                    overflow-x: hidden;
                    overflow-y: auto;
                    border-radius: 8px;
                    top: 40px;
                    padding: 10px;
                    z-index: 999;
                    position: absolute;
                    background: #fff;
                    border: 1px solid #eee;
                    box-shadow: 0 4px 10px 0 #0000001a;
                    min-width: 700px;
                    .sub-menu-list {
                        margin-top: 15px;
                        .item-sub-menu {
                            padding-bottom: 15px;
                            a {
                                color: #5e6d8c;
                                cursor: pointer;
                                font-size: 13px;
                                line-height: 1.2;
                                &:hover {
                                    color: #fc8821;
                                }
                            }
                        } 
                    }
                    
                    .column {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }
        }

        .header-left {
            display: flex;

            .logo {
                img {
                    height: 16px;
                    object-fit: contain;
                }
            }
        }

        .header-right {
            display: flex;
            align-items: center;
            .menu-item {
                i {
                    font-size: 18px;
                }
                &.choose-location {
                    .parent-title {
                        display: flex;
                        align-items: center;
                        img {
                            width: 30px;
                            height: 30px;
                            margin-right: 5px;
                        }
                    }
                }
                &.my-account {
                    .parent-title {
                        display: flex;
                        align-items: center;
                        .avatar-user {
                            height: 36px;
                            width: 36px;
                            border: 1px solid #d6e0ef;
                            border-radius: 50%;
                        }
                        i {
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
}

.hide-desktop {
    display: none !important;
}

.dropdown-menu-report {
    left: -20rem;
    position: absolute 
}

@media (min-width: 991px) and (max-width: 1199px) {
    .header {
        .header-top {
            .menu-item {
                .child-menu {
                    max-width: 768px;
                    .column {
                        flex: 0 0 calc(100%/3) !important;
                        max-width: calc(100%/3) !important;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .header .header-top  {
        .header-left {
            .menu-list {
                background: #fff;
                box-shadow: none;
                display: flex;
                flex-direction: column;
                height: 100%;
                left: 0;
                max-height: none;
                opacity: 1;
                overflow-x: hidden;
                overflow-y: auto;
                pointer-events: none;
                position: fixed;
                right: auto;
                top: 0;
                touch-action: manipulation;
                transform: translate3d(-104%, 0, 0);
                transition: transform .5s cubic-bezier(.645,.045,.355,1), visibility .5s cubic-bezier(.645,.045,.355,1);
                visibility: hidden;
                width: calc(100vw - 65px);
                z-index: 1001;
                &.active {
                    opacity: 1;
                    pointer-events: auto;
                    transform: none;
                    -webkit-transform: none;
                    visibility: visible;
                }
                .sidebar-head {
                    align-items: center;
                    background-color: hsla(0, 0%, 40%, .1);
                    border-bottom: 1px solid hsla(0, 0%, 51%, .2);
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    min-height: 56px;
                    padding: 0;
                    text-align: center;
                    .tab_title {
                        background-color: hsla(0, 0%, 40%, .15);
                        color: #0f0f0f;
                        cursor: pointer;
                        flex: 0 0 auto;
                        font-size: 14px;
                        font-weight: 500;
                        justify-content: center;
                        letter-spacing: .3px;
                        line-height: 48px;
                        padding: 5px 10px;
                        position: relative;
                        text-align: center;
                        text-transform: uppercase;
                        width: 50%;
                        &:after {
                            background-color: #f6a733;
                            content: "";
                            height: 2px;
                            left: 0;
                            left: auto;
                            margin-top: -1px;
                            position: absolute;
                            right: 0;
                            top: 100%;
                            transition: width .25s;
                            width: 0;
                            width: 100%;
                        }
                    }
                }
                .menu-item {
                    border-bottom: 1px solid hsla(0deg, 0%, 40%, 0.2);
                    color: #333;
                    display: block;
                    font-size: 14px;
                    letter-spacing: 0.2px;
                    line-height: 1.3;
                    text-align: left;
                    text-transform: uppercase;
                    font-weight: 500;
                    padding: 0;
                    .title-menu, > a {
                        padding: 15px 10px;
                        display: block;
                    }
                    .child-menu {
                        display: none;
                        padding: 0 20px;
                        .column {
                            flex: 0 0 100% !important;
                            max-width: 100% !important;
                            padding: 0 15px;
                            margin-bottom: 20px;
                            text-transform: none;
                            .border-bottom {
                                margin-bottom: 10px;
                                .dropText {
                                    color: #333;
                                }
                            }
                            .sub-menu-list {
                                .item-sub-menu {
                                    a {
                                        color: #5e6d8c;
                                        padding: 10px 0;
                                        border-bottom: 1px solid hsla(0deg, 0%, 40%, 0.2);
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                    &.parent-item {
                        position: relative;
                        &:before {
                            background-color: #333;
                            content: "";
                            height: 1px;
                            right: 4px;
                            opacity: 1;
                            position: absolute;
                            top: 24px;
                            transform: translate(-50%, -50%) rotate(-90deg);
                            transition: transform .35s ease-in-out, opacity .35s ease-in-out;
                            width: 12px;
                        }
                        &:after {
                            background-color: #333;
                            content: "";
                            height: 12px;
                            right: 15px;
                            position: absolute;
                            top: 24px;
                            transform: translate(-50%, -50%) rotate(-90deg);
                            transition: transform .35s ease-in-out, opacity .35s ease-in-out;
                            width: 1px;
                        }
                        &.active {
                            &:before {
                                opacity: 0;
                                transform: translate(-50%, -50%) rotate(90deg);
                            }
                        }
                    }
                    a, .title-menu {
                        font-size: 14px;
                        color: #333;
                    }
                    button {
                        font-size: 14px;
                        background: #fff;
                    }
                }
            }  
        }
    }
}

@media (min-width: 641px) and (max-width: 991px) {
    .header {
        .header-top {
            .header-left .menu-list {
                width: 340px;
            }
            .close-button-menu {
                left: 340px;
            }
        }
    }
}

@media (max-width: 767px) {
    .table-list-order-item .order-item-detail .item-avatar {
        .info-service {
            margin-left: 0 !important;
        }
        img {
            display: none;
        }
    } 
    .hide-desktop {
        display: block !important;
    }
    .hide-mobile {
        display: none !important;
    }

    .item-mobile-table {
        border-radius: 5px;
        background: #efefef;
        padding: 10px;
        margin-bottom: 10px;
        display: inline-block;
        .mobile-table {
            width: 100%;
            max-width: 100% !important;
            display: inline-block;
            float: none;
            padding: 0;
            line-height: 32px;
            border-top: 0 !important;
            &:not(:last-child) {
                border-bottom: solid 1px #ddd;
            }
            .title {
                float: left;
                width: 120px;
                text-align: left;
            }
            .value {
                float: left;
                text-align: left;
                width: calc(100% - 120px);
            }
        }
    }
    .dropdown-menu1 {
        width: 320px !important;
    }
    .dropdown-menu-report {
        left: -10rem;
    }
}

.button-drop{
    font-size: 12px;
    background-color: #37474F;
    border: none;
    margin-top: 0;
    padding: 0;
}
.button-drop:hover{
    background-color: rgba(0, 0, 0, 0.1);
}
.text-li{
    color: #5e6d8c;
    margin-left: 0;
    padding-left: 0;
    text-decoration: none;
    cursor: pointer;
    padding-left: 0;
    list-style: none;
    font-size: 13px;
    margin-top: 10px;
}
.text-li:hover{
    background-color: rgba(0,0,0,.15);
}
.dropText{
    color: #5e6d8c;
    margin-left: 0;
    padding-left: 0;
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
    padding-top: 4px;
}
.dropdown-menu1{
    width: 65rem;
    right: auto;
}


.snake-gift {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    animation-iteration-count: infinite;
    animation-duration: 2s;
    backface-visibility: hidden;
}

.pdt-10 {
    padding-top: 10px;
}

@-webkit-keyframes shake {
    100%,from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    10%,30%,50%,70%,90% {
        -webkit-transform: translate3d(-2px, 0, 0);
        transform: translate3d(-2px, 0, 0);
    }

    20%,40%,60%,80% {
        -webkit-transform: translate3d(2px, 0, 0);
        transform: translate3d(2px, 0, 0);
    }
}

@keyframes shake {
    100%,from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    10%,30%,50%,70%,90% {
        -webkit-transform: translate3d(-2px, 0, 0);
        transform: translate3d(-2px, 0, 0);
    }

    20%,40%,60%,80% {
        -webkit-transform: translate3d(2px, 0, 0);
        transform: translate3d(2px, 0, 0);
    }
}

.animate-flicker {
    -webkit-animation: flickerAnimation 1s infinite;
    -moz-animation: flickerAnimation 1s infinite;
    -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
}

@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
}

.page-title {
    padding: 7px 10px;
    background: #fff;
    color: #5e6d8c;
    display: inline-block;
    width: 100%;
    .title-page {
        font-weight: 700;
        float: left;
        font-size: 16px!important;
        font-weight: 700;
        line-height: 34px;
        .count-store {
            font-size: 12px;
        }
    }
    .button-cent {
        float: right;
    }
}

.button-cent {
    background: #4a90e2;
    border-radius: 3px;
    padding: 0 10px 0 42px;
    color: #fff;
    font-size: 12px;
    position: relative;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    span {
        position: absolute;
        top: 0px;
        text-align: center;
        left: 0px;
        background: rgba(0,0,0,.15);
        display: block;
        height: 32px;
        line-height: 32px;
        width: 32px;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
    }
    a {
        color: #fff; 
    }
    &.one-col {
        padding: 0 10px;
        span {
            background: transparent;
            position: relative;
            display: inline-block;
            width: auto;
            height: auto;
            padding-right: 5px;
        }
    }
    &.style2 {
        background: #FFA726;
    }
    &.style3 {
        background: #f2480c;
    }
    &.style4 {
        background: #efefef;
        color: #5e6d8c;
    }
    &.style5 {
        background-color: #00BCD4;
    }
    &.style6 {
        background-color: #a100d4;
    }
    &.style7 {
        background-color: #e24abd;
    }
    &.disable {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.popup-cent {
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1050;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    .content-popup {
        width: 1024px;
        border-radius: 3px;
        position: relative;
        background: #fff;
        z-index: 1041;
        margin: 30px auto;
        .head-popup {
            padding: 10px;
            color: #5e6d8c;
            font-size: 17px;
            border-bottom: solid 1px #d3dae7;
        }
        .body-popup {
            padding: 10px;
            .items-data {
                margin-bottom: 10px;
                color: #5e6d8c;
                font-size: 12px;
                min-width: 38px;
                line-height: 38px;
                .value {
                    input:not([type="radio"]), textarea {
                        width: 100%;
                        border: 1px solid #d3dae7;
                        border-radius: 3px;
                        padding: 7px;
                        &:focus {
                            background: #f6fdff;
                            border: 1px solid #9fbccc;
                        }
                    }
                    input:not([type="radio"]) {
                        height: 32px;
                        &.required {
                            border-color: #6aa1ce;
                            background: rgba(249,255,0,.05);
                        }
                    }
                    input.right-text-input {
                        text-align: right;
                    }
                    input.none-border-right {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                    textarea {
                        line-height: 1;
                    }
                    .measure {
                        text-align: center;
                        height: 32px;
                        line-height: 30px;
                        width: 40px;
                        border: 1px solid rgb(221, 221, 221);
                        border-left: none;
                        position: absolute;
                        top: 3px;
                        right: -35px;
                        border-top-right-radius: 3px;
                        border-bottom-right-radius: 3px;
                    }
                }
            }
        }
        .bottom-popup {
            display: inline-block;
            width: 100%;
            .button-container {
                float: right;
                padding: 10px;
                .button-cent {
                    margin-left: 5px;
                    display: inline-block;
                }
            }
        }
        .close {
            position: absolute;
            right: 10px;
            top: 8px;
            padding-left: 20px;
        }
    }
    .overlay {
        opacity: .5; 
        z-index: 1040;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: #000;
    }
    &.hour_open {
        .body-popup {
            padding: 30px 10px;
            .items-data {
                align-items: center;
                .title {
                    color: #8BC34A;
                    font-weight: 700;
                }
                .value {
                    .label {
                        position: relative;
                        padding-left: 20px;
                        color: #8BC34A;
                        margin: 0;
                        margin-top: 7px;
                        input {
                            position: absolute;
                            top:0;
                            left: 0;
                            width: 15px;
                            height: 15px;
                        }
                    }
                    .time-open {
                        position: relative;
                        input {
                            width: 120px;
                            height: 32px;
                            text-align: right;
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                        span {
                            padding: 0 5px;
                            font-size: 13px;
                            font-weight: 400;
                            color: #333;
                            background-color: #fcfcfc;
                            border: 1px solid #ddd;
                            border-left: none;
                            position: absolute;
                            height: 32px;
                            line-height: 30px;
                            border-top-right-radius: 3px;
                            border-bottom-right-radius: 3px;
                        }
                        &.red2 {
                            input {
                                border-color: #D84315;
                            }
                            span {
                                color: #D84315;
                                border-color: #D84315;
                                background-color: #FBE9E7;
                            }
                        }
                    }
                }
                &.red {
                    .title {
                        color: #F44336;
                    }
                    .value {
                        .label {
                            color: #F44336;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 1199px) {
        .content-popup {
            width: 95% !important;
        }
    }
}
.btn-xs {
    font-size: 12px;
    // line-height: 1.6666667;
    border-radius: 2px;
}

.bg-blue {
    background-color: #03A9F4;
    border-color: #03A9F4;
    color: #fff;
}

.bg-green {
    background-color: #8BC34A;
    border-color: #8BC34A;
    color: #fff;
}


.ml-10 {
    margin-left: 10px!important;
}

.btn-labeled.btn-xs {
    padding-left: 42px;
}

.pull-right {
    float: right!important;
}

.pt-10 {
    padding-top: 10px!important;
}
.no-margin {
    margin: 0!important;
}
.block-ui {
    position: relative;
}
.text-grey, .text-grey:focus, .text-grey:hover {
    color: #777;
}
.text-danger, .text-danger:focus, .text-danger:hover {
    color: #F44336!important;
}
.text-pink, .text-pink:focus, .text-pink:hover {
    color: #E91E63!important;
}
.text-info, .text-info:focus, .text-info:hover {
    color: #00BCD4!important;
}
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak, .ng-hide:not(.ng-hide-animate) {
    display: none !important;
}
hr {
    border: unset;
    border-top: 1px solid #ddd;
    overflow: auto;
}
.mr-10 {
    margin-right: 10px!important;
}
.mb-10 {
    margin-bottom: 10px!important;
}
.mt-10 {
    margin-top: 10px!important;
}
.pull-left {
    float: left!important;
}
.clear {
    clear: both;
    display: block;
    width: 100%;
}
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak, .ng-hide:not(.ng-hide-animate) {
    display: none !important;
}
.mr-5 {
    margin-right: 5px!important;
}
.pull-right {
    float: right!important;
}
.loader-spin {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: 1.1em solid rgba(0, 0, 0, 0.2);
    border-left: 1.1em solid #000000;
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

input:focus,
button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.notification .title {
    color: #fff;
}

@media (max-width: 1199px) {
    .header .header-top {
        .header-left .menu-item {
            i.fas, i.fa {
                display: none;
            }
            >a, button {
                font-size: 12px;
                
            }
            .sale, .cskh {
                font-size: 14px;
            }
        }
    }  
}
@media (min-width: 768px) and (max-width: 1199px) {
    .header .header-top {
        .header-left .menu-item {
            .show.dropdown {
                .dropdown-menu.show {
                    left: -17rem !important;
                    width: 700px;
                    overflow-x: scroll;
                }
            }
        }
    }
    .table-voucher { 
        overflow-x: scroll;
        .ant-table-cell {
            &:not(.ant-table-selection-column) {
                min-width: 120px
            }
            &:nth-child(3), &:nth-child(4) {
                min-width: 200px
            }
            &:last-child {
                min-width: 70px 
            }
        }
    } 
}
@media (max-width: 768px) {
    .header {
        position: fixed;
        z-index: 999;
        width: 100%;
        top: 0;
        .header-top {
            padding: 0;
            .button-drop {
                padding: 0 10px 0 7px;
            }
            .menu-item.choose-location {
                padding-right: 0;
                .dropdown-menu {
                    width: 300px;
                    right: -50px;
                }
            }
        }
    }
}