.admin-page.orders {
    .require-operator {
        .count.service {
            top: -8px;
        }

        .count {
            position: absolute;
            background: #4a90e2;
            color: #fff;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            border-radius: 50%;
            left: 10px;
            bottom: -8px;
        }
                
    }
    .list-store-select {
        width: max-content;
        font-size: 13px;
       label {
            padding-left: 20px;
            padding-right: 15px;
            display: block;
            cursor: pointer;
            input {
                margin-right: 5px;
                position: relative;
                top: 2px;
            }
            &:first-child {
                padding-top: 10px;
           }
       }
    }
    #dropdown-basic-button {
        font-size: 13px;
    }

    .header-right {
        .rs-picker-daterange {
            padding-right: 15px;
        }

        .button-cent {
            a {
                color: #fff !important;
            }
        }
    }

    .fixed-content-wrapper {
        top: 100px;
        left: 0;
        right: 0;
        bottom: 42px;
        background: #e6eef3;
        position: absolute;

        .order-advanced-search {
            font-size: 12px;
            padding: 5px 10px;

            .order-filter-block {
                display: inline-block;
                float: left;
                width: calc(100% - 200px);
                padding-left: 0;
                list-style: none;

                // li:nth-child(7n+1) {
                //     width: 10%;
                // }
                li {
                    padding-top: 10px;
                    float: left;
                    padding-right: 10px;

                    .css-b62m3t-container {
                        min-width: 160px;
                        height: calc(2rem + 2px);

                        .css-1okebmr-indicatorSeparator {
                            display: none;
                        }

                        .css-1hb7zxy-IndicatorsContainer {
                            height: 33px;
                        }

                        .css-1s2u09g-control {
                            min-height: 33px;
                            border-color: #ced4da;
                        }
                    }

                    .form-control {
                        height: calc(2rem + 2px);
                    }

                    input::placeholder {
                        font-size: 12px;
                    }
                }
            }

            form {
                button {
                    margin-top: 20px;
                    border-radius: 3px;
                }
            }
        }

        .order-list {
            height: calc(100% - 95px);
            width: calc(100% - 20px);
            margin-left: 15px;

            .fixed-two-col-width-6 {
                background-color: white;
                top: 5px !important;
                right: calc(40% + 5px);
                left: 10px;
                width: calc(57% - 5px);
                padding: 15px 15px;

                .panel-body {
                    height: calc(100% - 42px);
                    background-color: white;
                    overflow: auto;
                    width: 100%;
                    padding: 10px 0px 10px 0px;
                    font-size: 12px;

                    .dataTables_wrapper.no-footer {
                        .dataTables_length {
                            text-align: end;

                            select {
                                margin-left: 10px;
                            }
                        }

                        background-color: white;
                        .table-list-table {
                            table {
                                border-radius: 3px;
                                margin: 1rem 0;
                                width: 100%!important;
                                border-top: 1px solid #DDD;
                                border-bottom: 1px solid #DDD;
                                
                                thead {
                                    color: #5e6d8c;
                                    height: 50px;
                                    border: solid 1px #d3dae7;
                                    th {
                                        padding-left: 5px;
                                    }
                                }
    
                                tbody {
                                    tr {
                                        cursor: pointer;
                                        border: solid 1px #d3dae7;
    
                                        td {
                                            padding: 5px;
    
                                            :first-child {
                                                min-width: 50px;
    
                                                .checkbox-inline {
                                                    width: 100%;
                                                    text-align: center;
                                                }
                                            }
    
                                            .checkbox-inline {
                                                margin-bottom: unset !important;
    
                                            }
                                        }
                                    }
    
                                    .booking-status {
                                        font-size: smaller;
                                        line-height: 10px;
                                        text-transform: none;
                                        // padding: 2px 5px 1px;
                                        font-weight: 500;
                                        color: #fff;
                                    }
                                }
    
                            }
                        }
                        

                        .dataTables_info {
                            padding-top: 8px;
                            margin-left: 1rem;
                            white-space: nowrap;
                            padding: 8px 0 0;
                            margin: 0;
                            float: left;
                        }

                        .dataTables_paginate {
                            margin: 0;
                            white-space: nowrap;
                            text-align: right;
                            margin-top: 5px;

                            .rs-pagination-group {
                                display: unset !important;
                            }
                        }
                    }
                }
            }

            .fixed-two-col-width-4 {
                background-color: white;
                /* top: 5px !important; */
                right: calc(40% + 5px);
                left: 10px;
                width: calc(43% - 5px);
                padding: 15px 15px;
                overflow-x: hidden;
                overflow-y: auto;

                .title-panel-flat {
                    padding-bottom: 10px;
                    border-bottom: solid 1px #d3dae7;
                    margin-bottom: 10px;

                    .status-detail {
                        position: absolute;
                        right: 10px;
                        top: 10px;

                        span {
                            font-size: smaller;
                            line-height: 10px;
                            text-transform: none;
                            padding: 2px 5px 1px;
                            font-weight: 500;
                            color: #fff;
                        }
                    }


                }

                .body-popup {
                    height: calc(100% - 42px);
                    overflow: unset;
                    width: 100%;

                    .customer {
                        .customer-avatar {
                            position: relative;

                            img {
                                height: 60px;
                                margin-right: 10px;
                                width: 60px;
                                border: solid 1px #ddd;
                                border-radius: 50%;
                                object-fit: cover;
                            }

                            .info-customer {
                                position: absolute;
                                top: 10px;
                                left: 70px;

                                p {
                                    font-size: 12px;
                                    color: #777;
                                    margin-top: 0px;
                                    font-weight: 700;
                                    margin-bottom: 0px;
                                }

                                p:first-child {
                                    color: #4a90e2;
                                    font-size: 16px;
                                }
                            }
                        }
                    }

                    .order-info {
                        margin-top: 15px;
                        padding-bottom: 10px;

                        span {
                            color: #2196F3 !important;
                        }

                        p {
                            color: #5e6d8c;
                            font-size: 12px;
                        }

                        .user-order-customer {
                            margin-top: 10px;
                            padding-bottom: 10px;
                            border-bottom: #5e6d8c 1px;

                            .user-title {
                                .name-user {
                                    color: #5e6d8c;
                                    font-size: 12px;
                                }
                            }

                            .user-order-date {
                                background-color: #999;
                                border-color: #999;
                                color: #fff;
                                font-size: 11px;
                                line-height: 12px;
                                text-transform: none;
                                padding: 1px 5px;
                            }
                        }

                    }

                    .table-list-order-item {
                        font-size: 12px;
                        max-height: 180px;
                        overflow: auto;
                        padding-top: 10px;
                        border-top: solid 1px #d3dae7;

                        table {
                            width: 100%;

                            .order-item-tr {
                                border-bottom: solid 1px #d3dae7;
                                .discount {
                                    color: var(--rs-text-primary);
                                    text-decoration: line-through;
                                }

                                > td {
                                    padding: 5px 0;
                                    vertical-align: middle;
                
                                    &.selected-operator {
                                        position: fixed;
                                        top: 30%;
                                        left: 50%;
                                        max-height: 500px;
                                        border: 1px solid #ddd;
                                        padding: 10px;
                                        border-radius: 5px;
                                        width: 550px;
                                        background: #FFF;
                                        box-shadow: 0 0 45px rgb(0 0 0 / 25%);
                                        z-index: 9999;
                                        overflow-y: auto;
                                        .content-data {
                                            position: relative;
                                            z-index: 2;
                
                                            .content-operator {
                                                text-align: left;
                                                margin-top: 20px;
                
                                                .head-block {
                                                    .select-all {
                                                        color: #4a90e2;
                                                        font-size: 14px;
                                                        margin-bottom: 20px;
                                                        cursor: pointer;
                                                        display: inline-block;
                                                    }
                
                                                    .title-block {
                                                        float: left;
                                                        font-size: 14px;
                                                        line-height: 32px;
                                                        width: 100%;
                                                    }
                
                                                    .input-search {
                                                        input {
                                                            height: 30px;
                                                            border: 1px solid #E4E4E4;
                                                            border-radius: 3px;
                                                            width: 200px;
                                                            font-size: 12px;
                                                        }
                                                    }
                                                }
                
                                                .body-operators {
                                                    margin-top: 15px;
                                                    border: solid 1px #d3dae7;
                                                    border-radius: 3px;
                
                                                    .top-title {
                                                        display: inline-block;
                                                        width: 100%;
                                                        padding: 10px;
                                                        border-bottom: solid 1px #d3dae7;
                
                                                        .top-name {
                                                            float: left;
                                                            font-size: 12px;
                                                            width: 90px;
                
                                                            &:first-child {
                                                                width: 250px;
                                                            }
                                                        }
                                                    }
                
                                                    .list-operator {
                                                        .item-operator {
                                                            display: inline-block;
                                                            width: 100%;
                                                            padding: 10px;
                
                                                            > div {
                                                                float: left;
                                                            }
                
                                                            .operator-name {
                                                                width: 250px;
                
                                                                .avatar {
                                                                    object-fit: cover;
                                                                    float: left;
                                                                    width: 35px;
                                                                    height: 35px;
                                                                    border: 1px solid #d6e0ef;
                                                                    border-radius: 50%;
                                                                }
                
                                                                .info {
                                                                    margin-left: 45px;
                                                                    padding-top: 3px;
                
                                                                    .name {
                                                                        color: #2196F3;
                                                                        font-size: 12px;
                                                                        text-transform: uppercase;
                                                                    }
                
                                                                    .position {
                                                                        font-size: 11px;
                                                                    }
                                                                }
                                                            }
                
                                                            .operator-service {
                                                                width: 90px;
                                                                line-height: 35px;
                
                                                                > div {
                                                                    width: 16px;
                                                                    height: 16px;
                                                                    border: 1px solid #5e6d8c;
                                                                    position: relative;
                
                                                                    &.active {
                                                                        border-color: #2196F3;
                
                                                                        &:before {
                                                                            content: "";
                                                                            position: absolute;
                                                                            top: 2px;
                                                                            left: 2px;
                                                                            width: 10px;
                                                                            height: 10px;
                                                                            background: #2196F3;
                                                                        }
                                                                    }
                
                                                                    input {
                                                                        width: 16px;
                                                                        height: 16px;
                                                                    }
                                                                }
                                                            }
                
                                                            .operator-consultant {
                                                                width: 90px;
                                                                line-height: 35px;
                
                                                                > div {
                                                                    width: 16px;
                                                                    height: 16px;
                                                                    border: 1px solid #5e6d8c;
                                                                    position: relative;
                
                                                                    &.active {
                                                                        border-color: #2196F3;
                
                                                                        &:before {
                                                                            content: "";
                                                                            position: absolute;
                                                                            top: 2px;
                                                                            left: 2px;
                                                                            width: 10px;
                                                                            height: 10px;
                                                                            background: #2196F3;
                                                                        }
                                                                    }
                
                                                                    input {
                                                                        width: 16px;
                                                                        height: 16px;
                                                                    }
                                                                }
                                                            }
                
                                                            &:not(:last-child) {
                                                                border-bottom: solid 1px #d3dae7;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                
                                        .overlay-user-list {
                                            position: fixed;
                                            top: 0;
                                            right: 0;
                                            bottom: 0;
                                            left: 0;
                                            z-index: 1;
                                        }
                                    }
                
                                    &:first-child {
                                        padding-left: 0;
                                    }
                
                                    .cash-vnd {
                                        text-align: right;
                                        padding: 0;
                                        font-weight: 700;
                                        font-size: 14px;
                                        margin-right: 10px;
                                    }
                                }
                            }

                            tr {
                                margin-bottom: 15px !important;

                                th {
                                    // color:#5e6d8c ;
                                    font-weight: 700;
                                }

                                td:first-child {
                                    padding-top: 10px;
                                    width: 50%;

                                }
                            }
                        }

                        .order-item-detail {
                            padding-bottom: 15px;

                            .item-avatar {
                                display: flex;

                                .info-service {
                                    margin-left: 10px;
                                    p:first-child {
                                        color: #5e6d8c;
                                        font-weight: 700;
                                    }
                                }

                                img {
                                    width: 50px;
                                    height: 50px;
                                }
                            }

                            .employee-order {
                                padding-top: 7px
                            }

                            p {
                                font-size: 12px;
                                margin-top: 0px;
                            }
                        }
                    }

                    .order-into-money {
                        font-weight: 700;
                        font-size: 12px;
                        margin-top: 15px;
                        padding-bottom: 10px;
                        border-bottom: #d3dae7 solid 1px;

                        p {
                            padding-bottom: 0;
                            margin-bottom: 0px;
                            margin-top: 0px;
                        }

                        .list-order-price {
                            text-align: end;
                            font-weight: 700;
                            p {
                                margin-top: 0px !important;
                                margin-bottom: 0px !important;
                            }
                        }
                    }

                    .order-total-money {
                        margin-top: 15px;
                        padding-bottom: 10px;
                        border-bottom: #d3dae7 solid 1px;
                        font-size: 12px;

                        .order-price-type {
                            font-weight: 700;
                            color: #5E6D94;

                            p:first-child {
                                width: 70%;
                                font-size: 20px;
                            }
                            p {
                                padding-bottom: 0;
                                margin-bottom: 0px;
                                margin-top: 0px;
                            }
                        }

                        .list-order-price {
                            text-align: end;

                            p:first-child {
                                font-size: 20px;
                            }
                            p {
                                margin-top: 0px !important;
                                margin-bottom: 0px !important;
                            }

                            font-weight: 700;
                        }
                        .node-order {
                            .node-title {
                                color: #2196F3 !important;
                                font-weight: 700;
                            }
                            .node-text {
                                background-color: #eeeeee;
                                padding: 5px 5px 5px 5px;
                                color: #777 !important;
                                display: inline-block;
                                width: 100%;
                                textarea {
                                    width: 100%;
                                    height: 100px;
                                    padding: 10px;
                                    border: 1px solid #e5e5ea;
                                    border-radius: 3px;
                                }
                                .button-cent {
                                    width: 80px;
                                    float: left;
                                    margin-left: 10px;
                                }
                            }
                        }
                    }

                    .order-total-checkout-buttons {
                        white-space: nowrap;
                        margin-bottom: 35px;

                        .bg-danger-400 {
                            background-color: #f2480c !important;
                            border-color: #f2480c !important;
                            color: #fff;
                        }

                        .btn.bg-teal-400 {
                            background-color: #4a90e2;
                            border-color: #4a90e2;
                            border-radius: 3px;
                            color: #fff;
                            &.disable {
                                opacity: 0.5;
                                cursor: not-allowed;
                            }
                        }
                    }
                }
            }

        }
    }

    .bg-green {
        background-color: #0288D1;
    }

    .cl-red {
        color: #D32F2F;
    }
    .blink_me {
        animation: blinker 1s linear infinite;
    }

    .cl-blue {
        color: #0288D1;
    }

    .cl-green {
        color: #259B9A;
    }

    .cl-green2 {
        color: #8BC34A;
    }
    .cl-violet {
        color: #673AB7;
    }

    .cl-gray {
        color: #999;
    }

    .font-10 {
        font-size: 10px !important;
    }

    .posion-wraper {
        position: relative;

        .left-view {
            position: absolute;
            right: 10px;
            top: 0px;
            i {
                margin-right: 5px;
                color: #4a90e2;
                cursor: pointer;
            }
        }
    }

    .order-complete {
        background-color: #8BC34A;
        padding: 2px 5px;
        border-radius: 2px;;
    }

    .order-canceled {
        background-color: #C62828;
        padding: 2px 5px;
        border-radius: 2px;
    }

    .order-swich {
        background-color: #700909;
        padding: 2px 5px;
        border-radius: 2px;
    }

    .order-incomplete {
        background-color: #FF9800;
        padding: 2px 5px;
        border-radius: 2px;
    }

    .order-upsale {
        background-color: #26A69A;
        padding: 2px 5px;
        border-radius: 2px;
    }

    a {
        color: #fff;
    }

    .btn-close {
        background: #fff;
        color: #5e6d8c;
        border: solid 1px #d3dae7;
    }

    .order-status {
        clear: both;
        color: #999;
        font-size: 12px;
        position: absolute;
        right: 10px;
        top: 15px;
        padding-left: 20px;

        span {
            // background-color: #8BC34A !important;
            border-color: #8BC34A;
            font-size: smaller;
            line-height: 10px;
            text-transform: none;
            padding: 2px 5px 1px;
            font-weight: 500;
            color: #fff;
        }
    }

    .popup-cent {
        .content-popup {
            &.package-popup.voucher {
                width: 80%;
            }
            .body-popup .items-data .value textarea {
                height: 100px;
            }
        }
        .head-popup {
            .more-info {
                display: inline-block;
                color: #999;
                font-size: 10px;
                margin-left: 20px;
                font-style: italic;
                span {
                    color: #2196F3;
                }
            }
        }
        .row {
            margin: 0 -5px 10px;
            align-items: baseline;
            > div {
                padding: 0 5px;
            }
        }
        .item-more-customer {
            .service, .user {
                p {
                    line-height: 1;
                }
            }
        }
        // .select {
        //     top: 5px;
        // }
        .bold {
            font-weight: 700;
        }
        .add-more {
            color: #4a90e2;
            cursor: pointer;
            font-size: 12px;
            position: absolute;
            bottom: -10px;
            line-height: 1;
            i {
                margin-right: 3px;
            }
        }
        .count-time {
            font-size: 10px;
            color: #777;
            &.active {
                color: #8BC34A
            }
        }
        .booking-type {
            display: flex;
            &.disable {
                opacity: .5;
            }
            .item-type {
                width: 50%;
                text-align: center;
                border: 1px solid #d3dae7;
                color: #5e6d8c;
                height: 32px;
                line-height: 32px;
                cursor: pointer;
                &.active {
                    color: #fff;
                    background-color: #4a90e2;
                    border-color: #4a90e2;
                }
                &:first-child {
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                    border-right: 0;
                }
                &:last-child {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    border-left: 0;
                }
            }
        }
        .item-sidebar {
            margin-bottom: 30px;
            padding-left: 5px;
            .label-sidebar {
                padding-bottom: 7px;
                color: #5e6d8c;
                font-size: 12px;
                font-weight: 700;
            }
            .disable {
                opacity: 0.5;
            }
            .value-sidebar {
                .item {
                    padding: 0 12px;
                    color: #5e6d8c;
                    height: 36px;
                    line-height: 36px;
                    cursor: pointer;
                    font-size: 12px;
                    &:first-child {
                        border-top-left-radius: 3px;
                        border-top-right-radius: 3px;
                    }
                    &:last-child {
                        border-bottom-left-radius: 3px;
                        border-bottom-right-radius: 3px;
                    }
                    &.active {
                        color: #fff;
                        background-color: #4a90e2;
                        border-color: #4a90e2;
                    }
                }
            }
            &.status {
                .value-sidebar {
                    .item {
                        border: 1px solid #d3dae7;
                        &:not(:last-child) {
                            border-bottom: none;
                        }
                    }
                }
            }
            &.source {
                .value-sidebar {
                    border: 1px solid #d3dae7;
                    max-height: 320px;
                    border-radius: 3px;
                    .item {
                        text-align: center;
                        &:not(:last-child) {
                            border-bottom: 1px solid #d3dae7;
                        }
                    }
                }
            }
            .small-circle {
                border-radius: 8px;
                display: inline-block;
                height: 16px;
                margin-bottom: -3px;
                margin-right: 10px;
                width: 16px;
            }
        }
        .result-search {
            position: absolute;
            z-index: 6;
            background: #fff;
            width: calc(100% - 10px);
            border: 1px solid #d3dae7;
            border-radius: 3px;
            .empty-customer {
                line-height: 1;
                padding: 10px;
                font-size: 16px;
            }
            .items-customer {
                height: 350px;
                overflow: hidden;
                overflow-y: scroll;
                .item {
                    display: flex;
                    cursor: pointer;
                    padding: 10px;
                    .avatar {
                        float: left;
                        img {
                            border-radius: 50%;
                            width: 36px;
                            height: 38px;
                            object-fit: cover;
                        }
                    }
                    .info {
                        float: left;
                        padding-left: 10px;
                        width: calc(100% - 36px);
                        > div {
                            line-height: 1;
                        }
                        .name {
                            text-transform: uppercase;
                            border-radius: 3px;
                            background: red;
                            color: #fff;
                            display: inline-block;
                            padding: 3px;
                            position: relative;
                            top: -5px;
                        }
                        .phone {
                            position: relative;
                            top: -7px;      
                            color: #999;
                            font-size: 10px;              
                        }
                        .ranking {
                            position: relative;
                            top: -5px;
                            margin-left: 5px;
                            font-size: 10px;
                        }
                        .address {
                            color: #999;
                            font-size: 10px;    
                        }
                    }
                    &:not(:last-child) {
                        border-bottom: 1px solid #d3dae7;

                    }
                    &:hover {
                        background: #f6fdff;
                    }
                }
            }
        }
        .old-customer {
            .row {
                align-items: flex-start;
                padding-top: 10px;
            }
            .avatar {
                border-radius: 50%;
                position: relative;
                line-height: 60px;
                height: 100%;
                // text-align: center;
                img {
                    object-fit: cover;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                }
            }
            .info-customer {
                line-height: 1;
                .top-info {
                    margin-bottom: 10px;
                }
            }
            .gender {
                color: rgb(33, 150, 243);
                font-size: 12px;
                margin-right: 2px;
            }
            .name {
                font-size: 14px;
                font-weight: 700;
                margin: 0;
                color: #2196F3;
                text-transform: uppercase;
            }
            .button-edit {
                border-radius: 2px;
                background: #2196F3;
                color: #FFF;
                margin-left: 5px;
                padding: 3px;
                cursor: pointer;
            }
            .button-create-new {
                margin-left: 10px;
                color: #2196F3;
                text-decoration: underline;
                cursor: pointer;
                i {
                    margin-right: 5px;
                }
            }
            .ranking {
                margin-top: 15px;
            }
            .phone, .address, .ranking {
                line-height: 1;
                color: #5e6d8c;
                font-size: 12px;
                margin: 8px 0;
                i {
                    margin-right: 10px;
                    font-size: 13px;
                }
            }
            .note {
                border: 1px solid #5e6d8c;
                line-height: 16px;
                display: inline-block;
                padding: 5px;
                border-radius: 3px;
            }
            i {
                font-size: 10px;
            }
        }
    }
}

.package-popup {
    .head-popup {
        font-size: 21px;
        padding: 10px;
        border-bottom: solid 1px #d3dae7;
        position: relative;

        // .title {
        //     span {
        //         color: #2196F3 !important;
        //     }
        // }
    }

    .body-popup {
        // min-height: 200px;
        // max-height: 300px;
        overflow: auto;
        padding-bottom: 100px;
        position: relative;
        padding: 10px;
        font-size: 12px;
        font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
        line-height: 1.5384616;
        color: #5e6d8c;

        .list-title {
            list-style: none;
            padding: 5px 0;
            margin: 0;
            border-bottom: 1px solid #ddd;
            display: inline-block;
            width: 100%;
            >li {
                float: left;
                width: 16.6%;
            }  
        }
        .list-data {
            margin: 0;
            list-style: none;
            display: inline-block;
            width: 100%;
            padding: 0;
            >li {
                float: left;
                width: 16.6%;
            }  
        }
        .debt-order-list {
            .debt-order-list-title {
                border: none;
                margin-bottom: 0;
                display: inline-block;
                width: 100%;
                .print-botton {
                    background-color: #4a90e2;
                    border-color: #4a90e2;
                    border-radius: 3px;
                    color: #fff !important;
                }
                .remover-botton {
                    background-color: red;
                    border-color: red;
                    border-radius: 3px;
                    color: #fff !important;
                }

                .check-qr-bottom {
                    background-color: #f89406;
                    border-color: #f89406;
                    border-radius: 3px;
                    color: #fff !important;
                    margin-left: 5px;
                    margin-right: 5px;
                }

                .success-qr-bottom {
                    background-color: #28a745;
                    border-color: #28a745;
                    border-radius: 3px;
                    color: #fff !important;
                    margin-right: 5px;
                }

                .debt-order-item-list {
                    float: left;
                    width: 22%;
                    margin-top: 10px;
                    text-align: left;
                    overflow: hidden;
                    word-break: break-word;
                    padding-right: 10px;

                    .tag {
                        display: inline-block;
                        margin: 0 5px 5px 0;
                        background: #efefef;
                        color: #5e6d8c;
                        border: 1px solid #ddd;
                    }

                }

                .debt-order-history {
                    list-style: none;
                    margin-left: 0;
                    padding: 10px;
                    float: left;
                    background: #efefef;
                    border-radius: 10px;
                    margin-top: 10px;
                    width: 78%;
                    .item-transacion {
                        border-bottom: solid 1px #ddd;
                        padding-bottom: 10px;
                        >div {
                            padding-top: 8px;
                        }
                    }
                    .form-transaction {
                        padding-top: 20px;
                        display: inline-block;
                        width: 100%;
                    }

                    >li {
                        clear: both;
                        // overflow: hidden;
                        min-height: 50px;

                        > div {
                            line-height: 32px;
                        }

                        .action-transacion {
                            width: 70px;
                            float: right;
                        }

                        .date-pay {
                            width: 110px;
                            float: left;

                        }

                        .coupon-code {
                            width: 90px;
                            float: left;
                        }

                        .pay-method {
                            width: 18%;
                            float: left;

                        }

                        .payment-amount {
                            width: 90px;
                            float: left;
                        }

                        .owed-money {
                            width: 90px;
                            float: left;

                        }
                        .user-collecter-value {
                            display: flex;
                            padding-left: 10px;
                            float: left;
                            width: 100px;
                            text-align: center;
                            min-height: 40px;
                            justify-content: center;
                            align-items: center;
                            .value {
                                line-height: 1.5;
                            }
                        }
                        .action {
                            display: inline-block;
                        }
                        .user-collecter {
                            padding-left: 10px;
                            float: left;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            width: 100px;
                            text-align: center;
                        }
                        .pay-account-item-list {
                            clear: left;
                            text-align: left;
                            padding-top: unset;
                            color: #5e6d8c;
                            line-height: 1.5;
                            padding-top: 10px;
                            a {
                                color: #5e6d8c;
                            }
                        }
                        .content-item {
                            display: flex;
                            text-align: left;
                            align-items: center;
                            color: #5e6d8c;
                            line-height: 1.5;
                            padding-top: 10px;
                            .screen {
                                margin-left: 50px;
                                span {
                                    color: #21262c;
                                    font-weight: 600;
                                }
                            }
                        }

                    }
                    .li-form-transaction {
                        padding-top: 10px;
                        .pay-select-stores {
                            width: 22%;
                            float: left;
                        }
                        .select-option-store {
                            width: 52%;
                            float: left;
                        }
                        &.banking {
                            > div {
                                width: 70%;
                            }
                            .bank-screen {
                                margin-bottom: 10px;
                                display: inline-block;
                            }
                            .pay-select-stores {
                                width: 25%;
                                float: left;
                            }
                            .select-option-store {
                                width: 75%;
                                float: left;
                            }
                        }
                    }
                    .btn-add-transaction {
                        background-color: #4a90e2;
                        border-color: #4a90e2;
                        border-radius: 3px;
                        color: #fff;
                    }
                    .disable {
                        opacity: 0.5;
                    }
                    .ng-scope {
                        .cash-vnd {
                            color: #259B9A;
                            position: relative;
                            white-space: nowrap;
                        }
                    }
                    .form-transaction{
                        ::placeholder {
                            font-size: 12px;
                        }
                        .input-transaction-date{
                            width: 22%;
                            float: left;
                            margin-right: 10px;
                            .rs-picker-toggle-wrapper {
                                width: 100%;
                                .rs-btn.rs-btn-default {
                                    width: 100%;
                                    height: 38px;
                                    padding-right: unset;
                                    .rs-picker-toggle-textbox.rs-picker-toggle-read-only {
                                        padding-right: unset;
                                    }
                                    .rs-picker-toggle-clean.rs-btn-close {
                                        display: none;
                                    }
                                    .rs-picker-toggle-caret.rs-icon {
                                        display: none;
                                    }
                                }
                            }

                        }
                        .option-pay-method {
                            width: 17%;
                            float: left;
                            line-height: unset;
                            .css-b62m3t-container {
                                width: 100%;
                                .css-jf6xnh-control {
                                    width: 100%;
                                }
                            }
                        }
                        .customer-payment-amount {
                            width: 35%;
                            float: left;
                            padding-left: 10px;
                        }
                    }
                }

                .input-cash {
                    background-color: #fff;
                    border: 1px solid #ced4da;
                    border-radius: 0.25rem;
                    color: #495057;
                    display: block;
                    font-size: 1rem;
                    height: calc(2.25rem + 2px);
                    line-height: 1.5;
                    padding: 0.375rem 0.75rem;
                    width: 100%;
                }
            }

            .text-bold {
                font-weight: 700;
            }

            .pr-20 {
                padding-right: 20px !important;
            }

            .text-right {
                text-align: right;
            }
        }
    }
}

.print-transaction-detail {
    font-size: 13px;
    .header {
        padding-bottom: 15px;
        .title {
            font-size: 27px;
            text-align: center;
        }
    }
    .info-cus {
        width: 100%;
        padding-left: 10px;
        p {
            label{
                padding-right: 20px;
            }
            .adress {
                padding-left: 22px;
            }
        }
        
    }
    .transaction-item {
        text-align: center;
        .title {
            font-size: 27px;
            padding-top: 20px;
            padding-bottom: 15px;
        }
    }
    p {
        margin-bottom: 0px;
        margin-top: 0px;
    }
    .customer-user {
        text-align: center;
        p:last-child {
            padding-top: 30px;
        }
        p:first-child {
            color: black;
            font-weight: 600;

        }
    }
    .transaction-item-detail {
        padding-left: 10px;
        .item {
            display: inline-block;
            width: 100%;
        }
        
    }
    .left {
        float: left;
        width: 120px;
    }
    .right {
        float: right;
    }  
}

.pull-right.btn-group {
    .dropdown {
        .dropdown-menu {
            a {
                clear: both;
                font-weight: 400;
                line-height: 1.5384616;
                color: #333;
                padding: 8px 15px;
                font-size: 13px;
            }
        }
    }
}
.body-popup {
    .panel-body {
        height: calc(100% - 42px);
        background-color: white;
        width: 100%;
        padding: 10px 0px 10px 0px;
        font-size: 12px;
    
        .dataTables_wrapper.no-footer {
            .dataTables_length {
                text-align: end;
    
                select {
                    margin-left: 10px;
                }
            }
    
            background-color: white;
            .table-list-table {
                table {
                    border-radius: 3px;
                    margin: 1rem 0;
                    width: 100%!important;
                    border-top: 1px solid #DDD;
                    border-bottom: 1px solid #DDD;
                    
                    thead {
                        color: #5e6d8c;
                        height: 50px;
                        border: solid 1px #d3dae7;
                        th {
                            padding-left: 5px;
                        }
                    }
    
                    tbody {
                        tr {
                            cursor: pointer;
                            border: solid 1px #d3dae7;
    
                            td {
                                padding: 5px;
    
                                :first-child {
                                    min-width: 50px;
    
                                    .checkbox-inline {
                                        width: 100%;
                                        text-align: center;
                                    }
                                }
    
                                .checkbox-inline {
                                    margin-bottom: unset !important;
    
                                }
                            }
                        }
    
                        .booking-status {
                            font-size: smaller;
                            line-height: 10px;
                            text-transform: none;
                            // padding: 2px 5px 1px;
                            font-weight: 500;
                            color: #fff;
                        }
                    }
    
                }
            }
            
    
            .dataTables_info {
                padding-top: 8px;
                margin-left: 1rem;
                white-space: nowrap;
                padding: 8px 0 0;
                margin: 0;
                float: left;
            }
    
            .dataTables_paginate {
                margin: 0;
                white-space: nowrap;
                text-align: right;
                margin-top: 5px;
    
                .rs-pagination-group {
                    display: unset !important;
                }
            }
        }
    }
}

.isCopy {
    background: #59c136;
    color: white;
}

.isCopy-2 {
    background: #59c136 !important;
    color: white;
}
@media (max-width: 1199px) {
    .admin-page.orders {
        .fixed-content-wrapper {
            position: unset;
            background: #fff;
            .order-advanced-search {
                display: inline-block;
                width: 100%;
                border-bottom: 7px solid #e6eef3;
                .action-form  {
                    .order-filter-block {
                        width: 100%;
                        li {
                            width: 25%;
                        }
                    }
                    .button-action {
                        margin-top: 0;
                    }
                }
            }
            
            .order-list {
                width: 100%;
                height: auto;
                padding: 0 10px;
                margin: 0;
                .fixed-two-col-width-6 {
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                    .panel-body {
                        height: auto;
                    }
                }
                
                .fixed-two-col-width-4 {
                    position: fixed;
                    width: 95%;
                    height: 80vh;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1050;
                    overflow: hidden;
                    .content-order-detail {
                        overflow-y: scroll;
                        height: 100%;
                        .body-popup {
                            .table-list-order-item {
                                max-height: none;
                                table .order-item-tr > td.selected-operator {
                                    transform: translate(-50%, -50%);
                                    top: 50%;
                                    left: 50%;
                                    width: 80%;
                                    height: 80%;
                                }
                            }
                        }
                    }
                }
                .overlay-detail {
                    background: #000;
                    bottom: 0;
                    left: 0;
                    opacity: .5;
                    position: fixed;
                    right: 0;
                    top: 0;
                    z-index: 1040;
                }
            }
        }
         
    }
    .package-popup {
        .body-popup {
            .order-item-transaction {
                .debt-order-list {
                    .debt-order-list-title {
                        .debt-order-item-list, .debt-order-history {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .package-popup {
        .body-popup {
            .order-item-transaction {
                &:not(:last-child) {
                    border-bottom: 2px solid #FF9800;
                    margin-bottom: 20px;
                    padding-bottom: 15px;
                }


                .debt-order-list {
                    .list-data {
                        .mobile-table-haft {
                            width: 50%;
                            display: inline-block;
                            float: none;
                            padding: 0;
                            line-height: 32px;
                            border-bottom: solid 1px #ddd;
                            .title {
                                float: left;
                                text-align: left;
                                padding-right: 3px;
                                color: #007bff;
                                
                            }
                            .value {
                                float: left;
                                text-align: left;
                            }
                        }
                    }
                    .debt-order-list-title {
                        .debt-order-history {
                            background: transparent;
                            padding: 0;
                            .item-transacion {
                                background: #efefef;
                                border-radius: 10px;
                                margin-bottom: 10px;
                                padding: 0 10px;
                                padding: 10px;
                                border-bottom: none;
                                .mobile-table {
                                    width: 100%;
                                    display: inline-block;
                                    float: none;
                                    padding: 0;
                                    line-height: 32px;
                                    &:not(:last-child) {
                                        border-bottom: solid 1px #ddd;
                                    }
                                    .title {
                                        float: left;
                                        width: 120px;
                                        text-align: left;
                                    }
                                    .value {
                                        float: left;
                                        text-align: left;
                                        width: calc(100% - 120px);
                                    }
                                }
                            }
                            .form-transaction {
                                .input-transaction-date, .option-pay-method, .customer-payment-amount, .button-action {
                                    width: calc(50% - 10px);
                                    padding: 0 5px 10px 5px;
                                    margin: 0;
                                }
                                .button-action {
                                    float: left;
                                }
                            }
                            .li-form-transaction {
                                .select-option-store {
                                    width: 65%;
                                }
                                .pay-select-stores {
                                    width: 35%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .admin-page.orders {
        .header-right .rs-picker-daterange {
            padding-right: 0;
            margin-top: 10px;
        }
        .fixed-content-wrapper {
            .order-list {
                .fixed-two-col-width-6 .panel-body .dataTables_wrapper.no-footer .table-list-table {
                    overflow-y: hidden;
                    table {
                        overflow-y: scroll;
                        thead tr th,
                        tbody tr td {
                            min-width: 120px;
                            min-height: 50px;
                        }
                    }
                }
                .fixed-two-col-width-4 .body-popup {
                    .table-list-order-item table {
                        tr th:not(:first-child) {
                            text-align: center;  
                        }
                        .order-item-tr > td {
                            padding: 5px;
                            &:not(:first-child) {
                                text-align: center;  
                            }
                        }
                    }
                    .order-total-checkout-buttons .btn.bg-teal-400 {
                        margin-bottom: 10px;
                    }
                }
            } 
            .order-advanced-search {
                .action-form  {
                    .order-filter-block {
                        li {
                            width: 50%;
                            .btn {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}