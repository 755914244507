.admin-page.services {
    .show-popup-cat {
        display: none;
    }
    .admin-body {
        padding: 0;
        .wrapper {
            position: absolute;
            top: 110px;
            left: 0;
            right: 0;
            bottom: 20px;
            margin: 0;
            padding: 0;
            .column-left {
                border: 1px solid #ddd;
                border-radius: 5px;
                background: #fff;
                color: #5e6d8c;
                font-size: 12px;
                position: absolute;
                top: 0;
                bottom: 0;
                overflow-x: hidden;
                overflow-y: auto;
                box-shadow: 0 0 15px rgb(0 0 0 / 8%);
                left: 10px;
                right: calc(100% - 300px);
                .head-column {
                    padding: 10px;
                    border-bottom: 1px solid #d3dae7;
                    position: relative;
                    .button-close {
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        display: none;
                    }
                }
                .body-column {
                    padding: 13px 10px;
                    height: calc(100% - 90px);
                    overflow: auto;
                    .item-service {
                        padding: 10px;
                        cursor: pointer;
                        border-bottom: 1px solid #eee;
                        position: relative;
                        &.active {
                            background: #2196F3;
                            color: #fff;
                        }
                        .title {
                            display: flex;
                            padding-right: 25px;
                            span {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                width: 100%;
                                display: inline-block;
                            }
                        }
                        .button-edit {
                            position: absolute;
                            right: 5px;
                            top: 10px;
                            > i {
                                width: 20px;
                                height: 20px;
                                text-align: center;
                                line-height: 18px;
                                border: 1px solid #d3dae7;
                                border-radius: 3px;
                                background: #fff;
                                color: #5e6d8c;
                            }
                            &.active {
                                .dropdown-action {
                                    display: block;
                                }  
                            }
                            .dropdown-action {
                                display: none;
                                min-width: 180px;
                                padding: 7px 0;
                                color: #333;
                                position: absolute;
                                top: 100%;
                                right: 0;
                                background: #fff;
                                border-radius: 3px;
                                z-index: 2;
                                border: 1px solid #ddd;
                                border-radius: 3px;
                                -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
                                box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
                                margin: 2px 0 0;
                                list-style: none;
                                font-size: 13px;
                                .action {
                                    padding: 8px 15px;
                                    text-align: left;
                                    i {
                                        margin-right: 12px;
                                        margin-top: 2px;
                                        min-width: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
                .bottom-column {
                    border-bottom-right-radius: 3px;
                    border-bottom-left-radius: 3px;
                    background: #fcfcfc;
                    padding: 7px 5px;
                    .row {
                        margin: 0;
                        > div {
                            padding: 0;
                        }
                        input {
                            padding: 7px;
                            border:1px solid #d3dae7;
                            height: 32px;
                            border-radius: 2px;
                            font-size: 12px;
                            outline: none !important;
                            box-shadow: none;
                        }
                        .button-cent2 {
                            margin-left: 2px;
                            background: #4a90e2;
                            color: #fff;
                            font-size: 12px;
                            border-radius: 3px;
                            height: 32px;
                            line-height: 32px;
                            text-align: center;
                            cursor: pointer;
                            i {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
            .column-right {
                border: 1px solid #ddd;
                border-radius: 5px;
                padding: 0 10px 10px;
                background: #fff;
                font-size: 12px;
                color: #5e6d8c;
                position: absolute;
                top: 0;
                bottom: 0;
                overflow-x: hidden;
                overflow-y: auto;
                box-shadow: 0 0 15px rgb(0 0 0 / 8%);
                left: 310px;
                right: 10px;
                .head-column {
                    display: inline-block;
                    width: 100%;
                    padding: 10px 0;
                    background: #fff;
                    z-index: 2;
                    position: sticky;
                    top: 0;
                    border-bottom: 1px solid #ddd;
                    .search-service {
                        float: left;
                        width: calc(100% - 450px);
                        position: relative;
                        input {
                            padding: 7px;
                            border: 1px solid #d3dae7;
                            height: 32px;
                            border-radius: 30px;
                            width: 100%;
                            font-size: 12px;
                        }
                        i {
                            position: absolute;
                            top: 9px;
                            right: 12px;
                            font-size: 14px;
                        }
                    }
                    .button-cent {
                        float: right;
                    }
                }
                .table thead {
                    background: #fff;
                    z-index: 2;
                    position: sticky;
                    top: 52px;
                    th {
                        border-bottom: 1px solid #dee2e6;
                        border-top: none;
                    }
                }
            }
        }
    }
    .popup-cent {
        .content-popup {
            width: 700px;
            .body-popup {
                .header-tab {
                    display: flex;
                    margin-bottom: 20px;
                    .item {
                        padding: 9px 15px;
                        text-align: center;
                        width: 33.333%;
                        border-bottom: 1px solid #ddd;
                        cursor: pointer;
                        &.active {
                            border-top: 1px solid #ddd;
                            border-left: 1px solid #ddd;
                            border-right: 1px solid #ddd;
                            border-bottom: none;
                        }
                    }
                }
                .items-data .value {
                    .price-input {
                        padding-right: 30px;
                    }
                    .currency {
                        position: absolute;
                        top: 3px;
                        right: 15px;
                        width: 30px;
                        font-size: 15px; 
                        text-align: center;                   
                    }
                    .checkbox {
                        input {
                            width: 15px;
                            height: 15px;
                            display: inline-block;
                            margin-right: 5px;
                            position: relative;
                            top: 3px;
                        }
                    }
                }
            }
            .bottom-popup {
                border-top: solid 1px #d6e0ef;
                .status-service {
                    float: left;
                    padding: 10px;
                    font-size: 12px;
                }
            }
        }
    }
    .select {
        [class$="ValueContainer"] {
            height: 34px;
            padding-top: 0;
            padding-bottom: 0;
        }
        [class$="Input"] {
            height: 34px;
            padding: 0;
            margin: 0;
        }
    }
    .inline {
        display: inline-block;
    }

    .fr {
        float: right;
    }
    .tr {
        text-align: right;
    }
    .discount {
        input {
            width: calc(100% - 80px) !important;
        }
    }
    .table td, .table th {
        vertical-align: middle;
    }
    .dt-right {
        text-align: right;
    }
    .pdr-65 {
        padding-right: 65px;
    }
    .cash-vnd {
        color: #259B9A;
        font-size: 14px;
        padding-right: 50px;
        font-weight: 700;
    }
    .tag {
        border-radius: 3px;
        padding: 0 5px;
        color: #fff;
    }
    .green {
        background: #8BC34A;
    }
    .red {
        background: #F44336;
    }
    .button {
        border: 1px solid #d3dae7;
        color: #5e6d8c;
        font-size: 12px;
        background-color: #fcfcfc;
        padding: 5px 10px;
        cursor: pointer;
    }
    .radius-left {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }
    .radius-right {
        border-left: none;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }
    .ic-none {
        padding-left: 10px;
    }
    @media (max-width: 1199px) {
        .admin-body .wrapper .column-right {
            .head-column {
                .search-service {
                    width: calc(100% - 150px);
                }
            } 
            .table thead th:first-child {
                width: 250px
            }
        } 
        .pdr-65, .cash-vnd {
            padding-right: 12px;
        }
        .cash-vnd {
            padding-right: 0;
        }
    }

    @media (max-width: 991px) {
        .show-popup-cat {
            display: block;
        }
        .admin-body .wrapper {
            .column-right {
                left: 10px;
            }
            .column-left {
                .head-column .button-close {
                    display: block;
                }
                .wrapper-column {
                    max-height: none;
                    z-index: 1000;
                    transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
                    background: #fff;
                    box-shadow: none;
                    touch-action: manipulation;
                    pointer-events: none;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    visibility: hidden;
                    position: fixed;
                    opacity: 1;
                    width: calc(100vw - 65px);
                    top: 0;
                    right: auto;
                    left: 0;
                    transform: translate3d(-104%, 0, 0);
                    overflow-y: auto;
                    overflow-x: hidden;
                }

                .overlay-cat {
                    position: fixed;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100vw;
                    z-index: 999;
                    visibility: hidden;
                    pointer-events: none;
                    opacity: 0;
                    background: rgba(0, 0, 0, 0.7);
                    transition: opacity 0.3s ease-in-out, visibility 0.3s;
                    cursor: pointer;
                }
                &.active {
                    .wrapper-column, .overlay-cat {
                        pointer-events: auto;
                        visibility: visible;
                        transform: none;
                        -webkit-transform: none;
                        opacity: 1;
                    }
                }
            }
        } 
    }

    @media (max-width: 767px) {
        .btn-group {
            margin-top: 10px;
            .button {
                line-height: 1.5;
            }
        }
        .popup-cent .content-popup .body-popup .items-data {
            align-items: baseline;
            margin-bottom: 20px;
            line-height: 1.5;
            .checkbox {
                margin-top: 10px;
            }
        }
    }

    @media (min-width: 641px) and (max-width: 991px) {
        .admin-body .wrapper .column-left .wrapper-column {
            width: 340px;
        }
    }
}
