.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.qr-success {
  color: #fff;
  background: #28a745;
  padding: 2px 5px;
}

.qr-pending {
  color: #fff;
  background: #4a90e2;
  padding: 2px 5px;
}

@media (max-width: 768px) { 
  .search-report {
    margin-top: 52px;
  }
}
.popup-cent {
  .popup-view-services {
    width: auto !important;
    max-width: 1024px;
  }
}


.text_service {
  color: blue;
  cursor: pointer;
  font-weight: 600;
}
.text_service_none {
  color: #5e6d8c;
  font-weight: 600;
}
.text1 {
   color: green;
  }
  .text2{
    color:red
  }
  .text3{
    color: #D81B60;
  }
  .text4{
    color: blue;
  }
  .text5{
    font-size: 10px;
  }
  .text7 {
    color: #fb8a25;
  }
  .text8 {
    color: yellow;
  }
 .click-name{
    color: blue;
    cursor: pointer;
 }
 .text-date{
  color: #5e6d8c;
    font-size: 12px;
 }
 .order{
  cursor: pointer;
 }
 .order:hover{
  background-color:rgba(205, 205, 205, 0.87) ;
 }
 .text5{
  font-size: 10px;
 }
 .scrollTransaction{
  height: 500px;
  overflow: scroll;
}
.text-note{
  font-size: 11px;
    font-weight: normal;
    font-style: italic;
    color: #5e6d8c;
}

p {
  margin-bottom: 0;
}

.report-page {
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding: 0 5px;
  }
}

.pay_type {
  .rs-tag+.rs-tag {
    margin-left: 0px !important
  }
}
.h-55 {
  height: 55px;
}
.mh-172 {
  min-height: 172px;
}

.mb-3 {
  margin-bottom: 3px !important;
}
@media (max-width: 1199px) {
  .report-qrcode {
    .ant-table-content {
      overflow-x: auto;
      tr {
        th,td {
          min-width: 150px;
        }
      }
    }
  }
}
