.admin-page.operators {
    .admin-body {
        .dashboard-content {
            .wrapper {

            }
        }
    }
    .popup-cent .content-popup .body-popup .items-data {
        .value .checkbox {
            span {
                line-height: 1;
                font-size: 12px;
                margin-left: 10px;
                position: relative;
                top: -3px;
            }
            input {
                display: inline-block;
                width: 15px;
                height: 15px;
            }
        }
        .value .customer-avatar {
            cursor: pointer;
            img {
                width: 100px;
                height: 105px;
                object-fit: cover;
            }
        }
    }
    .select {
        [class$="ValueContainer"] {
            padding-top: 0;
            padding-bottom: 0;
        }
        [class$="Input"] {
            height: 34px;
            padding: 0;
            margin: 0;
        }
        [class$="multiValue"] {
            height: 25px;
            padding: 0;
            margin-right: 5px;
        }
        [class$="multiValue"] > div {
            height: 25px;
            padding: 0 5px;
            line-height: 25px;
        }
    }
    .inline {
        display: inline-block;
    }

    .tag {
        border-radius: 3px;
        padding: 0 5px;
        color: #fff;
    }
    .green {
        background: #8BC34A;
    }
    .red {
        background: #F44336;
    }
    .table td, .table th {
        vertical-align: middle;
        font-size: 12px;
        border-top: none;
    }
    .button-container {
        float: left;
        .button-cent {
            margin-left: 5px;
            display: inline-block;
        }
    }

    .page-search {
        display: flex;
        justify-content: end;
        margin-top: 5px;
        padding: 0 10px;
        input {
            width: 200px;
            height: 32px;
            margin-right: 10px;
        }
    }

    @media (max-width: 1199px) {
        .dashboard-content {
            overflow-x: scroll;
            .table {
                td, th {
                    min-width: 150px;
                    max-width: 250px;
                }
            }
        }
        .button-container .button-cent {
            padding: 0 10px;
            span {
                display: none;
            }
        }
    }
    @media (max-width: 767px) {
        .button-container {
            margin: 10px 10px 0 0; 
        }
    }
}