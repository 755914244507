
.background{
    background-color: #e6eef3;
    width: 100%;
    margin: 0px;
}
.background2{
    background-color: rgb(246, 246, 249);
}
.buttonClear{
    border: 1px solid #bfbfbf;
    background-color: white;
    height: auto;
}
.fixedPagination{
    position: sticky;
    bottom: 10px;
    padding-right: 5%;
    background: white;
    z-index: 1;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}