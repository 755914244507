.admin-page.booking {
    .page-title {
        .button-cent {
            float: none;
            display: inline-block;
        }

        .header-right {
            float: right;

            .rs-picker-daterange {
                margin: 0 15px;

                > div {
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }
        }
    }
    .admin-body {
        padding: 0;

        .dashboard-content {
            background: transparent;
            border: none;
            padding: 0;

            .wrapper {
                position: absolute;
                top: 100px;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0;
                padding: 0;
                background: #e6eef3;
                .tooltip-booking {
                    padding: 10px;
                    p {
                        margin-bottom: 5px;
                        margin-top: 0;
                    }
                }
                .fixed-column {
                    position: absolute;
                    top: 0;
                    bottom: 10px;
                    overflow-x: hidden;
                    overflow-y: auto;
                    box-shadow: 0 0 15px rgb(0 0 0 / 8%);
                    border: 1px solid #d3dae7;
                    background: #fff;
                    border-radius: 3px;
                    font-size: 12px;
                    color: #5e6d8c;

                    &.panel-pre-booking {
                        left: 10px;
                        right: calc(66.666% + 5px);
                    }
                    &.panel-waiting {
                        left: calc(33.333% + 5px);
                        right: calc(33.333% + 5px);
                    }

                    &.panel-order {
                        left: calc(66.666% + 5px);
                        right: 10px;
                        .header-col {
                            border-bottom: 1px solid #d3dae7;
                            padding: 5px 0;
                            background: #fff;
                            display: inline-block;
                            width: 100%;

                            .search-button {
                                width: calc(100% - 130px);
                                float: left;
                                padding-left: 5px;

                                .search-input {
                                    border-radius: 30px;
                                    font-weight: 400;
                                    padding: 0 7px;
                                    border: 1px solid #d3dae7;
                                    color: #5e6d8c;
                                    line-height: 32px;
                                    width: 100%;
                                }
                            }

                            .filter-by-status {
                                padding-right: 5px;
                                float: right;
                            }

                            .booking-list-today {
                                float: left;
                                width: 100%;
                                text-align: center;
                                background: #f0fcff;
                                border-top: solid 1.5px #1E88E5;
                                color: #1E88E5;
                                line-height: 25px;
                                margin-top: 5px;
                                cursor: pointer;
                                .text-danger {
                                    font-weight: 700;
                                }
                            }
                        }
                    }

                    .waiting-time {
                        color: red;
                        font-size: 14px;
                        margin-top: 5px;
                    }

                    .just-now {
                        margin-top: 5px;
                        color: #4a90e2;
                        font-size: 12px;
                    }

                    .created_at {
                        margin-top: 5px;
                        span {
                            text-decoration: underline;
                            color: #4a90e2;
                            font-weight: 600;
                        }
                    } 

                    .title-block {
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 24px;
                        padding: 10px 15px;
                        border-bottom: 1px solid #d3dae7;
                    }

                    .body-col {
                        height: calc(100% - 50px);
                        overflow: auto;
                        width: 100%;
                        padding: 0 0 10px 10px;
                        max-height: calc(100% - 50px);

                        .booking-right-list {
                            width: calc(100% - 12px);
                            height: 100%;
                            position: relative;
                            .empty-booking {
                                position: absolute;
                                top: 50%;
                                width: 100%;
                                text-align: center;
                            }
                            .item-list-booking {
                                width: 100%;
                                display: inline-block;
                                .title-list {
                                    float: left;
                                    overflow: visible;
                                    padding-bottom: 10px;
                                    padding-left: 50px;
                                    padding-top: 10px;
                                    height: 100%;
                                    position: relative;
                                    width: 100%;

                                    &:before {
                                        background: #ddd;
                                        content: "";
                                        height: 100%;
                                        left: 19px;
                                        position: absolute;
                                        top: 0;
                                        width: 2px;
                                    }

                                    .alpha-grey {
                                        border-radius: 5px;
                                        font-weight: 700;
                                        margin-left: -50px;
                                        margin-top: 20px;
                                        margin-right: 0;
                                        padding: 5px 5px 5px 15px;
                                        position: relative;
                                        color: #4b4f56;
                                        background-color: #eee;
                                        i {
                                            margin-right: 7px;
                                        }
                                        &.cell-today {
                                            background-color: #03A9F4;
                                            color: #fff;
                                        }
                                        .total-booking {
                                            float: right;
                                            i {
                                                margin-left: 5px;
                                            }
                                        }
                                    }
                                }

                                .data-list {
                                    float: left;
                                    width: 100%;

                                    .event-no-time,
                                    .event-time {
                                        display: block;
                                        font-size: 12px;
                                        position: absolute;
                                        text-align: center;
                                        z-index: 2;
                                    }

                                    .event-seatinfo,
                                    .event-service {
                                        font-size: 10px;
                                        height: 12px;
                                        line-height: 12px;
                                        margin-bottom: 2px;
                                        max-width: 100%;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    .event-status, .group-item {
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    .booking_object {
                                        display: flex;
                                        flex-wrap: wrap;
                                        overflow: visible;
                                        padding-bottom: 10px;
                                        padding-left: 50px;
                                        padding-top: 10px;
                                        height: 100%;
                                        position: relative;
                                        width: 100%;
                                        .edit-booking {
                                            display: inline-block;
                                            width: 50%;
                                            cursor: pointer;
                                            .event-time {
                                                background: #eee;
                                                border-radius: 20px;
                                                height: 40px;
                                                left: 0;
                                                line-height: 40px;
                                                top: 20px;
                                                width: 40px;

                                                &.new-booking-effect {                                                   
                                                    border: 1px solid #F44336;
                                                    font-weight: 700;
                                                    background-color: #F44336 !important;
                                                    color: #fff;
                                                }

                                                .new {
                                                    font-size: 9px;
                                                    color: yellow;
                                                    position: absolute;
                                                    top: -13px;
                                                    left: 10px;
                                                }
                                            }
                                            .event-booking-code {
                                                font-size: 11px;
                                                color: #999;
                                            }
                                            .event-customer {
                                                .event-customer-name {
                                                    font-weight: 600;
                                                    color: #4a90e2;
                                                }
                                                .booking-customer-group {
                                                    font-size: 12px;
                                                    color: red;
                                                    padding-left: 3px;
                                                    font-weight: 500;
                                                    .fas.fa-users {
                                                        font-size: 11px;
                                                        padding-right: 2px;
                                                    }
                                                }
                                            }
                                        }
                                        .operator-booking {
                                            width: 22%;
                                            text-align: right;
                                            padding-right: 20px;
                                            float: right;
                                            .list-operator {
                                                cursor: pointer;
                                                .avatar {
                                                    // border: 1px solid #ddd;
                                                    border-radius: 50%;
                                                    // width: 35px;
                                                    // height: 35px;
                                                    cursor: pointer;
                                                    position: relative;
                                                    text-align: center;
                                                    line-height: 33px;
                                                    float: right;
                                                    margin-top: 5px;
                                                    .avatar-user {
                                                        float: right;
                                                        left: 0px;
                                                        margin-left: -20px;
                                                        
                                                    }
                                                    img {
                                                        width: 36px;
                                                        height: 36px;
                                                        object-fit: cover;
                                                        border-radius: 50%;
                                                    }
                                                }
                                                .tooltip-booking {
                                                    text-align: left;
                                                }
                                            }
                                        }
                                        .event-action {
                                            width: 28%;
                                            text-align: right;
                                            .action-change-status {
                                                display: inline-block;
                                                width: 100%;
                                                max-width: 130px;
                                                .select >div {
                                                    width: 100% !important;
                                                   
                                                }
                                            }
                                            .checkout {
                                                padding-top: 5px;
                                                color: #4a90e2;
                                                cursor: pointer;
                                            }
                                        }
                                        &:before {
                                            background: #ddd;
                                            content: "";
                                            height: 100%;
                                            left: 19px;
                                            position: absolute;
                                            top: 0;
                                            width: 2px;
                                            z-index: 1;
                                        }
                                        &.disable-booking {
                                            .event-time,
                                            .event-status .label {
                                                background-color: #ddd;
                                                border: 1px solid #ccc;
                                                color: #ccc;
                                            }
                                            .event-booking-code,
                                            .event-customer-name,
                                            .event-service,
                                            .event-status .label,
                                            .checkout,
                                            .select [class$="singleValue"] {  
                                                color: #ccc;
                                            }
                                        }
                                    }
                                }
                                .load-more {
                                    float: left;
                                    width: 100%;
                                    text-align: center;
                                    font-size: 14px;
                                    cursor: pointer;
                                    color: #4a90e2;
                                    margin-top: 10px;
                                    font-weight: 600;
                                }
                            }
                        }
                    }

                    .table_container {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        overflow: hidden;

                        .mix-time-mask {
                            position: absolute;
                            left: 0;
                            top: 0;
                            z-index: 6;
                            height: 36px;
                            width: 140px;
                            background: #f0fcff;
                            border-radius: 3px;
                            border-right: solid 1px #dbe4eb;
                            border-bottom: solid 1px #dbe4eb;
                            text-align: center;

                            .title {
                                padding: 8px 5px;
                                line-height: 20px;
                                width: 100%;
                                vertical-align: text-bottom;
                            }
                        }

                        .table-wrapper {
                            width: 100%;
                            height: 100%;
                            position: relative;
                            display: inline-block;
                            overflow: auto;
                            -webkit-overflow-scrolling: touch;

                            .fixed-row-heading {
                                position: sticky;
                                padding-left: 140px;
                                white-space: nowrap;
                                z-index: 5;
                                line-height: 0;
                                vertical-align: text-top;
                                width: auto;
                                top: 0px;

                                .table-col {
                                    width: 140px;
                                    height: 36px;
                                    text-align: center;
                                    line-height: 36px;
                                    font-weight: 700;
                                    background: #f0fcff;
                                    vertical-align: text-top;
                                    display: inline-block;
                                    border-right: solid 1px #dbe4eb;
                                    overflow: visible;

                                    &.cell-today {
                                        background: #03A9F4;
                                        color: #fff;
                                    }
                                }
                            }

                            .fixed-col-heading {
                                height: auto;
                                position: sticky;
                                left: 0;
                                width: 140px;
                                z-index: 5;

                                .table-col {
                                    vertical-align: text-top;
                                    display: inline-block;
                                    border-right: solid 1px #dbe4eb;
                                    height: 154px;
                                    background-color: #f0fcff;
                                    text-align: center;
                                    width: 100%;
                                    overflow: hidden;
                                    line-height: 1px;
                                    border-bottom: solid 1px #dbe4eb;

                                    &.calendar-big-cell {
                                        height: 209px;
                                    }

                                    .avatar {
                                        border-radius: 50%;
                                        background-size: cover;
                                        clear: both;
                                        display: block;
                                        text-align: center;
                                        margin: 10px auto 0 !important;
                                        padding: 0;
                                        border: 1px solid #d6e0ef;
                                        width: 36px;
                                        height: 36px;
                                        img {
                                            width: 36px;
                                            object-fit: cover;
                                            height: 36px;
                                            border-radius: 50%;
                                        }
                                    }

                                    .staff-name {
                                        clear: both;
                                        color: #1E88E5;
                                        height: 20px;
                                        line-height: 20px;
                                        width: 100%;
                                        margin: 10px 0 0;
                                        padding: 0 10px;
                                        text-transform: uppercase;
                                        overflow: hidden;
                                        word-wrap: break-word;
                                        word-break: break-word;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        display: block;
                                        max-height: none;
                                    }

                                    .staff-title {
                                        text-align: center;
                                        display: block;
                                        clear: both;
                                        line-height: 12px;
                                    }
                                }
                            }

                            .table-content {
                                cursor: pointer;
                                position: absolute;
                                top: 36px;
                                left: 140px;

                                .table-row {
                                    background: #fff;
                                    clear: both;
                                    line-height: 1px;
                                    height: 155px;
                                    text-overflow: ellipsis;
                                    padding: 0;
                                    margin: 0;
                                    white-space: nowrap;
                                    float: left;

                                    &:first-child {
                                        height: 210px;
                                    }

                                    .table-col {
                                        background: #fff;
                                        display: inline-block;
                                        border-right: solid 1px #dbe4eb;
                                        line-height: 20px;
                                        overflow: visible;
                                        vertical-align: text-top;
                                        width: 140px;
                                        height: 155px;
                                        border-bottom: solid 1px #dbe4eb;
                                        position: relative;
                                        &.calendar-big-cell {
                                            height: 210px;

                                            .show-more-tasks {
                                                padding: 0 10px;
                                            }
                                        }

                                        &:hover {
                                            background-color: #f4fdff;
                                            cursor: pointer;
                                        }
                                        
                                        .show-more-tasks {
                                            color: #4a90e2;
                                            padding: 5px 10px;
                                            position: relative;
                                            z-index: 4;
                                            > span {
                                                display: block;
                                            }
                                            &:hover {
                                                color: #166dba;
                                            }
                                        }
                                        .bg-day {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            right: 0;
                                            bottom: 0;
                                            z-index: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .label {
        font-size: smaller;
        line-height: 12px;
        text-transform: none;
        padding: 1px 5px;
        display: inline-block;
        font-weight: 500;
        border: 1px solid transparent;
        border-radius: 2px;
        margin: 0 2px;
        &.type_booking {
            color: #fff;
            min-width: 30px;
            text-align: center;
            font-size: 12px;
            margin: 0 5px;
            padding: 0 2px;
        }
    }
    .fas.fa-chevron-circle-down.active {
        transform: rotate(180deg);
    }
    .booking_task {
        // height: 44px;
        height: 60px;
        text-transform: uppercase;
        display: block;
        // overflow: hidden;
        cursor: pointer;
        border-radius: 3px;
        text-indent: 10px;
        z-index: 3;
        line-height: 18px;
        margin: 0 2px 2px 0;
        padding: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 11px;
        word-break: break-all;
        border-right: solid 1px #dbe4eb;
        position: relative;
        padding-top: 3px;
        &:before {
            content: "";
            bottom: 0;
            width: 4px;
            top: 0;
            left: 0;
            position: absolute;
            display: block;
        }

        &.bg-1 {
            background-color: #fff1da;
            color: rgba(255,152,1,1);
            border: 1px solid #fff;

            &:before {
                background-color: rgba(255,152,1,1);
            }
        }

        &.bg-2 {
            background-color: #d9f2fe;
            color: rgba(3, 169, 244, 1);
            border: 1px solid #fff;

            &:before {
                background-color: rgba(3, 169, 244, 1);
            }
        }

        &.bg-3 {
            background-color: #fee6df;
            color: rgba(244,81,30,1);
            border: 1px solid #fff;

            &:before {
                background-color: rgba(244,81,30,1);
            }
        }

        &.bg-4 {
            background-color: #eef7e3;
            color: rgba(139,195,74,1);
            border: 1px solid #fff;

            &:before {
                background-color: rgba(139,195,74,1);
            }
        }

        &.bg-5, &.bg-6 {
            background-color: #f7e0e0;
            color: rgba(198, 40, 40, 1);
            border: 1px solid #fff;

            .task-booking-customer {
                font-style: italic;
                text-decoration: line-through;
            }

            &:before {
                background-color: rgba(198, 40, 40, 1);
            }
        }

        &.bg-7 {
            background-color: #dff3f1;
            color: rgba(38,166,154,1);
            border: 1px solid #fff;

            &:before {
                background-color: rgba(38,166,154,1);
            }
        }

        .task-booking-product,
        .task-booking-time,
        .ranking {
            line-height: 12px;
            text-transform: none;
            clear: both;
            float: left;
            font-size: 10px;
        }

        .task-booking-customer,
        .task-booking-guest {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 16px;
        }

        .task-booking-customer {
            font-weight: 700;
            max-width: 100%;
            float: left;
        }
        .booking-customer-group {
            position: absolute;
            right: 3px;
            color: red;
            font-size:10px;
        }

        .task-booking-time {
            display: block;
            color: #12a700;
            width: 40px;
        }

        .ranking {
            color: #999;
        }

        .task-booking-product {
            max-width: calc(100% - 5px);
            color: #999;
            text-align: left;
            padding-right: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .list-more-booking {
        position: absolute;
        z-index: 9;
        border-radius: 5px;
        padding: 5px;
        background: #fff;
        box-shadow: 0 0 15px rgb(0 0 0 / 25%);
        min-height: 40px;
        max-height: 400px;
        float: left;
        clear: none;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 175px;
        &.column-2 {
            width: 320px;
        }

        &.column-3 {
            width: 460px;
        }
        .button-close {
            position: absolute;
            right: 5px;
            top: 5px;
            color: #000;
            font-size: 18px;
            z-index: 9;
            padding: 0 5px;
            cursor: pointer;
        }
        .booking_task {
            width: 140px;
            float: left;
        }
    }

    @media (max-width: 1199px) {
        .popup-cent {

           .content-popup {
               width: 90%;
           }
       }
    }

    @media (max-width: 768px) {
        .admin-body .dashboard-content .wrapper {
            position: relative;
            left: auto;
            right: auto;
            top: 135px;
            .fixed-column.panel-order {
                position: relative;
                left: auto;
                right: auto;
                border: none;
                width: 100%;
                .body-col .booking-right-list .item-list-booking .data-list .booking_object  {
                    .edit-booking {
                        width: 75%;
                    }
                    .operator-booking {
                        padding: 0;
                        width: 25%;
                    }
                }
            }
        }
        .popup-cent {
             > .row {
                margin: 0 -10px 10px;
                > div {
                    padding: 0 10px;
                }
            }
            .content-popup {
                width: 95%;
            }
            .item-sidebar {
                padding-left: 0;
                .value-sidebar {
                    display: inline-block;
                    width: 100%;
                    .item {
                        width: calc(25% - 10px);
                        float: left;
                        margin: 5px;
                        border-radius: 3px;
                        border: 1px solid #d3dae7;
                    }
                }
                &.status .value-sidebar .item:not(:last-child) {
                    border-bottom: 1px solid #d3dae7;
                }
                &.source .value-sidebar {
                    border: none;
                }
            }
            .custom-date-picker {
                position: relative;
                top: -5px;
            }
            .count-time {
                display: block;
                position: absolute;
                top: 15px;
            }
        }
        .page-title {
            position: fixed;
            border-bottom: 1px solid #d3dae7;
            z-index: 99;
            .title-page {
                width: 100%;
                .button-cent {
                    float: right;
                }
            } 
            .header-right {
                margin-top: 5px;
                display: inline-block;
                width: 100%;
                .rs-picker-daterange {
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 10px;
                    float: left;
                    width: 100%;
                }
            }
        }
        .select {
            top: 3px;
        }
    }
    
    @media (max-width: 767px) {
        .admin-body {
            .dashboard-content {
                .wrapper {
                    .mg-10 {
                        margin: 10px 0;
                    }
                    .loyalty_point {
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                    }
                    .fixed-column.panel-order .body-col .booking-right-list .item-list-booking {
                        .title-list .alpha-grey {
                            padding: 5px;
                            font-size: 10px;
                        }
                        .data-list {
                            .booking_object .event-action {
                                width: 100%;
                                text-align: left;
                                margin-top: 10px;
                                .checkout {
                                    display: inline-block;
                                    margin-left: 15px;
                                }
                            }
                            .mt-2 .custom-table {
                                padding: 0 !important;
                            }
                        }
                    } 
                }
            }
        } 

        .popup-cent {
            .content-popup {
                .head-popup {
                    .title > .text {
                        display: block;
                    }
                    .more-info {
                        margin-left: 0;
                        margin-right: 10px;
                    }
                }
                .body-popup {
                    .items-data {
                        .custom-date-picker {
                            margin-top: 10px;
                        }
                        .title {
                            line-height: 20px;
                        }
                        .md-10 {
                            margin: 10px 0;
                        }
                    }
                }
            }
            .booking-type {
                margin-bottom: 10px;
            }
            .item-sidebar .value-sidebar .item {
                width: calc(50% - 10px);
            }
            .add-more {
                position: unset;
                margin-top: 5px;
            }
        }
    }

    .body-popup-export-booking {
        padding-bottom: 20px;
        .popup-export-booking {
            .order-filter-block {
                li {
                    list-style: none;
                    padding-top: 10px;
                    float: left;
                    padding-right: 10px;
                    ::placeholder {
                        font-size: 12px;
                      }

                    .date-range-picker-order-at {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
    .voucher-list {
        position: fixed;
        top: 20%;
        left: 25%;
        max-height: 500px;
        border: 1px solid #ddd;
        padding: 10px;
        border-radius: 5px;
        width: 700px;
        background: #FFF;
        box-shadow: 0 0 45px rgba(0, 0, 0, 0.25);
        z-index: 9999;
        overflow-y: auto;
        padding-top: 0px;
        .head-popup {
            padding-top: 10px;
            padding-bottom: 20px;
        }
        .body-popup {
            height: auto;
            .grid-two-column{
                height: 53px;
                .product-image {
                    height: 50px;
                    width: 50px;
                }
                .pull-left{
                    font-size: 10px;
                    .product-name {
                        font-size: 10px;
                        height: 20px;
                    }
                    .pull-left {
                        font-size: 10px;
                    }
                }
            }
            // .grid-two-column.active{
            //     background: #f79800 !important
            // }
            .input-action-voucher {
                display: flex;
                input {
                    margin-right: 10px;
                }
                button {
                    padding: 8px;
                    width: 36px;
                    height: 36px;
                }
            }

            .product-grid {
                margin-top: 10px;
                .title-list {
                    margin-bottom: 10px;
                    color: dodgerblue;
                    font-weight: bold;
                }
                .disable-package2 {
                    display: none;
                }
                .close {
                    right: 5px;
                    top: -2px;
                    color: white;
                }
                .active {
                    .close {
                        right: 5px;
                        top: -2px;
                        color:#C3C3C3 ;
                    }
                }
            }
        }
        .content-popup.fadeInDown {
            width: 100% !important;
            margin: 10px auto;
        }
        .overlay {
            background: none;
        }
    }
    .openTypeAvatar {
        position: fixed;
        top: 20%;
        left: 25%;
        max-height: 250px;
        border: 1px solid #ddd;
        padding: 10px;
        border-radius: 5px;
        width: 700px;
        background: #FFF;
        box-shadow: 0 0 45px rgba(0, 0, 0, 0.25);
        z-index: 9999;
        overflow-y: auto;
        padding-top: 0px;
        .content-popup.fadeInDown {
            width: 100% !important;
            margin: 10px auto;
        }
        .overlay {
            background: none;
        }
    }
    .voucher-list.avatar-list {
        left: 432px;
    }

    .avatar-view {
        z-index: 9999;
        position: absolute;
        top: 5%;
        left: 160px;
        width: 262px;
        height: 306px;
        border: 1px solid #ddd;
        padding: 30px;
        border-radius: 5px;
        box-shadow: 0 0 45px rgba(0, 0, 0, 0.25);
        background: #FFF;
        
        .title-avatar {
            color: dodgerblue;
            padding-bottom: 10px;
            font-weight: 500;
        }
        .avatar {
            img {
                width: 200px;
                height: 200px;
            }
        }
    }

    .avatar-customer-grid>li {
        list-style: none;
        float: left;
        margin: 0 10px 10px 1px;
        position: relative;
        width: calc(13% - 11px);
        cursor: pointer;
        height: 90px;
        width: 90px;
        padding: 0;
        border-radius: 6px;
        .avatar {
            width: 90px;
            height: 90px;
            img {
                width: 90px;
                height: 90px;
                object-fit: cover;
                border: rgba(52,152,255,.25) 4px solid;
            }
        }
        .close {
            right: 5px;
            top: -3px;
        }
    }

    .avatar-customer-grid {
        .title-list {
            margin-bottom: 10px;
            color: dodgerblue;
            font-weight: bold;
        }
        .grid-two-column-avatar.active {
            .avatar {
                img {
                    //border-radius: 50% !important;
                }
            }
        }
    }

    .bottom-popup-save-avatar {
        position: sticky;
        bottom: 0;
    }

    .popup-cent {
        &.payment {
            color: #5e6d8c;

            .content-popup {
                width: 900px;
            }

            .account-payment {
                padding-bottom: 10px;
                font-size: 12px;
            }

            .payment-block {
                display: inline-block;
                width: 100%;

                .left-block {
                    float: left;
                }

                .right-block {
                    float: right;
                }
            }

            .total-price,
            .money-back-block {
                .title-block {
                    font-size: 20px;
                    line-height: 40px;
                }

                .price-block {
                    line-height: 40px;
                    font-size: 32px;
                    color: #2196F3;
                }
            }

            .payment-method {
                margin-top: 10px;

                .title-block {
                    font-size: 14px;
                    margin-bottom: 10px;
                }

                .body-block {
                    .row {
                        margin: 0 -5px;

                        .btn {
                            margin-bottom: 10px;

                            &.btn-primary {}

                            &.btn-default {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .cash-block {
                margin-bottom: 10px;

                .title-block {
                    font-size: 14px;
                    line-height: 40px;
                }

                .cash-customer {
                    width: 60%;
                    text-align: right;
                    position: relative;

                    .currency {
                        position: absolute;
                        top: 7px;
                        right: 7px;
                        font-size: 16px;
                    }

                    input {
                        width: 100%;
                        height: 32px;
                        padding-left: 10px;
                        padding-right: 25px;
                        border: 1px solid rgb(211, 218, 231);
                        border-radius: 3px;
                        text-align: right;
                    }
                }
            }

            .note-transaction {
                border-top: solid 1px #d3dae7;
                padding-top: 10px;

                .note-block {
                    textarea {
                        width: 100%;
                        border: 1px solid rgb(211, 218, 231);
                        border-radius: 3px;
                        margin-top: 10px;
                        height: 80px;
                        padding: 5px 10px;
                    }
                }
            }

            .owed-block {
                color: rgb(244, 67, 54);

                .money-owed {
                    font-size: 16px;
                }
            }
        }

        &.update-cart-item {
            .content-popup {
                width: 700px;
            }

            .order-item-details {
                display: inline-block;
                width: 100%;
                border-bottom: solid 1px #d6e0ef;
                padding-bottom: 15px;
                margin-bottom: 10px;

                .order-item-image {
                    width: 80px;
                    height: 80px;
                    float: left;
                    border: 1px solid #ddd;
                }

                .info {
                    float: left;
                    margin-left: 20px;

                    .order-item-name {
                        font-weight: 700;
                        font-size: 16px;
                        color: #5e6d8c;
                    }

                    .order-code {
                        color: #2196F3;
                        font-size: 12px;
                    }
                }

                .cash-vnd {
                    font-weight: 700;
                    font-size: 26px;
                    float: right;
                    color: #259B9A;
                    position: relative;
                    white-space: nowrap;

                    .show-discount {
                        font-size: 12px !important;
                        text-decoration: line-through;
                        color: rgb(119, 119, 119) !important;
                    }
                }
            }

            .list-change {
                .item-input {
                    width: 100%;
                    display: inline-block;
                    padding: 5px;

                    .label {
                        color: #5e6d8c;
                        font-size: 12px;
                        width: 120px;
                        float: left;
                        line-height: 32px;
                    }

                    .value {
                        width: 160px;
                        position: relative;
                        float: left;
                        font-size: 12px;

                        input {
                            width: 100%;
                            height: 32px;
                            padding-left: 10px;
                            padding-right: 25px;
                            border: 1px solid rgb(211, 218, 231);
                            border-radius: 3px;
                            text-align: right;
                            font-size: 12px;
                        }

                        .currency {
                            position: absolute;
                            top: 7px;
                            right: 7px;
                            font-size: 16px;
                        }
                    }

                    .select-quantit {
                        width: 115px !important;
                    }

                    .set-max-used-package {
                        text-align: end;

                        .label {
                            width: 210px;
                        }

                        .value {
                            width: 100px;
                            margin-left: 10px;

                            input {
                                padding-right: 0px;
                            }
                        }
                    }

                    .value.node-cart-coc {
                        width: 360px;

                        .form-control {
                            height: 70px;
                        }
                    }
                }

                .discount-show {
                    .value-show-discount {
                        margin-left: 120px;

                        .input-discount-show {
                            width: 100%;
                            height: 32px;
                            padding-left: 10px;

                            // padding-right: 25px;
                            border: 1px solid rgb(211, 218, 231);
                            border-radius: 3px;
                            text-align: right;
                            font-size: 12px;
                        }

                        .checkbox-all-item {
                            padding-left: 10px;
                            padding-top: 8px;

                            input {
                                margin-right: 10px;
                                width: 15px;
                                height: 15px;
                            }

                            label {
                                font-size: 12px;
                            }
                        }
                    }
                }

                .wraper-source-search {
                    display: flex;
                    font-size: 12px;

                    .source-customer-search {
                        position: relative;

                        .select-option-type-user {
                            width: 160px;
                        }

                        .search-show-user {
                            width: 230px;

                            input {
                                width: 100%;
                                width: 100%;
                                height: 38px;
                                padding-left: 10px;
                                padding-right: 25px;
                                border: 1px solid rgb(211, 218, 231);
                                border-radius: 3px;
                                font-size: 12px;
                            }

                            .result-search {
                                width: 100%;
                            }
                        }

                        .refresh-button {
                            padding-left: 10px;

                            button {
                                height: 35px;
                            }
                        }
                    }

                    .label-source {
                        width: 120px;
                        line-height: 35px;
                    }
                }

                .wraper-show-data {
                    .value-show-discount {
                        font-size: 12px;
                        padding-left: 120px;

                        .input-group {
                            width: 160px;
                            padding-right: 10px;

                            input {
                                width: 100%;
                                height: 32px;
                                padding-left: 10px;
                                padding-right: 25px;
                                border: 1px solid rgb(211, 218, 231);
                                border-radius: 3px;
                            }

                            .height-38 {
                                height: 38px !important;
                            }

                            .input-discount-money {
                                border-radius: 3px 0px 0px 3px;
                                padding-right: 5px !important;
                                text-align: end;

                                // width: 110px;

                            }
                        }

                        .input-consultant {
                            padding-right: 0px !important;

                            .input-discount-mone {
                                text-align: end;
                            }
                        }

                        .select-type-consultant {
                            width: 65px;
                        }
                    }
                }
            }

            .wraper-introductory-person {
                border-top: solid 1px #d6e0ef;
                padding-top: 20px;

                .validation-error-label {
                    padding-left: 125px;
                    color: red;
                    font-size: 11px;

                    :before {
                        content: '\ed63';
                        position: absolute;
                        top: 2px;
                        left: 0;
                        display: inline-block;
                        line-height: 1;
                        font-size: 16px;
                    }
                }
            }

            .bottom-popup {
                border-top: solid 1px #d6e0ef;
            }
        }
    }
}
.ant-message {
    z-index: 9999999;
}
.success-full{
    text-decoration: underline;
    color: red;
}
@media (max-width: 768px) {
    .admin-page.booking .popup-cent .row {
        align-items: normal;
    }
    .rs-picker-menu.placement-bottom-start {
        .rs-picker-toolbar-ranges {
            .rs-btn-link {
                float: left;
                font-size: 12px;
                text-align: left;
            }
        }   
    }
}
.custom-date-picker {
    > .rs-picker-toggle {
        height: 32px;
    }
}
.rs-picker-menu {
    z-index: 1060;
    .rs-calendar {
        width: 50%;
    }
}

.text-grey, .text-grey:focus, .text-grey:hover {
    color: #777;
}

.text-danger, .text-danger:focus, .text-danger:hover {
    color: #F44336;
}

.ml-20 {
    margin-left: 20px !important;
}

.status-1 {
    background-color: #FF9800;
    border-color: #FF9800;
    color: #fff;
}

.status-2 {
    background-color: #03A9F4;
    border-color: #03A9F4;
    color: #fff;
}

.status-3 {
    background-color: #F4511E;
    border-color: #F4511E;
    color: #fff;
}

.status-4 {
    background-color: #8BC34A;
    border-color: #8BC34A;
    color: #fff;
}

.status-5 {
    background-color: #C62828;
    border-color: #C62828;
    color: #fff;
}

.status-6 {
    background-color: #E53935;
    border-color: #E53935;
    color: #fff;
}

.status-7 {
    background-color: #26A69A;
    border-color: #26A69A;
    color: #fff;
}

.select.booking {
    [class$="-menu"] {  
        margin: 0;
        z-index: 999;
        [class$="-MenuList"], [class$="-Group"], [class$="-group"] {
            padding: 0;
            [class$="-option"] {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
    .custom-head {
        padding: 0 15px;
        background: #D35B34;
        color: #fff;
        position: relative;
        .custom-label {
            font-size: 14px; 
        }
        .custom-count {
            border-radius: 50%;
            color: #fff;
            font-size: 10px;
            text-align: center;
            border: 1px solid #fff;
            width: 22px;
            height: 22px;
            line-height: 20px;
            float: right;
            text-align: center;
            position: absolute;
            top: calc(50% - 11px);
            right: 15px;
        }
    }

    &.service {
        [class$="-menu"] {
            [class$="-MenuList"], [class$="-Group"], [class$="-group"] {
                [id*="option-0"] {
                    position: relative;
                    padding-left: 40px;
                    &:before {
                        content: "\f2c2";
                        font-family: "Font Awesome 6 Free";
                        font-weight: 900;
                        margin-right: 12px;
                        margin-left: 2px;
                        font-size: 20px;
                        overflow: hidden;
                        color: #FF9800;
                        position: absolute;
                        top: 0px;
                        left: 5px;
                    }
                }
            }
        }
    }
}

.add-booking.popup-cent {
    .content-popup .body-popup .items-data .value textarea {
        height: 100px;
    }
    @media(min-width: 1200px) {
        .content-popup {
            width: 1100px !important
        }
    }

    .head-popup {
        .more-info {
            display: inline-block;
            color: #999;
            font-size: 10px;
            margin-left: 20px;
            font-style: italic;
            span {
                color: #2196F3;
            }
        }
    }
    .row {
        margin: 0 -5px 10px;
        align-items: normal;
        > div {
            padding: 0 5px;
        }
    }
    .item-more-customer {
        .service, .user {
            p {
                line-height: 1;
            }
        }
    }
    // .select {
    //     top: 5px;
    // }
    .bold {
        font-weight: 700;
    }
    .add-more {
        color: #4a90e2;
        cursor: pointer;
        font-size: 12px;
        position: absolute;
        bottom: -10px;
        line-height: 1;
        i {
            margin-right: 3px;
        }
    }
    .count-time {
        font-size: 10px;
        color: #777;
        &.active {
            color: #8BC34A
        }
    }
    .booking-type {
        display: flex;
        &.disable {
            opacity: .5;
        }
        .item-type {
            width: 50%;
            text-align: center;
            border: 1px solid #d3dae7;
            border-left: none;
            color: #5e6d8c;
            height: 32px;
            line-height: 32px;
            cursor: pointer;
            &.active {
                color: #fff;
                background-color: #4a90e2;
                border-color: #4a90e2;
            }
            &:first-child {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                border-left: 1px solid #d3dae7;
            }
            &:last-child {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }
    .item-sidebar {
        margin-bottom: 30px;
        padding-left: 5px;
        .label-sidebar {
            padding-bottom: 7px;
            color: #5e6d8c;
            font-size: 12px;
            font-weight: 700;
        }
        .disable {
            opacity: 0.5;
        }
        .value-sidebar {
            .item {
                padding: 0 12px;
                color: #5e6d8c;
                height: 36px;
                line-height: 36px;
                cursor: pointer;
                font-size: 12px;
                &:first-child {
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;
                }
                &:last-child {
                    border-bottom-left-radius: 3px;
                    border-bottom-right-radius: 3px;
                }
                &.active {
                    color: #fff;
                    background-color: #4a90e2;
                    border-color: #4a90e2;
                }
            }
        }
        &.status {
            .value-sidebar {
                .item {
                    border: 1px solid #d3dae7;
                    &:not(:last-child) {
                        border-bottom: none;
                    }
                }
            }
        }
        &.source {
            .value-sidebar {
                border: 1px solid #d3dae7;
                max-height: 320px;
                border-radius: 3px;
                .item {
                    text-align: center;
                    &:not(:last-child) {
                        border-bottom: 1px solid #d3dae7;
                    }
                }
            }
        }
        .small-circle {
            border-radius: 8px;
            display: inline-block;
            height: 16px;
            margin-bottom: -3px;
            margin-right: 10px;
            width: 16px;
        }
    }
    .result-search {
        position: absolute;
        z-index: 6;
        background: #fff;
        width: calc(100% - 10px);
        border: 1px solid #d3dae7;
        border-radius: 3px;
        .empty-customer {
            line-height: 1;
            padding: 10px;
            font-size: 16px;
        }
        .items-customer {
            height: 350px;
            overflow: hidden;
            overflow-y: scroll;
            .item {
                display: flex;
                cursor: pointer;
                padding: 10px;
                .avatar {
                    float: left;
                    img {
                        border-radius: 50%;
                        width: 36px;
                        height: 38px;
                        object-fit: cover;
                    }
                }
                .info {
                    float: left;
                    padding-left: 10px;
                    width: calc(100% - 36px);
                    > div {
                        line-height: 1;
                    }
                    .name {
                        text-transform: uppercase;
                        border-radius: 3px;
                        background: red;
                        color: #fff;
                        display: inline-block;
                        padding: 3px;
                        position: relative;
                        top: -5px;
                    }
                    .phone {
                        position: relative;
                        top: -7px;      
                        color: #999;
                        font-size: 10px;              
                    }
                    .ranking {
                        position: relative;
                        top: -5px;
                        margin-left: 5px;
                        font-size: 10px;
                    }
                    .address {
                        color: #999;
                        font-size: 10px;    
                    }
                }
                &:not(:last-child) {
                    border-bottom: 1px solid #d3dae7;

                }
                &:hover {
                    background: #f6fdff;
                }
            }
        }
    }
    .old-customer {
        .row {
            align-items: flex-start;
            padding-top: 10px;
        }
        .avatar {
            border-radius: 50%;
            position: relative;
            line-height: 60px;
            height: 100%;
            cursor: pointer;
            // text-align: center;
            img {
                object-fit: cover;
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }
        }
        .info-customer {
            line-height: 1;
            .top-info {
                margin-bottom: 10px;
            }
        }
        .gender {
            color: rgb(33, 150, 243);
            font-size: 12px;
            margin-right: 2px;
        }
        .name {
            font-size: 14px;
            font-weight: 700;
            margin: 0;
            color: #2196F3;
            text-transform: uppercase;
        }
        .button-edit {
            border-radius: 2px;
            background: #2196F3;
            color: #FFF;
            margin-left: 5px;
            padding: 3px;
            cursor: pointer;
        }
        .button-create-new {
            margin-left: 10px;
            color: #2196F3;
            text-decoration: underline;
            cursor: pointer;
            i {
                margin-right: 5px;
            }
        }
        .ranking {
            margin-top: 15px;
        }
        .phone, .address, .ranking {
            line-height: 1;
            color: #5e6d8c;
            font-size: 12px;
            margin: 8px 0;
            i {
                margin-right: 10px;
                font-size: 13px;
            }
        }
        b {
            margin-right: 10px;
            font-size: 13px;
        }
        .note {
            border: 1px solid #5e6d8c;
            line-height: 16px;
            display: inline-block;
            padding: 5px;
            border-radius: 3px;
        }
        i {
            font-size: 10px;
        }
    }
}

.label-booking {
    color: red;
    font-weight: 600;
    position: absolute;
    right: 5px;
    top: 1px;
    z-index: 2;
}

.label-type_booking {
    color: #00a6ff;
    font-weight: 600;
    position: absolute;
    right: 5px;
    top: 18px;
    z-index: 2;
}

.d-flexs {
    display: flex;
}
