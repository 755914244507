
.background{
    background-color: #e6eef3;
    width: 100%;
    margin: 0px;
}
.background2{
    background-color: rgb(246, 246, 249);
}
.buttonClear{
    border: 1px solid #bfbfbf;
    background-color: white;
    height: auto;
}
.fixedPagination{
    position: sticky;
    bottom: 10px;
    padding-right: 5%;
    background: white;
    z-index: 1;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.tagStatus span {
    color: #fff;
    border-radius: 4px;
    padding: 2px 4px;
}

.listPayment {
    list-style: none;
    padding: 0;
}

.listService {
    padding-left: 20px;
    margin-bottom: 10px;
}

.listService label {
    margin-bottom: 3px;
    font-weight: 700;
    text-transform: uppercase;
}

.listNew label {
    color: #ff3232;
}

p+p {
    margin-top: 3px;
}