.admin-page {
    background: #e6eef3;
    .admin-body {
        padding: 15px;
        .dashboard-content {
            border: 1px solid #d3dae7;
            background: #fff;
            padding: 15px 15px 0; 
            .wrapper {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin: 0 -5px;
                .dashboard-main-button a {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                }
                a {
                    color: #fff;
                }
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
                .dashboard-main-button {
                    width: calc(100%/3 - 10px);
                    margin: 0 5px;
                    color: #fff;
                    height: 120px;
                    position: relative;
                    overflow: hidden;
                    .icon-bg {
                        position: absolute;
                        top: -50px;
                        right: -50px;
                        width: 100px;
                        height: 100px;
                        -ms-transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                    .title {
                        position: absolute;
                        top: 0;
                        left: 10px;
                        font-size: 23px;
                        font-weight: 700;
                    }
                    i {
                        position: absolute;
                        top: 5px;
                        right: 5px;
                        font-size: 25px;
                        z-index: 99;
                    }
                    .report {
                        width: 100%;
                        bottom: 20px;
                        position: absolute;
                        left: 0;
                        .value {
                            width: 50%;
                            float: left;
                            text-align: center;
                            .count {
                                font-size: 23px;
                                font-weight: 700;
                            }
                            .note {
                                font-size: 12px;
                            }
                        }
                    }
                    &:first-child {
                        background: #f2480c;
                        .icon-bg {
                            background: #ca3c0a;
                        }
                    }
                    &:nth-child(2) {
                        background: #26A69A;
                        .icon-bg {
                            background: #00897B;
                        }
                    }
                    &:last-child {
                        background: #FFA726;
                        .icon-bg {
                            background: #FB8C00;
                        }
                    }
                    &:hover {
                        -webkit-filter: contrast(120%);
                        filter: contrast(120%);
                    }
                }
                .dashboard-report-button {
                    background: #AB47BC;
                    color: #fff;
                    height: 120px;
                    width: calc(100%/3 - 10px);
                    margin: 0 5px;
                    .icon {
                        width: 30%;
                        height: 120px;
                        text-align: center;
                        float: left;
                        line-height: 120px;
                        font-size: 35px;
                        vertical-align: middle;
                    }
                    .title {
                        width: 70%;
                        height: 120px;
                        font-weight: 700;
                        text-align: center;
                        line-height: 120px;
                        float: right;
                    }
                }
                .dashboard-setting-button {
                    width: calc(100%/6 - 10px);
                    height: 120px;
                    float: left;
                    margin: 0 5px 20px 5px;
                    background: #9CCC65;
                    .title {
                        width: 100%;
                        height: 20px;
                        font-weight: 700;
                        text-align: center;
                        line-height: 20px;
                        background: #558B2F;
                        font-size: 12px;
                    }
                    .icon {
                        width: 100%;
                        height: 100px;
                        text-align: center;
                        line-height: 100px;
                        background: #7CB342;
                        text-align: center;
                        font-size: 40px;
                        line-height: 100px;
                    }
                }
            }
        }
    }
    @media (max-width: 1199px) {
        .admin-body {
            .dashboard-content .wrapper {
                .dashboard-main-button, .dashboard-report-button {
                    width: calc(50% - 10px);
                    margin-bottom: 10px;
                }
                .main-customer {
                    width: 100%;
                }
                .dashboard-setting-button {
                    width: calc(33.3333% - 10px);
                }
                .empty {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 768px) {
        padding-top: 48px;
        width: 100%;
    }
    @media (max-width: 767px) {
        .admin-body {
            .dashboard-content .wrapper {
                .dashboard-main-button, .dashboard-report-button {
                    width: 100%;
                }

                .dashboard-setting-button {
                    width: calc(50% - 10px);
                    
                }
            }
        }
    }
}