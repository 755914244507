.radio-button {
    margin: 0 50px 0 0;

    input[type=radio] {
        margin-right: 10px;
        position: relative;
        top: 2px;
    }
}

.control-label {
    text-transform: uppercase;
    margin-top: 0px;
    font-size: 12px;
}

.panel.panel-flat {
    color: #5e6d8c;
    border: 1px solid #d3dae7 !important;
    margin-bottom: 20px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);

    .panel-body {
        padding: 0 10px 10px;
        position: relative;

        .show-head {
            align-items: unset;
        }

        .col-3 {
            .customer-name {
                text-transform: uppercase;
                font-weight: bold;
            }
        }

        .col-5 {
            .package-order {
                text-transform: uppercase;
                font-weight: bold;
                color: #9C27B0;
            }

            .tag {
                border-radius: 3px;
                color: #fff;
                display: inline-block;
                margin: 5px 5px 5px 0;
                padding: 0 5px;
            }
        }
    }
}

.w-700 {
    font-weight: 700;
}

.bg-green {
    background-color: #0288D1;
}

.bg-red {
    background-color: #D32F2F;
}

.bg-blue {
    background-color: #0288D1;
}

.cl-red {
    color: #D32F2F;
}

.cl-blue {
    color: #0288D1;
}

.cl-green {
    color: #259B9A;
}

.cl-green2 {
    color: #8BC34A;
}

.cl-bold {
    font-weight: 700;
}

.cl-none-bold {
    font-weight: 300;
}

.cl-violet {
    color: #673AB7;
}

.cl-grey {
    color: #777 !important;
    text-decoration: line-through;

}

.posion-wraper {
    position: relative;

    .left-view {
        position: absolute;
        right: 10px;
        top: 0px;
    }
}

.order-status {
    clear: both;
    color: #999;
    font-size: 12px;
    position: absolute;
    right: 10px;
    top: 15px;
    padding-left: 20px;

    span {
        //background-color: #8BC34A !important;
        border-color: #8BC34A;
        font-size: smaller;
        line-height: 10px;
        text-transform: none;
        padding: 2px 5px 1px;
        font-weight: 500;
        color: #fff;
    }
}

.order-popup {
    top: 0;

    .head-popup {
        .order-status {
            clear: both;
            color: #999;
            font-size: 12px;
            position: absolute;
            right: 10px;
            top: 15px;
            padding-left: 20px;

            span {
                // background-color: #8BC34A !important;
                // border-color: #8BC34A;
                font-size: smaller;
                line-height: 10px;
                text-transform: none;
                padding: 2px 5px 1px;
                font-weight: 500;
                color: #fff;
            }
        }
    }

    .body-popup {
        .customer {
            .customer-avatar {
                position: relative;

                img {
                    height: 60px;
                    margin-right: 10px;
                    width: 60px;
                    border: solid 1px #ddd;
                    border-radius: 50%;
                    object-fit: cover;
                }

                .info-customer {
                    position: absolute;
                    top: 10px;
                    left: 70px;

                    p {
                        font-size: 12px;
                        color: #777;
                        margin-top: 0px;
                        font-weight: 700;

                    }

                    p:first-child {
                        color: #4a90e2;
                        font-size: 16px;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .order-info {
            margin-top: 15px;
            padding-bottom: 10px;

            span {
                color: #2196F3 !important;
            }

            p {
                color: #5e6d8c;
                font-size: 12px;
            }

            .user-order-customer {
                margin-top: 10px;
                padding-bottom: 10px;
                border-bottom: #5e6d8c 1px;

                .user-title {
                    div {
                        color: #5e6d8c;
                        font-size: 12px;
                    }
                }

                .user-order-date {
                    p {
                        background-color: #999;
                        border-color: #999;
                        color: #fff;
                        font-size: smaller;
                        line-height: 12px;
                        text-transform: none;
                        padding: 1px 5px;
                    }
                }
            }

        }

        .table-list-order-item {
            font-size: 12px;
            max-height: 200px;
            overflow: auto;
            padding-top: 10px;
            border-top: solid 1px #d3dae7;

            table {
                width: 100%;

                .order-item-tr {
                    border-bottom: solid 1px #d3dae7;
                }

                tr {
                    margin-bottom: 15px !important;

                    th {
                        color: #2196F3 !important;
                        font-weight: 700;
                    }

                    th:last-child {
                        text-align: end;
                    }

                    td:first-child {
                        padding-top: 10px;
                    }

                    td:last-child {
                        text-align: end;
                    }
                }
            }

            .order-item-detail {
                padding-bottom: 15px;

                .item-avatar {
                    display: flex;

                    .info-service {
                        margin-left: 10px;
                        p:first-child {
                            color: #5e6d8c;
                            font-weight: 700;
                        }
                    }

                    img {
                        width: 50px;
                        height: 50px;
                    }
                }

                p {
                    font-size: 12px;
                    margin-top: 0px;
                }
            }
        }

        .order-remind-item {
            border-top: unset !important;

            .none-remind {
                position: absolute;
                top: 56px;
                height: 150px;
                text-align: center;
                width: 100%;
            }
        }

        .item-pagination {
            text-align: end;
            border-top: 1px solid #e0e0e0;
            padding: 0 24px;
            border-bottom: 1px solid #e0e0e0;

            .ag-paging-panel {
                padding: 10px 0;
            }
        }

        .order-into-money {
            font-weight: 700;
            font-size: 12px;
            margin-top: 15px;
            padding-bottom: 10px;
            border-bottom: #d3dae7 solid 1px;

            p {
                padding-bottom: 0;
            }

            .list-order-price {
                text-align: end;
                font-weight: 700;
            }
        }

        .order-total-money {
            margin-top: 15px;
            padding-bottom: 10px;
            border-bottom: #d3dae7 solid 1px;
            font-size: 12px;

            .order-price-type {
                font-weight: 700;
                color: #5E6D94;

                p:first-child {
                    width: 70%;
                    font-size: 20px;
                }
            }

            .list-order-price {
                text-align: end;

                p:first-child {
                    font-size: 20px;
                }

                font-weight: 700;
            }

            .node-order {
                .node-title {
                    color: #2196F3 !important;
                    font-weight: 700;
                }

                .node-text {
                    background-color: #eeeeee;
                    padding: 5px 5px 5px 5px;
                    color: #777 !important;
                    display: inline-block;
                    width: 100%;
                }
            }
        }
    }
}

.popup-cent .content-popup {
    .row {
        margin-left: -5px;
        margin-right: -5px;
        align-items: center;

        >div {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

.bar-green{
    background-color: #558B2F;
    border-color: #558B2F;
    color: #fff;
    height: 7px;
    display: inline-block;
}
.bar-blue{
    background-color: blue;
    border-color: blue;
    color: #fff;
    height: 7px;
    display: inline-block;
}
.bar-blue{
    background-color: red;
    border-color: red;
    color: #fff;
    height: 7px;
    display: inline-block;
}
.transaction-owed {
    cursor: pointer;
}