.daily-confirm .head-popup {
    text-align: center;
    padding-top: 20px;
    .title {
        font-weight: bold;
        color: chocolate;
    }
}
.daily-confirm {
    .title-body {
        font-weight: bold;
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: chocolate;;
    }
    .body-popup {
        .wp-list {
            background: #efefef;
            border-radius: 5px;
            display: inline-block;
            margin-bottom: 10px;
            padding: 3px;
            .title-items-data div{
                font-size: 13px;
                font-weight: 600;
                text-align: center;
            }
            .ant-select {
                font-size: 12px;
                color: #ffff;
                font-weight: bold;
                width: 100%;
            }
            .ant-input {
                width: 138px !important;
            }
        }
        .title-momth {
            font-size: 14px;
            font-weight: bold;
        }
    }
}

.daily-confirm .ant-table {
    font-size: 12px !important;
    .ant-table-thead tr>th {
        // color: #000;
        background: #FCE5CD;
        font-weight: 700;
    }
    .ant-table-tbody tr td {
        padding: 5px !important;
    }
}

.text10 {
    color:#69A84F ;
}


.color-default .ant-select-selector {
    background-color: #5e6d8c !important;
}
.color-default-1 .ant-select-selector {
    background-color: #6aa84f !important;
}
.color-default-2 .ant-select-selector {
    background-color: #4a86e8 !important;
}
.color-default-3 .ant-select-selector {
    background-color: #e06666 !important;
}