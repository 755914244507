.member-card {
	.alert-danger {
		background-color: #fff3e0;
	}
	
	.align-center {
		align-items: center;
	}
	
	.button-trash {
		background: #f78557;
		color: white;
		i {
			margin-right: 5px;
		}
	}	
	
	.border-black {
		border: 1px solid #dddddd;
	}
	
	.card-item {
		display: flex;
		align-items: center;
	}
	
	
	.card-item {
		margin: 5px 0px;
		padding: 10px 0px;
		cursor: pointer;
		width: 100%;
		&:hover {
			background-color: #f0fcff;
		}
	
		.edit-button {
			background-color: #4a90e2;
			color: white;
			margin-right: 10px;
			border-radius: 5px;
		}
	
		.delete-button {
			background-color: #f2480c;
			color: white;
			border-radius: 5px;
		}
	}
	
	.select-card-checkbox {
		width: 5%;
	}
	
	.card-status {
		width: 5%;
		.level1 {
			color: #ffa116;
		}
	
		.level2 {
			color: #4aa9f5;
		}
	
		.level3 {
			color: #9c27b0;
		}
	
		.level4 {
			color: #6dbe70;
		}
	}
	
	.card-info {
		width: 10%;
		p {
			margin-bottom: 0px;
		}
	}
	
	.name {
		width: 20%;
		max-width: 250px;
		span {
			font-size: 12px;
			line-height: 18px;
			color: #5e6d8c;
			font-weight: 700;
		}
	}
	
	.type {
		width: 15%;
		max-width: 130px;
		margin-right: 20px;
		font-size: 12px;
		line-height: 18px;
		font-weight: 400;
	
		.card-number {
			width: 60%;
			color: white;
			background-color: #8bc34a;
			border-radius: 5px;
			padding: 3px;
			text-align: center;
		}
	
		.card-money {
			width: 60%;
			color: white;
			border-radius: 5px;
			padding: 3px;
			background-color: #ff9800;
		}
	}
	
	.price {
		width: 20%;
		max-width: 200px;
		text-align: end;
		margin-right: 20px;
	
		span {
			font-size: 14px;
			color: #259b9a;
			line-height: 22px;
			font-weight: 700;
			text-align: right;
		}
	
		div {
			font-size: 10px;
			line-height: 15px;
			color: #5e6d8c;
			font-weight: 400;
		}
	}
	
	.number-used {
		width: 10%;
		max-width: 180px;
		span {
			font-size: 12px;
			line-height: 18px;
			color: #5e6d8c;
			font-weight: 400;
		}
	
	}
	
	.account {
		width: 10%;
		max-width: 150px;
	}
	
	.due-date {
		width: 10%;
		max-width: 180px;
	}
	
	.function {
		width: 15%;
	}
	
	.action {
		width: 10%;
		max-width: 150px;
		display: flex;
		align-items: flex-end;
	}
	
	.card-item-container {
		height: 65vh;
		// max-height: 500px;
		overflow: scroll;
		padding: 10px;
	}
	.header-filter {
		padding-top: 20px;
	}
	
}

.card-tab-container {
	margin-left: 20px;
	.item {
		cursor: pointer;
		padding: 5px 15px;
		border: 1px solid #d3dae7;
		background-color: white;
		color: #d3dae7;
	}
	.item.active {
			background-color: #4a90e2;
			color: white;
			border: 1px solid #4a90e2;
	}
}

.checkbox-custom {
	span.rs-checkbox-wrapper {
		.rs-checkbox-inner:before {
			background: #2196f3;
			width: 30px;
			height: 30px;
			top: -10px;
			left: -5px;
		}
	}
}

.checkbox-custom.checkbox-red {
	span.rs-checkbox-wrapper {
		.rs-checkbox-inner:before {
			background: #f44336;
		}
	}
}

.checkbox-custom.checkbox-green {
	span.rs-checkbox-wrapper {
		.rs-checkbox-inner:before {
			background: #4caf50;
		}
	}
}

.checkbox-custom.checkbox-purple {
	span.rs-checkbox-wrapper {
		.rs-checkbox-inner:before {
			background: #9c27b0;
		}
	}
}

.checkbox-custom.checkbox-light-blue {
	span.rs-checkbox-wrapper {
		.rs-checkbox-inner:before {
			background: #00bcd4;
		}
	}
}
.checkbox-custom.checkbox-orange {
	span.rs-checkbox-wrapper {
		.rs-checkbox-inner:before {
			background: #ff9800;
		}
	}
}

// .rs-checkbox-checked {
// 	span.rs-checkbox-inner::before {
// 		width: 30px;
// 		height: 30px;
// 		top: -10px;
// 		left: -5px;
// 	}
// }

// .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:after {
// 	width: 10px;
// 	height: 17px;
// 	margin-top: -6px;
// 	margin-left: 6px;
// }


.checkbox-red.rs-checkbox-checked {
	.rs-checkbox-wrapper .rs-checkbox-inner:before {
		background: #f44336;
		border: 1px solid #f44336;	
	}
}
.checkbox-green.rs-checkbox-checked {
	.rs-checkbox-wrapper .rs-checkbox-inner:before {
		background: #4caf50;
		border: 1px solid #4caf50;	
	}
}
.checkbox-purple.rs-checkbox-checked {
	.rs-checkbox-wrapper .rs-checkbox-inner:before {
		background: #9c27b0;
		border: 1px solid #9c27b0;	
	}
}
.checkbox-light-blue.rs-checkbox-checked {
	.rs-checkbox-wrapper .rs-checkbox-inner:before {
		background: #00bcd4;
		border: 1px solid #00bcd4;	
	}
}
.checkbox-orange.rs-checkbox-checked {
	.rs-checkbox-wrapper .rs-checkbox-inner:before {
		background: #ff9800;
		border: 1px solid #ff9800;	
	}
}

.card-type {
	cursor: pointer;
	width: 49%;
	border: 1px solid #ccc;
	padding: 10px 0px;
	color: #333;
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	text-align: center;
}

.card-type.active {
	background-color: #4a90e2;
	color: white;
}

.button-save-card-modal {
	background-color: #4a90e2;
	color: white;
}

.text-info {
	color: #00bcd4;
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
	font-style: italic;
}
.w-20 {
	width: 20%;
}

.gap-20 {
	gap: 20px;
}

.item-flex-start {
	align-items: flex-start
}

.add-modal-card {
	.control-label {
		text-transform: none !important;
	}

	.flex {
		display: flex;
		align-items: center;
	}
	.input-group-addon {
		font-size: 13px;
		font-weight: 400;
		color: #333;
		background-color: #fcfcfc;
		border: 1px solid #ddd;
		border-radius: 3px;
	}
	.required {
		border-color: #6aa1ce!important;
		text-indent: 10px;
		background: rgba(249,255,0,.05);
	}
	.tr.price-input {
		padding-right: 30px;
		height: 32px;
		border: 1px solid #d3dae7;
		border-radius: 3px;
		padding: 7px;
	}
}

.rs-modal-lg {
	width: 700px;
	font-size: 12px !important;
	::placeholder {
		font-size: 12px;
	}
  
}