.background{
    background-color: #eee;
    padding-top: 15px;
    padding-bottom: 10px;
}
.backgroundQR {
    background-color: #eee;
    padding-top: 15px;
    padding-bottom: 10px;
}
.textTag{
    font-size: 16px;
    margin: 0;
    padding: 0;
    display: block;
    text-transform: uppercase;
    color: white;
}
.textTag3{
    font-size: 11px;
    margin: 0;
    padding: 0;
    display: block;
    text-transform: uppercase;
    color: white;
}
.textTag5{
    font-size: 11px;
    margin: 0;
    padding: 0;
    display: block;
    color: white;
}
.textTag4{
    font-size: 9px;
    margin: 0;
    padding: 0;
    display: block;
    text-transform: uppercase;
    color: white;
}
.textTag2{
    font-size: 13px;
    margin: 0;
    padding: 0;
    display: block;
    text-transform: uppercase;
    color: white;
}
.textTag2{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
}
.tag1{
    background-color: #009688;
}
.tag2{
    background-color: #D81B60;
}
.tag3{
    background-color: #E57373;
}
.moneyText{
    font-size: 16px;
    margin: 0;
    padding: 0;
    display: block;
    text-transform: uppercase;
    color: white;
}

@media (max-width: 768px) {
    .backgroundQR {
        padding-top: 60px;
    }
}